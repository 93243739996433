import DepartmentViceMayorRequest from '@/views/landServiceRds/components/DepartmentViceMayorRequest'

export default function (status) {
  let state
  const userRole = this.$store.getters['session/rolePlatform']
  const isDzp = this.$store.getters['session/isDzp']
  const isDAIG = this.$store.getters['session/isDAIG']
  const isPD = this.$store.getters['session/isPD']
  if (['VICE_MAYOR'].includes(userRole)) {
    return {
      title: 'Заявление',
      isEdit: false,
      isRead: true,
      isLandsData: true,
      components: [
        DepartmentViceMayorRequest
      ]
    }
  } else if (isDzp || isDAIG || isPD) {
    return {
      title: 'Заявление',
      isEdit: false,
      isRead: true,
      isLandsData: true
    }
  } else {
    switch (status) {
      case 'ASSIGNING_EXECUTOR':
        state = {
          isEdit: false,
          isRead: false
        }
        break
      case 'ANALYZING_DOCUMENTS_COMPLETENESS':
        state = {
          title: 'Заявление',

          isRead: false,
          isLandsData: true,
          actions: [
            {
              color: 'primary',
              text: 'Сохранить',
              action: () => this.updateRequest()
            },
            {
              color: 'primary',
              text: 'Отправить в работу',
              action: async () => {
                if (await this.updateRequest()) {
                  this.$store.dispatch('stateRds/changeStatus', {
                    decision: 'APPROVE'
                  })
                }
              }
            },
            {
              color: 'error',
              text: 'Дозапрос документов у заявителя',
              action: async () => {
                if (await this.updateRequest()) {
                  this.$store.dispatch('stateRds/changeStatus', {
                    decision: 'CANCEL'
                  })
                }
              }
            }
          ]
        }
        break
      case 'REQUERYING_DOCUMENTS_FROM_APPLICANT':
        state = {
          title: 'Заявление',
          isRead: false,
          isLandsData: true,
          actions: [
            {
              color: 'primary',
              text: 'Сохранить',
              action: () => this.updateRequest()
            },
            {
              color: 'primary',
              text: 'Отправить в работу',
              action: async () => {
                await this.updateRequest()
                this.$store.dispatch('stateRds/changeStatus', {
                  decision: 'APPROVE'
                })
              }
            },
            {
              color: 'error',
              text: 'Возврат заявления',
              action: async () => {
                await this.updateRequest()
                this.$store.dispatch('stateRds/changeStatus', {
                  decision: 'CANCEL'
                })
              }
            }
          ]
        }
        break
      case 'REQUESTING_FROM_DEPARTMENTS':
      case 'ANALYZING_DEPARTMENTS_DOCUMENTS':
        state = {
          title: 'Заявление',
          isRead: false,
          isLandsData: true,
          actions: [
            {
              color: 'primary',
              text: 'Сохранить',
              action: () => this.updateRequest()
            },
            {
              color: 'primary',
              text: 'Открыть этапы работ над заявлением',
              action: () => {
                this.$router.push({
                  name: 'land-rds-item-status',
                  params: { requestId: this.$route.params.requestId }
                })
              }
            }
          ]
        }
        break
      case 'APPROVING_BY_HEAD_PERSON':
      case 'APPROVING_BY_DEPARTMENT_DIRECTOR':
      case 'APPROVING_LEGAL_DEPARTMENT':
      case 'ANALYZING_LEGAL_DEPARTMENT_RESPONSE':
      case 'COMPLETING_HANDLING_STATEMENT':
      case 'PREPARING_REJECTION_PROJECT':
      case 'ISSUED_AGREEMENT':
      case 'REJECTED_BY_HEAD_PERSON':
      case 'REJECTED':
      case 'REJECTED_BY_LEGAL_DEPARTMENT':
      case 'PREPARING_LEGAL_DEPARTMENT_REQUEST':
      case 'COMPLETING_REJECTION_AFTER_LD':
        state = {
          title: 'Заявление',

          isRead: true,
          isLandsData: true,
          actions: [
            {
              color: 'primary',
              text: 'Открыть этапы работ над заявлением',
              action: () => {
                this.$router.push({
                  name: 'land-rds-item-status',
                  params: { requestId: this.$route.params.requestId }
                })
              }
            }
          ]
        }
        break
      case 'RETURNED':
        state = {
          title: 'Заявление',
          isEdit: false,
          isRead: true,
          isLandsData: true
        }
        break
      default:
        state = {
          title: 'Новое заявление перераспределения',
          isRead: false,
          isLandsData: false,
          actions: [
            {
              color: 'primary',
              text: 'Создать заявление',
              action: () => this.saveRequest()
            }
          ]
        }
    }
    return state
  }
}
