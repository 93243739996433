export default {
  async statementList ({ rootGetters, commit }, payload) {
    const { filters } = payload
    const data = await rootGetters.GET('land/prv/statement', filters)
    commit(filters.pageIndex ? 'mergeProperty' : 'setProperty', { name: 'statements', value: data })
  },
  async fullStatementItem ({ rootGetters, commit }, payload) {
    const { id } = payload
    const data = await rootGetters.GET(`land/prv/provision/${id}`)
    const applicants = data.statement.applicants
    delete data.statement.applicants
    commit('statementPerson/setApplicants', { applicants, stateStatus: data.statement.status }, { root: true })
    commit('setProperty', { name: 'statement', value: data })
  },
  async onlyStatementItem ({ rootGetters, commit }, payload) {
    const { id } = payload
    const data = await rootGetters.GET(`land/prv/statement/${id}`)
    commit('statementPerson/setApplicants', { applicants: data.applicants, stateStatus: data.status }, { root: true })
    commit('setProperty', { name: 'statement', value: data })
  },
  async saveStatement ({ rootGetters, dispatch, commit }, payload) {
    const statement = await rootGetters.POST('land/prv/statement', payload)
    await dispatch('statementPerson/savePersonToStatement', statement, { root: true })
    commit('statementPerson/clearPersonList', null, { root: true })
  },
  async updateStatement ({ rootGetters, dispatch }, payload) {
    await rootGetters.PUT(`land/prv/provision/${payload.id}`, payload)
    await dispatch('statementPerson/savePersonToStatement', payload, { root: true })
  },
  async updateCheckListFinish ({ rootGetters, dispatch }, payload) {
    await rootGetters.PUT(`land/prv/provision/${payload.id}`, payload)
  },
  async addAttachStatement ({ rootGetters, state, commit }, payload) {
    const statement = state.statement.statement
    if (Array.isArray(payload)) {
      await rootGetters.POST(`land/prv/statement/${statement.id}/attachments`, payload)
    } else {
      await rootGetters.POST(`land/prv/statement/${statement.id}/attachment`, payload)
    }
    commit('addFileStatement', payload)
  },
  // Изменение статуса для заявления
  async changeStatus ({ rootGetters, state, commit }, payload) {
    const statement = state.statement.statement
    const data = await rootGetters.POST(`land/prv/statement/${statement.id}/update-status`, {
      statusCode: statement.status,
      ...payload
    })
    commit('changeStatusStatementItem', data.message)
  },
  // Назначение исполнителя
  async setExecutor ({ rootGetters, commit }, payload) {
    const { statementId } = payload
    await rootGetters.POST(`land/prv/statement/${statementId}/set-executor`, payload)
    commit('changeStatusStatement', { id: statementId, status: 'ANALYZING_DOCUMENTS_COMPLETENESS' })
  },
  // Изменение статуса для департамента
  // type in ['Dzp', 'Daig', 'Rosreestr']
  async changeStatusDepartment ({ rootGetters, state, commit }, payload) {
    const statement = state.statement.statement
    const { type } = payload
    const data = await rootGetters.POST(`land/prv/statement/${statement.id}/update-${type.toLowerCase()}-substatus`, {
      statusCode: state.statement[`request${type}Status`]
    })
    commit('changeStatusDepartmentItem', { type: `request${type}Status`, status: data.message })
  }
}
