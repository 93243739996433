import UploadRequest from '@/views/Department/components/UploadRequest'
import InfoStatus from '@/views/Department/components/InfoStatus'
import MatchingSolution from '@/views/Department/components/MatchingSolution'

export default function (process, rolePlatform) {
  const { status: { code } } = process
  switch (code) {
    case 'PREPARING_RESPONSE':
      return rolePlatform === 'DZP_LEAD_ADVISOR' ? UploadRequest : InfoStatus
    case 'APPROVING_BY_HEAD_PERSON':
      return rolePlatform === 'DZP_HEAD_PERSON' ? MatchingSolution : InfoStatus
    case 'APPROVING_BY_DEPUTY_DIRECTOR':
      return rolePlatform === 'DZP_DEPUTY_DIRECTOR' ? MatchingSolution : InfoStatus
    case 'APPROVING_BY_DEPARTMENT_DIRECTOR':
      return rolePlatform === 'DZP_DEPARTMENT_DIRECTOR' ? MatchingSolution : InfoStatus
  }
  return InfoStatus
}
