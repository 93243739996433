<template>
  <section v-if="isSend" class="melia rocky--text">Решение принято</section>
  <section class="d-flex flex-column" v-else>
    <a
      :href="attachment.url"
      download
      target="_blank">
      <rir-button class="memento godfather--text">Скачать документ</rir-button>
    </a>
    <rir-button
      :disabled="!!note"
      class="mt-5"
      @click="changeStatus('APPROVE')"
    >
      Утвердить
    </rir-button>
    <span class="kleodora titanic--text anie mt-8 mb-4">
      Если документ требует доработки, то опишите правки в поле для комментария
    </span>
    <rir-textarea
      class="mb-6"
      label="Комментарий"
      v-model="note"
    />
    <rir-button
      color="error"
      :disabled="!note"
      @click="changeStatus('RETURN')"
    >
      Вернуть на доработку
    </rir-button>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import getEnv from '@/plugins/env'

export default {
  name: 'matching-solution-sub-status',
  data: () => ({
    isSend: false,
    note: null
  }),
  props: {
    process: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('session', ['departmentCode', 'rolePlatform']),
    attachment () {
      return this.process.attachments[0]
    },
    roleMethod () {
      switch (this.rolePlatform) {
        case 'DAIG_HEAD_PERSON_URBAN':
          return 'update-head-person-urban-substatus'
        case 'DAIG_DEPUTY_DIRECTOR':
          return 'update-deputy-director-substatus'
        case 'DAIG_HEAD_PERSON_SOCIAL':
          return 'update-head-person-social-substatus'
      }
      return null
    }
  },
  methods: {
    async changeStatus (decision) {
      await this.$axios
        .post(
          `${getEnv('VUE_APP_API_SERVER')}land/${this.departmentCode}/${this.process.id}/${this.roleMethod}`,
          {
            note: this.note,
            decision
          }
        )
      this.isSend = true
    }
  }
}
</script>
