<template>
  <rir-accordion v-if="isView">
    <template slot="activator">
      <div class="d-flex flex-column">
        <div class="mb-2 titanic--text melia">
          Принятие решения по отказу над заявлением
        </div>
        <div class="d-flex align-center">
          <rir-icon
            class="mr-2"
            :icon="isStatus.icon"
            :fill="isStatus.fillIcon"
          />
          <span class="anie terminator--text">{{ isStatus.text }}</span>
        </div>
      </div>
    </template>
    <component
      :is="componentForm"
    />
  </rir-accordion>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FormChiefReject from '@/views/landServicePrv/components/statuses/agreement/FormChiefReject'
import FormSpecialistReject from '@/views/landServicePrv/components/statuses/agreement/FormSpecialistReject'

export default {
  name: 'agreement-reject',
  computed: {
    ...mapGetters('session', ['rolePlatform']),
    ...mapState('statePrv', ['statement']),
    isView () {
      return [
        'APPROVING_REJECTION_BY_LD_AFTER_DEPARTMENTS',
        'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_DEPARTMENTS',
        'APPROVING_REJECTION_BY_LD_AFTER_DEPARTMENTS',
        'COMPLETING_REJECTION_AFTER_DEPARTMENTS',
        'APPROVING_REJECTION_BY_DEPARTMENT_DIRECTOR',
        'CORRECTING_REJECTION',
        'ANALYZING_LEGAL_DEPARTMENT_REJECT_RESPONSE',
        'REJECTED'
      ].includes(this.statement.statement.status)
    },
    isStatus () {
      let status = {
        icon: 'in-progress',
        fillIcon: 'rocky',
        text: 'В процессе'
      }
      const isFinishStep = [
        'REJECTED'
      ].includes(this.statement.statement.status)
      if (isFinishStep) {
        status = {
          icon: 'selected',
          fillIcon: 'matrix',
          text: 'Завершено'
        }
      }
      return status
    },
    componentForm () {
      switch (this.rolePlatform) {
        case 'SPECIALIST':
          return FormSpecialistReject
        case 'CHIEF':
          return FormChiefReject
      }
      return null
    }
  }
}
</script>
