<template>
  <div class="p-form">
    <div class="row">
      <div class="col-md-4-12">
        <rir-form-item
          :error="v.lastName.$error"
          :message="mesErr(v.lastName)">
          <rir-input
            label="Фамилия"
            v-model="value.lastName"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item
          :error="v.firstName.$error"
          :message="mesErr(v.firstName)">
          <rir-input
            label="Имя"
            v-model="value.firstName"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item>
          <rir-input
            label="Отчество"
            v-model="value.middleName"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4-12">
        <rir-form-item
          :error="v.birthDate.$error"
          :message="mesErr(v.birthDate)">
          <rir-date-picker
            label="Дата рождения"
            v-model="value.birthDate"
            v-mask="'##.##.####'"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item
          :error="v.gender.$error"
          :message="mesErr(v.gender)">
          <rir-select
            label="Пол"
            :items="genderTypes"
            id-value="value"
            text-value="text"
            v-model="value.gender"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item
          :error="v.phone.$error"
          :message="mesErr(v.phone)">
          <rir-input
            label="Телефон"
            v-model="value.phone"
            v-mask="'+7 (###) ###-##-##'"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12 crephusa">
        Паспортные данные
      </div>
    </div>
    <div class="row">
      <div class="col-md-4-12">
        <rir-form-item
          :error="v.passport.series.$error"
          :message="mesErr(v.passport.series)">
          <rir-input
            label="Серия"
            v-model="value.passport.series"
            v-mask="'####'"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item
          :error="v.passport.number.$error"
          :message="mesErr(v.passport.number)">
          <rir-input
            label="Номер"
            v-model="value.passport.number"
            v-mask="'######'"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item
          :error="v.passport.issueDate.$error"
          :message="mesErr(v.passport.issueDate)">
          <rir-date-picker
            click-close
            label="Дата выдачи"
            v-model="value.passport.issueDate"
            v-mask="'##.##.####'" />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8-12">
        <rir-form-item
          :error="v.passport.issuerName.$error"
          :message="mesErr(v.passport.issuerName)">
          <rir-input
            label="Кем выдан"
            v-model="value.passport.issuerName"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item
          :error="v.passport.issuerCode.$error"
          :message="mesErr(v.passport.issuerCode)">
          <rir-input
            label="Код подразделения"
            v-model="value.passport.issuerCode"
          />
        </rir-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { mask } from 'vue-the-mask'
import { mapState } from 'vuex'
export default {
  name: 'p-form',
  mixins: [validateMixin],
  directives: { mask },
  data: () => ({}),
  props: {
    value: {
      type: Object
    },
    v: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('directory', ['genderTypes'])
  }
}
</script>

<style lang="scss" scoped>
.p-form{
  margin-top: 20px;
}
</style>
