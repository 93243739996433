import { mapActions } from 'vuex'

export default {
  async mounted () {
    await this.loadData()
  },
  data: () => ({
    persons: []
  }),
  props: {
    value: {
      type: Object
    },
    personType: {
      type: String
    },
    query: {
      type: String,
      default: ''
    },
    returnSelect: {
      type: Boolean
    }
  },
  watch: {
    query: async function (val) {
      await this.loadData({ query: val })
    }
  },
  methods: {
    ...mapActions('person', ['listPerson']),
    async loadData (query = null) {
      this.persons = await this.listPerson({
        personType: this.personType,
        query
      })
    },
    selectRow (item) {
      if (this.returnSelect) {
        return item
      }
      this.$router.push({
        name: 'person-item',
        params: {
          personType: this.personType,
          guid: item.id
        }
      })
    }
  }
}
