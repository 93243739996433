import mixinsCommit from '@/store/mixins/mixinsCommit'

export default {
  ...mixinsCommit,
  changeStatusStatement (state, { id, status }) {
    if (state.statements.length) {
      const index = state.statements.findIndex(el => el.id === id)
      state.statements[index].status = status
    }
  },
  changeStatusStatementItem (state, status) {
    state.statement.statement.status = status
  },
  changeStatusDepartmentItem (state, { type, status }) {
    state.statement[type] = status
  },
  addFileStatement (state, file) {
    let attach = state.statement.statement.attachments
    if (Array.isArray(file)) {
      attach = attach.concat(file)
    } else {
      attach.push(file)
    }
    state.statement.statement.attachments = attach
  },
  clearFile (state) {
    state.statement.statement.attachments = state.statement.statement.attachments
      .filter(f => !['16-02', '16-01', '15-03', '16-04'].includes(f.type))
      .map(f => {
        const attach = f
        switch (attach.type) {
          case '15-02':
            attach.type = '15-03'
            break
          case '16-02':
            attach.type = '16-04'
            break
        }
        return attach
      })
  }
}
