<template>
  <section class="land-service-form">
    <div class="row" v-if="isEditForm">
      <div
        class="col-md-12-12">
        <span class="metioche titanic--text biqe">№ {{ numberStatement }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4-12">
        <rir-form-item
          :error="$v.model.receiptDate.$error"
          :message="mesErr($v.model.receiptDate)">
          <rir-date-picker
            :readonly="isReadOnly"
            click-close
            label="Дата поступления"
            is-time
            v-model="model.receiptDate"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-input
          :readonly="isReadOnly"
          label="Регистрационный номер"
          v-model="model.sedNumber"
        />
      </div>
      <div class="col-md-4-12">
        <rir-form-item
          :error="$v.model.receptionMethod.$error"
          :message="mesErr($v.model.receptionMethod)">
          <rir-select
            :readonly="isReadOnly"
            :items="receptions"
            label="Способ приема заявления"
            v-model="model.receptionMethod"
            id-value="code"
            text-value="name"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12">
        <statement-person-block
          :readonly="isReadOnly"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12 d-flex flex-column">
        <span class="melia mb-5">Пакет документов</span>
        <rir-file-list
          v-if="isReadOnly"
          :files="attachmentStatement"
        />
        <rir-drag-upload-file
          v-else
          v-model="attachmentStatement"
          :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt']"
          :count-file="10"
          :upload-url="$root.$uploadServer"
          :params-body="{
            code: 'LAND',
            type: '11-01'
          }"
        />
      </div>
    </div>
    <template v-if="formSettingsDefault.isLandsData">
      <lands-data
        :readonly="isReadOnly"
        :item="statement"
        ref="lands"
      />
      <persons-share-land
        ref="psl"
        :readonly="isReadOnly"/>
      <realties-state
        ref="rs"
        :realties="statement.realties"
        :readonly="isReadOnly"/>
    </template>
    <div class="row" v-if="isGetDocPerson">
      <div class="col-md-12-12 melia">Осталось времени до возврата заявления</div>
      <div class="col-md-12-12 d-flex align-center">
        <rir-icon
          class="mr-2"
          icon="clock"
        />
        <span>{{ isGetDocPerson }}</span>
      </div>
    </div>
    <div
      class="row"
       v-for="(action, index) in formSettingsDefault.actions"
      :key="index"
    >
      <div class="col-md-12-12">
        <rir-button
          :color="action.color"
          @click="action.action"
        >
          {{ action.text }}
        </rir-button>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions, mapMutations } from 'vuex'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required } from '@/plugins/vuelidate/customValidate'
import formState from '@/views/landServicePrv/utils/formState'
import LandsData from '@/views/landServicePrv/components/LandsData'
import StatementPersonBlock from '@/components/statmentPerson/StatementPersonBlock'
import PersonsShareLand from '@/views/landServicePrv/components/PersonsShareLand'
import RealtiesState from '@/views/landServicePrv/components/RealtiesState'

export default {
  name: 'land-service-form',
  components: { RealtiesState, PersonsShareLand, StatementPersonBlock, LandsData },
  mixins: [validationMixin, validateMixin],
  mounted () {
    if (this.statement) { this.model = { ...this.statement.statement } }
    this.setProperty({ name: 'titlePage', value: this.formSettingsDefault.title })
  },
  data: () => ({
    model: {
      receiptDate: +new Date(),
      sedNumber: null,
      receptionMethod: null,
      attachments: []
    }
  }),
  validations: {
    model: {
      receiptDate: {
        required: required('Обязательное поле')
      },
      receptionMethod: {
        required: required('Обязательное поле')
      }
    }
  },
  props: {
    statement: {
      type: Object
    }
  },
  computed: {
    ...mapState('directory', ['receptions']),
    formSettingsDefault () {
      return formState.apply(this, [this?.statement?.statement?.status])
    },
    isEditForm () {
      return !!this.statement
    },
    isReadOnly () {
      return this.formSettingsDefault.isRead
    },
    attachmentStatement: {
      get () {
        return this.model.attachments.filter(el => el.type === '11-01')
      },
      set (val) {
        this.model.attachments = val
      }
    },
    numberStatement () {
      return this.statement.statement.number
    },
    isGetDocPerson () {
      if (this.statement?.statement.status === 'REQUERYING_DOCUMENTS_FROM_APPLICANT') {
        const start = this.statement.requeryingDocumentsStartTime ? moment(this.statement.requeryingDocumentsStartTime) : moment()
        const end = (this.statement.requeryingDocumentsStartTime ? moment(this.statement.requeryingDocumentsStartTime) : moment()).add(5, 'days')
        return end.from(start)
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapMutations('apps', ['setProperty']),
    ...mapActions('statePrv', ['updateStatement', 'saveStatement', 'statementItem']),
    async saveRequest () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      // Создаем заявление
      await this.saveStatement({
        ...this.model
      })
      this.clearForm()
      this.$root.$notification({
        type: 'success',
        text: 'Заявление отправлено в работу'
      })
    },
    async updateRequest () {
      this.$v.$touch()
      this.$refs.lands.$v.$touch()
      this.$refs.psl.$v.$touch()
      this.$refs.rs.$v.$touch()
      if (this.$v.$invalid ||
        this.$refs.lands.$v.$invalid ||
        this.$refs.psl.$v.$invalid ||
        this.$refs.rs.$v.$invalid) return false
      const model = {
        id: this.model.id,
        statement: {
          ...this.model
        },
        landArea: this.$refs.lands.landArea,
        realties: this.$refs.rs.model
      }
      await this.updateStatement(model)
      this.$root.$notification({
        type: 'success',
        text: 'Заявление обновлено!'
      })
      return true
    },
    clearForm () {
      this.model = {
        receiptDate: null,
        sedNumber: null,
        receptionMethod: null,
        attachments: []
      }
      this.$v.$reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.land-service-form{
  &__person{
    padding: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 12px;
    background-color: var(--rir-amelie);
  }
}
</style>
