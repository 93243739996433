import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const InitState = () => ({
  receptions: [],
  personTypes: [],
  statementStatuses: [],
  executors: [],
  redistributions: [],
  corporateForms: [],
  areaType: [],
  genderTypes: [
    {
      value: 'M',
      text: 'Мужской'
    },
    {
      value: 'F',
      text: 'Женский'
    }
  ]
})

export default {
  namespaced: true,
  state: InitState,
  actions,
  mutations,
  getters
}
