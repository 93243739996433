<template>
  <section class="d-flex align-center mt-5">
    <rir-icon
      class="mr-3"
      icon="waiting" />
    <h2 class="melia rocky--text">{{ dataStatus.action }}</h2>
  </section>
</template>

<script>

export default {
  name: 'info-status',
  props: {
    process: {
      type: Object
    }
  },
  computed: {
    dataStatus () {
      const { status } = this.process
      return {
        action: status.name
      }
    }
  }
}
</script>
