import moment from 'moment'
moment.locale('ru')
export default {
  install: function (Vue, options) {
    Vue.filter('dateTextMonth', (date) => date ? moment(date).format('DD MMMM YYYY') : '')
    Vue.filter('dateRuFormat', (date) => date ? moment(date).format('DD.MM.YYYY') : '')
    Vue.filter('dateTime', (date) => date ? moment(date).format('DD MMMM HH:mm') : '')
    Vue.filter('time', (date) => date ? moment(date).format('HH:mm') : '')
  }
}
