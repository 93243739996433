<template>
  <span>{{ item.receiptDate | dateRuFormat }}</span>
</template>

<script>
export default {
  name: 'date-table',
  props: {
    item: {
      type: Object
    }
  }
}
</script>
