<template>
  <section class="d-flex flex-column">
    <template v-if="status === 'STARTED'">
      <span class="kleodora terminator--text anie mb-4">Сформируйте автоматический запрос в ДАиГ, проверьте и при необходимости отредактируйте его, после чего загрузите в систему</span>
      <rir-button @click="downloadReport" class="memento godfather--text mb-4">Сформировать запрос</rir-button>
      <rir-drag-upload-file
        class="mb-4"
        :count-file="1"
        v-model="attachment"
        :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt']"
        :upload-url="$root.$uploadServer"
        :params-body="{
          code: 'LAND',
          type: '13-01'
        }" />
      <rir-button
        :disabled="!attachment.length"
        :loading="isLoading"
        @click="changeStatus">Отправить в ДАиГ</rir-button>
    </template>
    <template v-if="status === 'PENDING'">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="matrix"
            class="mr-2"
            icon="selected"
          />
          <span>Отправлено в ДАиГ</span>
        </div>
        <span class="pl-6 titanic--text anie">Ожидаем ответ</span>
      </div>
    </template>
    <template v-if="status === 'FINISHED'">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="snatch"
            class="mr-2"
            icon="notifications"
          />
          <span>Получен ответ</span>
        </div>
        <span class="mb-5 pl-6 titanic--text anie">Ознакомьтесь с документом</span>
        <a
          v-if="downloadFile.length"
          :href="downloadFile[0].url"
          download
          target="_blank">
          <rir-button class="memento godfather--text">Скачать документ</rir-button>
        </a>
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'department-daig',
  data: () => ({
    isLoading: false,
    attachment: []
  }),
  props: {
    status: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['GET_BLOB']),
    ...mapState('statePrv', ['statement']),
    downloadFile () {
      return this.statement.statement.attachments.filter(el => el.type === '13-02')
    }
  },
  methods: {
    ...mapActions('statePrv', ['addAttachStatement']),
    async changeStatus () {
      this.isLoading = true
      !!this.attachment.length && await this.addAttachStatement(this.attachment[0])
      await this.$emit('changeStatus', 'Daig')
      this.isLoading = false
    },
    async downloadReport () {
      const response = await this.GET_BLOB(`land/prv/provision/${this.statement.id}/daig`,
        {},
        {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        })
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Запрос ДАиГ.odt') // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }
}
</script>
