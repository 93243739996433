<template>
  <rir-accordion>
    <template
      slot="activator">
      <div class="d-flex flex-column mr-5">
        <div class="mb-2 mr-5 titanic--text melia">Формирование запросов по заявлению</div>
        <div class="d-flex align-center">
          <rir-icon
            class="mr-2"
            :icon="isStatus.icon"
            :fill="isStatus.fillIcon"
          />
          <span class="anie terminator--text">{{ isStatus.text }}</span>
        </div>
      </div>
      <div
        class="d-flex flex-column mr-5">
        <div class="mb-2 mr-5 titanic--text kleodora anie">Отправлено</div>
        <div class="d-flex align-center titanic--text melia">{{ countSend }} / 3</div>
      </div>
      <div class="d-flex flex-column flex-auto">
        <div class="mb-2 mr-5 titanic--text kleodora anie">Получен ответ</div>
        <div class="d-flex align-center titanic--text melia">{{ countGet }} / 3</div>
      </div>
    </template>
    <div class="row row--no-grep">
      <div class="col-md-4-12 pl-0 mb-0">
        <rir-cards-block class="amelie department-card">
          <span class="titanic--text clymenti" slot="title">
            Департамент<br/>землепользования
          </span>
          <template>
            <department-dzp
              @changeStatus="changeStatus"
              :status="redistribution.requestDzpStatus" />
          </template>
        </rir-cards-block>
      </div>
      <div class="col-md-4-12 mb-0">
        <rir-cards-block class="amelie department-card">
          <span class="titanic--text clymenti" slot="title">
            Департамент архитектуры и градостроительства
          </span>
          <template>
            <department-daig
              @changeStatus="changeStatus"
              :status="redistribution.requestDaigStatus" />
          </template>
        </rir-cards-block>
      </div>
      <div class="col-md-4-12 pr-0 mb-0">
        <rir-cards-block class="amelie department-card">
          <span class="titanic--text clymenti mb-10 d-flex" slot="title">
            Росреестр
          </span>
          <template>
            <department-rosreestor
              @changeStatus="changeStatus"
              :status="redistribution.requestRosreestrStatus"/>
          </template>
        </rir-cards-block>
      </div>
    </div>
  </rir-accordion>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DepartmentDzp from '@/views/landServiceRds/components/statuses/DepartmentDzp'
import DepartmentDaig from '@/views/landServiceRds/components/statuses/DepartmentDaig'
import DepartmentRosreestor from '@/views/landServiceRds/components/statuses/DepartmentRosreestor'
export default {
  name: 'departments-status',
  components: { DepartmentRosreestor, DepartmentDaig, DepartmentDzp },
  props: {
    finish: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('stateRds', ['statement', 'redistribution']),
    countSend () {
      /*
        requestDaigStatus: "STARTED"
        requestDzpStatus: "STARTED"
        requestRosreestrStatus: "STARTED"
      * */
      const mapStatus = ['requestDaigStatus', 'requestDzpStatus', 'requestRosreestrStatus']
      return mapStatus.reduce((count, el) => {
        if (this.redistribution[el] !== 'STARTED') count++
        return count
      }, 0)
    },
    countGet () {
      /*
        requestDaigStatus: "STARTED"
        requestDzpStatus: "STARTED"
        requestRosreestrStatus: "STARTED"
      * */
      const mapStatus = ['requestDaigStatus', 'requestDzpStatus', 'requestRosreestrStatus']
      return mapStatus.reduce((count, el) => {
        if (this.redistribution[el] === 'FINISHED') count++
        return count
      }, 0)
    },
    isStatus () {
      return {
        icon: this.finish ? 'selected' : 'in-progress',
        fillIcon: this.finish ? 'matrix' : 'rocky',
        text: this.finish ? 'Завершено' : 'В процессе'
      }
    }
  },
  methods: {
    ...mapActions('stateRds', ['changeStatusDepartment']),
    async changeStatus (type) {
      await this.changeStatusDepartment({ type })
      this.$root.$notification({
        type: 'success',
        text: 'Запрос отправлен!'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.department-card{
  border-radius: 16px;
  padding: 20px
}
</style>
