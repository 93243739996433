<template>
  <section class="d-flex flex-column align-start">
    <rir-tooltip
      :activator-hover="isTooltip"
    >
    <span
      ref="content"
      slot="activator"
      class="applicantTable__content">
      {{ item.applicant }}
    </span>
      <span class="amelie--text">{{ item.applicant }}</span>
    </rir-tooltip>
    <rir-button
      @click.native.stop="openInfoApplicants"
      transparent
      class="godfather--text"
      v-if="item.applicantCount > 1"
    >еще {{ item.applicantCount - 1 }}</rir-button>
  </section>
</template>

<script>
import ApplicantsListModal from '@/components/modal/ApplicantsListModal'
import tableCheckEclipse from '@/plugins/mixins/tableCheckEclipse'

export default {
  name: 'applicant-table',
  mixins: [tableCheckEclipse],
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    model () {
      return this.item?.statement || this.item
    }
  },
  methods: {
    openInfoApplicants () {
      this.$root.$modal(ApplicantsListModal, {
        item: this.model
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.applicantTable{
  &__content{
    @media (max-width: 1366px) {
      width: 160px;
    }
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
