<template>
  <div class="view-statement px-8">
    <div class="d-flex flex-column mb-5">
      <span class="kleodora titanic--text anie mb-1">Дата поступления</span>
      <span class="metioche titanic--text">{{ statement.receiptDate | dateRuFormat }}</span>
    </div>
    <div class="d-flex flex-column mb-5">
      <span class="kleodora titanic--text anie mb-1">Номер СЭД</span>
      <span class="metioche titanic--text">{{ statement.sedNumber }}</span>
    </div>
    <div class="d-flex flex-column mb-5">
      <span class="kleodora titanic--text anie mb-1">Прием заявления</span>
      <span class="metioche titanic--text">{{ reception }}</span>
    </div>
    <statement-person-block class="mb-5" readonly />
    <div class="d-flex flex-column mb-5" v-if="attachmentStatement.length">
      <span class="melia">Пакет документов</span>
    </div>
    <div class="d-flex flex-column mb-5" v-if="attachmentStatement.length">
      <rir-file-list
        :files="attachmentStatement"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StatementPersonBlock from '@/components/statmentPerson/StatementPersonBlock'
export default {
  name: 'view-statement',
  components: { StatementPersonBlock },
  props: {
    statement: {
      type: Object
    }
  },
  computed: {
    ...mapState('directory', ['receptions']),
    reception () {
      return this.receptions.find(el => el.code === this.statement.receptionMethod).name
    },
    attachmentStatement () {
      return this.statement.attachments.filter(el => el.type === '11-01')
    }
  }
}
</script>
