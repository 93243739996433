import UploadRequest from '@/views/Department/components/UploadRequest'
import InfoStatus from '@/views/Department/components/InfoStatus'
import MatchingSolution from '@/views/Department/components/MatchingSolution'
import ChangeExecutor from '@/views/Department/components/ChangeExecutor'

export default function (process, rolePlatform) {
  const { status: { code } } = process
  switch (code) {
    case 'ASSIGNING_EXECUTOR':
      return rolePlatform === 'LD_HEAD_PERSON' ? ChangeExecutor : InfoStatus
    case 'PREPARING_RESPONSE':
      return rolePlatform === 'LD_LEAD_ADVISOR' ? UploadRequest : InfoStatus
    case 'APPROVING_BY_HEAD_PERSON':
      return rolePlatform === 'LD_HEAD_PERSON' ? MatchingSolution : InfoStatus
    case 'APPROVING_BY_DEPARTMENT_DIRECTOR':
      return rolePlatform === 'LD_DEPARTMENT_DIRECTOR' ? MatchingSolution : InfoStatus
  }
  return InfoStatus
}
