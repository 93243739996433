export default {
  loadDirectories ({ commit, rootGetters }) {
    rootGetters.GET('land/ref/statement/reception-method').then(data => {
      commit('setProperty', { name: 'receptions', value: data })
    })
    rootGetters.GET('common/ref/process-status').then(data => {
      commit('setProperty', { name: 'statementStatuses', value: data })
    })
    rootGetters.GET('common/get-users', {
      roles: 'LAND.SPECIALIST'
    }).then(data => {
      commit('setProperty', { name: 'executors', value: data })
    })
    rootGetters.GET('land/ref/redistribution/basis').then(data => {
      commit('setProperty', { name: 'redistributions', value: data })
    })
    rootGetters.GET('common/ref/person-type').then(data => {
      commit('setProperty', { name: 'personTypes', value: data })
    })
    rootGetters.GET('common/ref/corporate-form').then(data => {
      commit('setProperty', { name: 'corporateForms', value: data })
    })
    rootGetters.GET('land/ref/redistribution/area-type').then(data => {
      commit('setProperty', { name: 'areaType', value: data })
    })
  }
}
