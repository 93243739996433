<template>
  <section class="land-service-item">
    <div
      class="d-flex align-center justify-center"
      v-if="isLoadData">
      <loading-ring
        :size="32"
        color="var(--rir-rocky)"
      />
    </div>
    <template
      v-else>
      <land-service-form
        :statement="statement"
      />
      <template
        v-if="!!formSettings.components">
        <component
          v-for="comp in formSettings.components"
          :is="comp"
          :key="comp.name"
        />
      </template>
    </template>
  </section>
</template>

<script>
import LandServiceForm from '@/views/landServicePrv/LandServiceForm'
import { mapActions, mapState } from 'vuex'
import formState from '@/views/landServicePrv/utils/formState'
export default {
  name: 'land-service-item',
  components: { LandServiceForm },
  mounted () {
    this.loadData()
  },
  data: () => ({
    isLoadData: true
  }),
  props: {
    requestId: {
      type: String
    }
  },
  computed: {
    ...mapState('statePrv', ['statement']),
    formSettings () {
      return formState.apply(this, [this.statement?.statement?.status])
    }
  },
  methods: {
    ...mapActions('statePrv', ['fullStatementItem']),
    async loadData () {
      this.isLoadData = true
      await this.fullStatementItem({
        id: this.requestId
      })
      this.isLoadData = false
    }
  }
}
</script>
