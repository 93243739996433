<template>
  <div class="d-flex flex-column">
    <template v-if="statusStep === 1">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="fargo"
            class="mr-2"
            icon="close"
          />
          <span>Отказано в заявление</span>
        </div>
        <span class="pl-6 titanic--text anie">Решение отказа</span>
        <span class="anie terminator--text pl-6">
          {{ contentIsStatus.info }}
        </span>
        <rir-file-list
          v-if="fileReject.length"
          :count-line="1"
          :files="fileReject"
        />
      </div>
    </template>
    <template v-if="statusStep === 2">
      <div class="row row--no-grep flex-column">
        <div class="d-flex align-center mb-5">
          <rir-icon
            fill="fargo"
            class="mr-2"
            icon="close"
          />
          <span>Возврат на доработку</span>
        </div>
        <rir-file-list
          :files="fileReturn" />
        <template v-if="!!statement.statement.note">
          <div class="mb-5">Комментарий: {{statement.statement.note}}</div>
        </template>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0">
          <rir-drag-upload-file
            class="mb-4"
            :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt', '.zip']"
            :count-file="1"
            :upload-url="$root.$uploadServer"
            :params-body="{
              code: 'LAND',
              type: '17-01'
            }"
            v-model="attachmentCancel" />
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0 mb-0">
          <rir-button
            color="error"
            :disabled="!attachmentCancel.length"
            :loading="isSend"
            @click="sendAgreement('CANCEL')"
          >Отправить отказ на согласование</rir-button>
        </div>
      </div>
    </template>
    <template v-if="statusStep === 3">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="fargo"
            class="mr-2"
            icon="close"
          />
          <span>Анализ ответа от правового департамента</span>
        </div>
        <span class="pl-6 titanic--text anie">Решение</span>
        <a v-if="filePDAnswer.length" :href="filePDAnswer[0].url" class="mt-5" download target="_blank">
          <rir-button
            class="memento godfather--text"
          >Скачать Документ</rir-button>
        </a>
        <rir-button
          class="mt-5"
          :loading="isSend"
          @click="sendAgreement('APPROVE')"
        >Закрыть работу по договору</rir-button>
        <rir-button
          class="mt-5"
          color="error"
          :loading="isSend"
          @click="sendAgreement('RETURN')"
        >Доработать отказ</rir-button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  name: 'form-specialist-reject',
  created () {
    this.attachmentCancel = this.statement.statement.attachments.filter(el => el.type === '17-01')
  },
  data: () => ({
    isSend: false,
    attachmentCancel: []
  }),
  computed: {
    ...mapGetters(['GET_BLOB']),
    ...mapState('statePrv', ['statement']),
    contentIsStatus () {
      const statement = this.statement.statement
      switch (statement.status) {
        case ('APPROVING_REJECTION_BY_LD_AFTER_DEPARTMENTS'):
          return {
            info: 'Ожидается согласование отказа правового департамента'
          }
        case ('SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_DEPARTMENTS'):
          return {
            info: 'Ожидается утверждение начальника'
          }
        case ('APPROVING_REJECTION_BY_DEPARTMENT_DIRECTOR'):
          return {
            info: 'Ожидается утверждение директором'
          }
      }
      return {}
    },
    statusStep () {
      /*
      * APPROVING_BY_HEAD_PERSON
      * ANALYZING_DEPARTMENTS_DOCUMENTS
      * APPROVING_LEGAL_DEPARTMENT
      *
      * 1 - Информация по утверждению
      * */
      let step
      const statement = this.statement.statement
      switch (statement.status) {
        case 'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_DEPARTMENTS':
        case 'APPROVING_REJECTION_BY_LD_AFTER_DEPARTMENTS':
        case 'COMPLETING_REJECTION_AFTER_DEPARTMENTS':
        case 'APPROVING_REJECTION_BY_DEPARTMENT_DIRECTOR':
        case 'REJECTED':
          step = 1
          break
        case 'CORRECTING_REJECTION':
          step = 2
          break
        case 'ANALYZING_LEGAL_DEPARTMENT_REJECT_RESPONSE':
          step = 3
          break
      }
      return step
    },
    fileReturn () {
      return this.statement.statement.attachments.filter(el => ['17-02', '17-03'].includes(el.type))
    },
    fileReject () {
      return this.statement.statement.attachments.filter(el => ['17-01'].includes(el.type))
    },
    filePDAnswer () {
      return this.statement.statement.attachments.filter(el => el.type === '15-02')
    }
  },
  methods: {
    ...mapActions('statePrv', ['changeStatus', 'addAttachStatement']),
    async sendAgreement (type) {
      this.isSend = true
      this.statusStep === 2 && !!this.attachmentCancel.length && await this.addAttachStatement(this.attachmentCancel[0])
      await this.changeStatus({ decision: type })
      this.isSend = false
    }
  }
}
</script>
