<template>
  <div class="view-statement">
    <div class="row">
      <div class="col-md-4-12 d-flex flex-column">
        <span class="kleodora titanic--text anie mb-1">Дата поступления</span>
        <span class="metioche titanic--text">{{ statement.receiptDate | dateRuFormat }}</span>
      </div>
      <div class="col-md-4-12 d-flex flex-column">
        <span class="kleodora titanic--text anie mb-1">Номер СЭД</span>
        <span class="metioche titanic--text">{{ statement.sedNumber }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12 d-flex flex-column">
        <span class="kleodora titanic--text anie mb-1">Прием заявления</span>
        <span class="metioche titanic--text">{{ reception }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12">
        <statement-person-block readonly />
      </div>
    </div>
    <div class="row" v-if="attachmentStatement.length">
      <div class="col-md-12-12 d-flex flex-column">
        <span class="kleodora titanic--text anie mb-1">Пакет документов</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12">
        <rir-file-list
          :files="attachmentStatement"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StatementPersonBlock from '@/components/statmentPerson/StatementPersonBlock'
export default {
  name: 'view-statement',
  components: { StatementPersonBlock },
  props: {
    statement: {
      type: Object
    }
  },
  computed: {
    ...mapState('directory', ['receptions']),
    reception () {
      return this.receptions.find(el => el.code === this.statement.receptionMethod).name
    },
    attachmentStatement () {
      return this.statement.attachments.filter(el => el.type === '01-01')
    }
  }
}
</script>
