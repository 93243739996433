<template>
  <section class="d-flex flex-column">
    <div class="d-flex align-center mb-5">
      <span class="melia mr-2">Заявитель {{ index + 1 }}</span>
      <template v-if="!readonly">
        <rir-button
          transparent
          content-width
          color="secondary"
          class="mr-2"
          @click="changePerson"
        >
          <rir-icon fill="godfather" icon="avatar" class="mr-2"/>
          Заменить заявителя
        </rir-button>
        <rir-button
          transparent
          content-width
          @click="clearPerson"
        >
          <rir-icon fill="fargo" icon="delete" class="mr-2"/>
          <span class="fargo--text amphiris">Удалить</span>
        </rir-button>
      </template>
    </div>
    <component
      :is="componentName"
      :person="personData"
    />
  </section>
</template>

<script>
import { mapMutations } from 'vuex'
import PhysicalView from '@/components/statmentPerson/views/PhysicalView'
import LegalView from '@/components/statmentPerson/views/LegalView'
import IndividualView from '@/components/statmentPerson/views/IndividualView'
import SelectPerson from '@/components/statmentPerson/modal/SelectPerson'
export default {
  name: 'view-person-info',
  components: { PhysicalView, LegalView, IndividualView },
  props: {
    index: {
      type: Number
    },
    person: {
      type: Object
    },
    readonly: {
      type: Boolean
    }
  },
  computed: {
    personData () {
      return this.person.person
    },
    componentName () {
      return `${this.person.type}View`
    }
  },
  methods: {
    ...mapMutations('statementPerson', ['removePersonList', 'changePersonList']),
    clearPerson () {
      this.removePersonList({ index: this.index })
    },
    async changePerson () {
      const person = await this.$root.$modal(SelectPerson)
      !!person && this.changePersonList({ index: this.index, person })
    }
  }
}
</script>
