<template>
  <rir-accordion v-if="isView">
    <template slot="activator">
      <div class="d-flex flex-column">
        <div class="mb-2 titanic--text melia">
          Завершение работ по заявлению
        </div>
        <div class="d-flex align-center">
          <rir-icon
            class="mr-2"
            :icon="isStatus.icon"
            :fill="isStatus.fillIcon"
          />
          <span class="anie terminator--text">{{ isStatus.text }}</span>
        </div>
      </div>
    </template>
    <template v-if="statusStep === 1">
      <div class="d-flex flex-column">
        <rir-checkbox
          :disabled="isChangeCheckbox"
          class="mb-5"
          label="Договор подписан с обеих сторон"
          v-model="statement.signed"
        />
        <rir-checkbox
          :disabled="isChangeCheckbox"
          class="mb-5"
          label="Квитанция оплачена"
          v-model="statement.paid"
        />
        <rir-checkbox
          :disabled="isChangeCheckbox"
          class="mb-5"
          label="Документы лично выданы заявителю"
          v-model="statement.issued"
        />
        <rir-button
          :disabled="!isCloseStatement"
          @click="changeStatusFinish">
          Завершить работы по заявлению</rir-button>
      </div>
    </template>
    <template v-if="statusStep === 2">
      <div class="d-flex flex-column">
        <div class="mb-5">
          <rir-icon
            :fill="isStatus.fillIcon"
            class="mr-2"
            :icon="isStatus.icon"
          />
          <span>{{isStatus.text}}</span>
        </div>
        <a v-if="fileCancel.length" :href="fileCancel[0].url" download target="_blank">
          <rir-button
            class="memento godfather--text"
          >Скачать Документ</rir-button>
        </a>
      </div>
    </template>
    <template v-if="statusStep === 3">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            :fill="isStatus.fillIcon"
            class="mr-2"
            :icon="isStatus.icon"
          />
          <span>{{isStatus.text}}</span>
        </div>
        <div class="pl-6 kleodora anie">Ожиадетеся утверждение от вице-мэра</div>
      </div>
    </template>
  </rir-accordion>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'finish-step',
  data: () => ({
    isChangeCheckbox: false
  }),
  watch: {
    computedModel: {
      async handler (val, oldVal) {
        if (!this.isView) return
        if (JSON.stringify(val) !== JSON.stringify(oldVal) && !this.isChangeCheckbox) {
          this.isChangeCheckbox = true
          await this.updateCheckListFinish(val)
          this.isChangeCheckbox = false
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('session', ['rolePlatform']),
    ...mapState('statePrv', ['statement']),
    computedModel () {
      return Object.assign({}, this.statement)
    },
    isView () {
      return [
        'APPROVING_AGREEMENT_BY_VICE_MAYOR',
        'COMPLETING_HANDLING_STATEMENT',
        'ISSUED_AGREEMENT'
      ].includes(this.statement.statement.status)
    },
    isStatus () {
      switch (this.statement.statement.status) {
        case 'ISSUED_AGREEMENT':
          return {
            icon: 'selected',
            fillIcon: 'matrix',
            text: 'Завершено'
          }
        default:
          return {
            icon: 'in-progress',
            fillIcon: 'rocky',
            text: 'В процессе'
          }
      }
    },
    statusStep () {
      /*
      * 1 - Ожидание оплаты, квитанции, выдачи
      * 2 - Завершено
      * */
      switch (this.statement.statement.status) {
        case 'COMPLETING_HANDLING_STATEMENT':
          return 1
        case 'APPROVING_AGREEMENT_BY_VICE_MAYOR':
          return 3
        default:
          return 2
      }
    },
    isCloseStatement () {
      return this.statement.issued && this.statement.paid && this.statement.signed
    },
    fileCancel () {
      return this.statement.statement.attachments.filter(el => el.type === '07-01')
    }
  },
  methods: {
    ...mapActions('statePrv', ['changeStatus', 'updateCheckListFinish']),
    ...mapMutations('statePrv', ['setProperty']),
    async changeStatusFinish () {
      await this.changeStatus({ decision: 'APPROVE' })
    }
  }
}
</script>
