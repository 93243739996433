export default {
  setProperty: (state, payload) => {
    const { name, value } = payload
    state[name] = value
  },
  mergeProperty: (state, payload) => {
    const { name, value } = payload
    state[name] = state[name].concat(value)
  },
  removeEntityArray: (state, payload) => {
    const { name, value } = payload
    const index = state[name].findIndex(el => el.id === value)
    state[name].splice(index, 1)
  }
}
