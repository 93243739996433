<template>
  <section>
    <div class="row">
      <div class="col-md-12-12">
        <a :href="requestFile.url" class="mt-5 mb-5" download target="_blank">
          <rir-button
            class="memento godfather--text"
          >
            {{ btnFileName }}
          </rir-button>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12">
        <rir-button
          :disabled="!!note"
          @click="changeStat('APPROVE')"
        >Утвердить</rir-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6-12 kleodora titanic--text anie">
        Если документ требует доработки, то опишите правки в поле для комментария
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12">
        <rir-textarea
          label="комментарий"
          v-model="note"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12">
        <rir-button
          :disabled="!note"
          color="error"
          @click="changeStat('RETURN')"
        >Вернуть на доработку</rir-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'director-request',
  data: () => ({
    note: null
  }),
  computed: {
    ...mapState('statePrv', ['statement']),
    requestFile () {
      const statement = this.statement.statement
      if (statement.status === 'APPROVING_REJECTION_BY_DEPARTMENT_DIRECTOR') {
        return statement.attachments.find(el => ['17-01'].includes(el.type))
      }
      return statement.attachments.find(el => ['16-01'].includes(el.type))
    },
    btnFileName () {
      const statement = this.statement.statement
      if (statement.status === 'APPROVING_REJECTION_BY_DEPARTMENT_DIRECTOR') {
        return 'Скачать отказ'
      }
      return 'Скачать договор'
    }
  },
  methods: {
    ...mapActions('statePrv', ['changeStatus']),
    async changeStat (type) {
      await this.changeStatus({ decision: type, note: this.note })
    }
  }
}
</script>
