<template>
  <div class="d-flex flex-column">
    <template v-if="statusStep === 1">
      <template v-if="fileHistory.length">
        <div class="row row--no-grep mt-5">
          <div class="col-md-12-12 px-0 flex-column">
            <div class="mb-5 melia">История документов</div>
            <rir-file-list :files="fileHistory" />
          </div>
        </div>
      </template>
<!--      <template v-if="fileChief.length">-->
<!--        <div class="d-flex flex-column mb-5">-->
<!--          <div class="d-flex align-center">-->
<!--            <rir-icon-->
<!--              fill="snatch"-->
<!--              class="mr-2"-->
<!--              icon="notifications"-->
<!--            />-->
<!--            <span>Возврат на доработку от начальника</span>-->
<!--          </div>-->
<!--          <span class="mb-5 pl-6 titanic&#45;&#45;text anie kleodora">-->
<!--            Скачайте документ с комменатариями руководителя, внести правки и загрузите исправленный документ в систему-->
<!--          </span>-->
<!--          <a-->
<!--            :href="fileChief[0].url"-->
<!--            download-->
<!--            target="_blank">-->
<!--            <rir-button class="memento godfather&#45;&#45;text">Скачать документ</rir-button>-->
<!--          </a>-->
<!--        </div>-->
<!--      </template>-->
      <template v-if="!!statement.statement.note">
        <div class="d-flex flex-column mb-5">
          <div class="d-flex align-center">
            <rir-icon
              fill="snatch"
              class="mr-2"
              icon="notifications"
            />
            <span>Возврат на доработку</span>
          </div>
          <span class="pl-6 titanic--text">
            {{ statement.statement.note }}
          </span>
        </div>
      </template>
      <div class="row row--no-grep">
        <div class="col-md-7-12 pl-0 kleodora anie terminator--text">
          Сформировать данные для конструктора договоров, проверьте и при необходимости отредактируйте его, после чего загрузите в систему
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0">
          <rir-button
            @click="downloadReport"
            class="memento godfather--text"
          >Сформировать данные</rir-button>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0">
          <rir-drag-upload-file
            :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt', '.zip']"
            :count-file="1"
            :upload-url="$root.$uploadServer"
            :params-body="{
              code: 'LAND',
              type: '16-01'
            }"
            v-model="attachment" />
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0">
          <rir-button
            :disabled="!attachment.length"
            :loading="isSend"
            @click="sendAgreement('APPROVE')"
          >Отправить договор на согласование</rir-button>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0 kleodora anie terminator--text">
          Или подготовьте отказ и завершите работы по заявлению
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0">
          <rir-drag-upload-file
            :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt', '.zip']"
            :count-file="1"
            :upload-url="$root.$uploadServer"
            :params-body="{
              code: 'LAND',
              type: '17-01'
            }"
            v-model="attachmentCancel" />
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0">
          <rir-button
            :disabled="!attachmentCancel.length"
            color="error"
            :loading="isSend"
            @click="sendAgreement('CANCEL')"
          >Отправить отказ на согласование</rir-button>
        </div>
      </div>
    </template>
    <template v-if="statusStep === 2">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="matrix"
            class="mr-2"
            icon="selected"
          />
          <span>{{ contentStep.title }}</span>
        </div>
        <span class="pl-6 titanic--text anie mb-2">Ожидаем ответ</span>
        <a v-if="attachment.length" :href="attachment[0].url" class="mb-4" download target="_blank">
          <rir-button
            class="memento godfather--text"
          >Скачать договор</rir-button>
        </a>
      </div>
    </template>
    <template v-if="statusStep === 3">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="snatch"
            class="mr-2"
            icon="notifications"
          />
          <span>Подтверждено</span>
        </div>
        <span class="pl-6 titanic--text anie mb-2">Отправлено в Правовой департамент</span>
        <rir-file-list
          v-if="attachment.length"
          :count-line="1"
          :files="attachment"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  name: 'form-specialist',
  mounted () {
    this.attachment = this.statement.statement.attachments.filter(el => el.type === '16-01')
  },
  data: () => ({
    isSend: false,
    attachment: [],
    attachmentCancel: []
  }),
  computed: {
    ...mapGetters(['GET']),
    ...mapState('statePrv', ['statement']),
    statusStep () {
      /*
      * APPROVING_BY_HEAD_PERSON
      * ANALYZING_DEPARTMENTS_DOCUMENTS
      * APPROVING_LEGAL_DEPARTMENT
      *
      * 1 - пришло ответы от департамента
      * 2 - отправлено руководителю
      * 3 - руководитель утвердил
      * */
      let step
      const statement = this.statement.statement
      switch (statement.status) {
        case 'APPROVING_BY_HEAD_PERSON':
        case 'APPROVING_AGREEMENT_BY_DEPARTMENT_DIRECTOR':
          step = 2
          break
        case 'APPROVING_LEGAL_DEPARTMENT':
        case 'ANALYZING_LEGAL_DEPARTMENT_RESPONSE':
        case 'COMPLETING_HANDLING_STATEMENT':
        case 'PREPARING_REJECTION_PROJECT':
        case 'ISSUED_AGREEMENT':
        case 'REJECTED_BY_HEAD_PERSON':
        case 'REJECTED_BY_LEGAL_DEPARTMENT':
        case 'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_LD':
        case 'COMPLETING_REJECTION_AFTER_LD':
          step = 3
          break
        case 'ANALYZING_DEPARTMENTS_DOCUMENTS':
          step = 1
      }
      return step
    },
    contentStep () {
      let step
      const statement = this.statement.statement
      switch (statement.status) {
        case 'APPROVING_BY_HEAD_PERSON':
          step = {
            title: 'На утверждении у руководителя'
          }
          break
        case 'APPROVING_AGREEMENT_BY_DEPARTMENT_DIRECTOR':
          step = {
            title: 'На утверждении у директора'
          }
          break
        case 'APPROVING_LEGAL_DEPARTMENT':
          step = {
            title: 'На утверждение у правового департамента'
          }
          break
        default:
          step = {
            title: 'Соглашение утверждено'
          }
      }
      return step
    },
    // fileChief () {
    //   return this.statement.statement.attachments.filter(el => el.type === '16-02')
    // },
    fileHistory () {
      return this.statement.statement.attachments.filter(el => ['15-03', '16-04'].includes(el.type))
    }
  },
  methods: {
    ...mapActions('statePrv', ['changeStatus', 'addAttachStatement']),
    async sendAgreement (type) {
      this.isSend = true
      if (type === 'APPROVE') {
        !!this.attachment.length && await this.addAttachStatement(this.attachment[0])
      }
      !!this.attachmentCancel.length && await this.addAttachStatement(this.attachmentCancel[0])
      await this.changeStatus({ decision: type })
      this.isSend = false
    },
    async downloadReport () {
      // const response = await this.GET_BLOB(`land/prv/provision/${this.statement.id}/agreement`,
      //   {},
      //   {
      //     'Content-Type': 'application/json',
      //     Accept: 'application/pdf'
      //   })
      // LSCLOC-434
      try {
        const response = await this.GET(`land/prv/provision/${this.statement.id}/agreement-url`)
        window.open(response.value, '_blank').focus()
      } catch {}
      // const nameFile = /filename\*?=([^']*'')?([^;]*)/.exec(response.headers['content-disposition']) || ['']
      // const url = window.URL.createObjectURL(new Blob([response.data]))
      // const link = document.createElement('a')
      // link.href = url
      // link.setAttribute('download', nameFile[nameFile.length - 1]) // or any other extension
      // document.body.appendChild(link)
      // link.click()
      // link.remove()
    }
  }
}
</script>
