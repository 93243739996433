<template>
  <section class="d-flex flex-column">
    <rir-button
      color="secondary"
      class="mb-8"
      @click="downloadDoc"
    >
      Скачать {{ dataDocument.fileName }}
    </rir-button>
    <rir-button
      class="mb-8"
      :loading="isLoading"
      :disabled="!!comment"
      @click="changeResult('APPROVE')"
    >
      Утвердить
    </rir-button>
    <span
      style="width: 500px"
      class="kleodora mb-4 anie">
        Если документ требует доработки, то опишите правки в поле для комментария
      </span>
    <rir-textarea
      class="mb-6"
      v-model="comment"
      label="Текст комментария"
      :max-rows="5"
    />
    <rir-button
      :loading="isLoading"
      :disabled="!comment"
      color="error"
      @click="changeResult('RETURN')"
    >
      Вернуть на доработку
    </rir-button>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'confirm-document',
  data: () => ({
    isLoading: false,
    comment: null
  }),
  computed: {
    ...mapGetters(['GET_BLOB']),
    ...mapState('stateRds', ['statement']),
    dataDocument () {
      const { decision, attachments } = this.statement
      if (decision === 'AGREEMENT') {
        return {
          downloadDoc: attachments.find(el => el.type === '06-01'),
          fileName: 'соглашение'
        }
      }
      return {
        downloadDoc: attachments.find(el => el.type === '07-01'),
        fileName: 'отказ'
      }
    }
  },
  methods: {
    ...mapActions('stateRds', ['changeStatus']),
    async changeResult (decision) {
      this.isLoading = true
      await this.changeStatus({
        decision,
        note: this.comment
      })
      this.isLoading = false
    },
    async downloadDoc () {
      const response = await fetch(this.dataDocument.downloadDoc.url)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', decodeURI(`${this.dataDocument.downloadDoc.filename}`)) // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }
}
</script>
