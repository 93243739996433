<template>
  <div class="l-form">
    <div class="row">
      <div class="col-md-4-12">
        <rir-form-item
          :error="v.corporateFormCode.$error"
          :message="mesErr(v.corporateFormCode)">
          <rir-select
            label="Правовая форма"
            :items="corporateForms"
            id-value="code"
            text-value="name"
            v-model="value.corporateFormCode"
          />
        </rir-form-item>
      </div>
      <div class="col-md-8-12">
        <rir-form-item
          :error="v.fullName.$error"
          :message="mesErr(v.fullName)">
          <rir-input
            label="Полное название организации"
            v-model="value.fullName"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6-12">
        <rir-form-item
          :error="v.shortName.$error"
          :message="mesErr(v.shortName)">
          <rir-input
            label="Краткое название организациии"
            v-model="value.shortName"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4-12">
        <rir-form-item
          :error="v.inn.$error"
          :message="mesErr(v.inn)">
          <rir-input
            label="ИНН организации"
            v-model="value.inn"
            v-mask="'##########'"
          />
        </rir-form-item>
      </div>
      <div class="col-md-8-12">
        <rir-form-item
          :error="v.legalAddress.$error"
          :message="mesErr(v.legalAddress)">
          <rir-input
            label="Юридический адрес"
            v-model="value.legalAddress"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12 crephusa">
        Информация о руководителе организации
      </div>
    </div>
    <div class="row">
      <div class="col-md-5-12">
        <rir-button
          @click="selectPhysical"
          class="memento godfather--text">
          <rir-icon icon="avatar" class="mr-2"/>
          Выбрать физическое лицо из списка
        </rir-button>
      </div>
      <div class="col-md-3-12 d-flex align-center" v-if="physical">
        <a
          @click="editPhysical"
          class="rocky--text">
          <rir-icon icon="edit" class="mr-2"/>
          Редактировать информацию
        </a>
      </div>
      <div class="col-md-2-12 d-flex align-center" v-if="physical">
        <a
          @click="clearPhysical"
          class="rocky--text">
          <rir-icon icon="delete" class="mr-2"/>
          Очистить
        </a>
      </div>
    </div>
    <div class="row" v-if="physical">
      <div class="col-md-12-12">
        <p-view :personId="physical.id" :key="physical.id" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4-12">
        <rir-form-item>
          <rir-input
            :readonly="!!physical"
            label="Фамилия руководителя"
            v-model="value.headPersonLastName"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item>
          <rir-input
            :readonly="!!physical"
            label="Имя руководителя"
            v-model="value.headPersonFirstName"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item>
          <rir-input
            :readonly="!!physical"
            label="Отчество руководителя"
            v-model="value.headPersonMiddleName"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6-12">
        <rir-form-item>
          <rir-input
            label="Должность руководителя"
            v-model="value.headPersonPost"
          />
        </rir-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import SelectPhysical from '@/views/persons/modal/SelectPhysical'
import PView from '@/views/persons/layout/PView'
import FormPhysical from '@/views/persons/modal/FormPhysical'
export default {
  name: 'l-form',
  mounted () {
    this.physical = this.value.headPerson
    this.physical && this.changePhysical(this.physical)
  },
  mixins: [validateMixin],
  directives: { mask },
  components: { PView },
  data: () => ({
    physical: null
  }),
  props: {
    value: {
      type: Object
    },
    v: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('directory', ['corporateForms'])
  },
  methods: {
    async selectPhysical () {
      const physical = await this.$root.$modal(SelectPhysical, {
        returnSelect: true,
        personType: 'physical'
      })
      if (physical) {
        this.physical = null
        setTimeout(() => {
          this.changePhysical(physical)
        })
      }
    },
    async editPhysical () {
      const physical = await this.$root.$modal(FormPhysical, {
        physicalId: this.physical.id
      })
      if (physical) {
        this.physical = null
        setTimeout(() => {
          this.changePhysical(physical)
        })
      }
    },
    changePhysical (physical) {
      this.physical = physical
      this.value.physicalPersonId = this.physical.id
      this.value.headPersonPost = null
      this.value.headPersonLastName = physical.lastName
      this.value.headPersonFirstName = physical.firstName
      this.value.headPersonMiddleName = physical.middleName
    },
    clearPhysical () {
      this.physical = null
      this.value.headPersonPost = null
      this.value.headPersonLastName = null
      this.value.headPersonFirstName = null
      this.value.headPersonMiddleName = null
    }
  }
}
</script>
<style lang="scss" scoped>
.l-form{
  margin-top: 20px;
}
</style>
