<template>
  <section class="land-service-item">
    <div
      v-if="isLoadData">Загрузка</div>
    <template
      v-else>
      <land-service-form
        :statement="statement"
        :redistribution="redistribution"
      />
      <template
        v-if="!!formSettings.components">
        <component
          v-for="comp in formSettings.components"
          :is="comp"
          :key="comp.name"
        />
      </template>
    </template>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LandServiceForm from '@/views/landServiceRds/LandServiceForm'
import formState from '@/views/landServiceRds/utils/formState'
export default {
  name: 'land-service-item',
  components: { LandServiceForm },
  mounted () {
    this.loadData()
  },
  data: () => ({
    isLoadData: true
  }),
  props: {
    requestId: {
      type: String
    }
  },
  computed: {
    ...mapState('stateRds', ['statement', 'redistribution']),
    formSettings () {
      return formState.apply(this, [this.statement?.status])
    }
  },
  methods: {
    ...mapActions('stateRds', ['fullStatementItem']),
    async loadData () {
      this.isLoadData = true
      await this.fullStatementItem({
        id: this.requestId
      })
      this.isLoadData = false
    }
  }
}
</script>

<style lang="scss" scoped>
.land-service-item{
  display: flex;
  flex-direction: column;
  &__column{
    display: flex;
    align-items: center
  }
}
</style>
