<template>
  <section class="d-flex flex-column">
    <template v-if="status === 'STARTED'">
      <span class="kleodora terminator--text anie mb-4">Загрузите в систему ответ Росреестра, запрошенный через РСМЭВ</span>
      <rir-drag-upload-file
        :count-line="1"
        class="mb-4"
        :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt']"
        :count-file="0"
        v-model="attachment"
        :upload-url="$root.$uploadServer"
        :params-body="{
          code: 'LAND',
          type: '12-02'
        }" />
      <rir-button
        :disabled="!attachment.length"
        :loading="isLoading"
        @click="changeStatus">Загрузить ответ</rir-button>
    </template>
    <template v-if="status === 'FINISHED'">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="snatch"
            class="mr-2"
            icon="notifications"
          />
          <span>Получен ответ</span>
        </div>
        <span class="mb-5 pl-6 titanic--text anie">Ознакомьтесь с документами</span>
<!--        <a-->
<!--          v-if="downloadFile.length"-->
<!--          :href="downloadFile[0].url"-->
<!--          download-->
<!--          target="_blank">-->
<!--          <rir-button class="memento godfather&#45;&#45;text">Скачать документ</rir-button>-->
<!--        </a>-->
        <rir-file-list
          :count-line="1"
          :files="downloadFile"
        />
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'department-rosreestor',
  data: () => ({
    isLoading: false,
    attachment: []
  }),
  props: {
    status: {
      type: String
    }
  },
  computed: {
    ...mapState('statePrv', ['statement']),
    downloadFile () {
      return this.statement.statement.attachments.filter(el => el.type === '12-02')
    }
  },
  methods: {
    ...mapActions('statePrv', ['addAttachStatement']),
    async changeStatus () {
      this.isLoading = true
      !!this.attachment.length && await this.addAttachStatement(this.attachment)
      await this.$emit('changeStatus', 'Rosreestr')
      this.isLoading = false
    }
  }
}
</script>
