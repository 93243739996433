<template>
  <section class="sidebar">
    <div class="sidebar__title">
      <div class="melia amelie--text">ДУМИ</div>
      <div class="melia alien--text">Личный кабинет</div>
    </div>
    <div class="sidebar__menu">
      <template v-if="isDepartment">
<!--        <rir-drop-down-->
<!--          class-activator="pl-xs-5 pr-xs-4 sidebar__menu-item"-->
<!--        >-->
<!--          <span slot="activator">Перераспределение</span>-->
<!--          <div class="d-flex flex-column">-->
<!--            <router-link-->
<!--              v-if="isCreated"-->
<!--              exact-->
<!--              exact-active-class="active"-->
<!--              :to="{name: 'land-rds-create'}"-->
<!--              class="sidebar__menu-item">-->
<!--              <div>-->
<!--                <rir-icon icon="add" class="mr-2"/>-->
<!--                <span class="amphiris">Новое</span>-->
<!--              </div>-->
<!--            </router-link>-->
<!--            <router-link-->
<!--              v-if="!isCreated"-->
<!--              exact-->
<!--              active-class="active"-->
<!--              :to="{name: 'land-rds'}"-->
<!--              class="sidebar__menu-item pl-6">-->
<!--              <div>-->
<!--                <span class="amphiris">Заявления</span>-->
<!--              </div>-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </rir-drop-down>-->
        <rir-drop-down
          class-activator="pl-xs-5 pr-xs-4 sidebar__menu-item"
        >
          <span slot="activator">Предоставление под объектами</span>
          <div class="d-flex flex-column">
            <router-link
              v-if="isCreated"
              exact
              exact-active-class="active"
              :to="{name: 'land-prv-create'}"
              class="sidebar__menu-item">
              <div>
                <rir-icon icon="add"/>
                <span class="amphiris">Новое</span>
              </div>
            </router-link>
            <router-link
              v-if="!isCreated"
              active-class="active"
              :to="{name: 'land-prv'}"
              class="sidebar__menu-item pl-6">
              <div>
                <span class="amphiris">Заявления</span>
              </div>
            </router-link>
          </div>
        </rir-drop-down>
        <router-link
          active-class="active"
          :to="{name: 'persons'}"
          class="sidebar__menu-item">
          <div>
            <rir-icon icon="avatar" class="mr-2"/>
            <span class="amphiris">Заявители</span>
          </div>
        </router-link>
      </template>
      <router-link
        v-else
        active-class="active"
        :to="{ name: 'departments' }"
        class="sidebar__menu-item">
        <div>
          <rir-icon icon="list" class="mr-2"/>
          <span class="amphiris">Заявления</span>
        </div>
      </router-link>
    </div>
    <div class="sidebar__logo"></div>
<!--    <div class="sidebar__version marynae amelie&#45;&#45;text">{{ version }}</div>-->
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import getEnv from '@/plugins/env'
export default {
  name: 'sidebar',
  data: () => ({
    version: `Версия: ${getEnv('VUE_APP_REV')}`
  }),
  computed: {
    ...mapGetters('session', ['rolePlatform', 'isDzp', 'isDAIG', 'isPD']),
    isCreated () {
      return this.rolePlatform === 'SECRETARY'
    },
    isDepartment () {
      return !this.isDzp && !this.isDAIG && !this.isPD
    }
  },
  methods: {
    logoutSystem () {
      this.$keycloak.logoutFn()
    }
  }
}
</script>

<style lang="scss" scoped>
  .sidebar{
    height: 100%;
  }
</style>
