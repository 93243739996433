<template>
  <section class="pl-8 pr-8 mt-5">
    <div class="row row--no-grep">
      <div class="col-md-6-12 pl-0 d-flex flex-column">
        <span class="melia mb-5">Земельный участок в собственности</span>
        <rir-form-item
          class="mb-5"
          :error="$v.model.propertyLandArea.cadastralNumber.$error"
          :message="mesErr($v.model.propertyLandArea.cadastralNumber)"
        >
          <rir-input
            :readonly="readonly"
            v-mask="'##:##:#######:####'"
            label="Кадастровый номер"
            v-model="model.propertyLandArea.cadastralNumber"
          />
        </rir-form-item>
        <rir-form-item
          class="mb-5"
        >
          <rir-input
            :readonly="readonly"
            label="Адрес"
            v-model="model.propertyLandArea.address"
          />
        </rir-form-item>
        <rir-form-item
          :error="$v.model.propertyLandArea.area.$error"
          :message="mesErr($v.model.propertyLandArea.area)">
          <rir-input
            :readonly="readonly"
            label="Площадь, м2"
            v-model="model.propertyLandArea.area"
          />
        </rir-form-item>
      </div>
      <div class="col-md-6-12 pr-0 d-flex flex-column">
        <span class="melia mb-5">Образованный земельный участок</span>
        <rir-form-item
          class="mb-5"
          :error="$v.model.formedLandArea.cadastralNumber.$error"
          :message="mesErr($v.model.formedLandArea.cadastralNumber)"
        >
          <rir-input
            :readonly="readonly"
            v-mask="'##:##:#######:####'"
            label="Кадастровый номер"
            v-model="model.formedLandArea.cadastralNumber"
          />
        </rir-form-item>
        <rir-form-item
          class="mb-5"
          :error="$v.model.formedLandArea.address.$error"
          :message="mesErr($v.model.formedLandArea.address)"
        >
          <rir-input
            :readonly="readonly"
            label="Адрес"
            v-model="model.formedLandArea.address"
          />
        </rir-form-item>
        <rir-form-item
          :error="$v.model.formedLandArea.area.$error"
          :message="mesErr($v.model.formedLandArea.area)">
          <rir-input
            :readonly="readonly"
            label="Площадь, м2"
            v-model="model.formedLandArea.area"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <span class="col-md-12-12 px-0 melia">C чем перераспределяется земельным участком, государственная собственность на которые не разграничена?</span>
    </div>
    <div
      class="row row--no-grep"
      v-for="(la, index) in model.landAreas"
      :key="la.id"
    >
      <redistribution-item
        :readonly="readonly"
        :land-area="la"
        :index="index"
        @remArea="remArea"
        :v="$v.model.landAreas.$each[index]"
      />
    </div>
    <div
      class="row row--no-grep"
      v-if="!readonly"
    >
      <div class="col-md-12-12 px-0">
        <rir-button
          color="secondary"
          @click="addArea">
          Добавить участок
        </rir-button>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12 px-0 d-flex flex-column">
        <span class="melia mb-5">Основание перераспределения</span>
        <div class="mb-5">
          <rir-form-item
            :error="$v.model.basis.$error"
            :message="mesErr($v.model.basis)">
            <rir-radio-group
              is-column
              v-model="model.basis"
            >
              <rir-radio
                :readonly="readonly"
                v-for="r in redistributions"
                :key="r.code"
                :label="r.name"
                :value="r.code"
              />
            </rir-radio-group>
          </rir-form-item>
        </div>
        <div class="row row--no-grep">
          <div class="col-md-4-12 pl-0">
            <rir-form-item>
              <rir-input
                :readonly="readonly"
                label="Номер"
                v-model="model.orderNumber"
              />
            </rir-form-item>
          </div>
          <div class="col-md-4-12">
            <rir-form-item>
              <rir-date-picker
                :readonly="readonly"
                click-close
                label="Дата"
                v-model="model.orderDate"
              />
            </rir-form-item>
          </div>
          <div class="col-md-4-12 pr-0" v-if="model.basis === 'ORDER'">
            <rir-form-item>
              <rir-input
                :readonly="readonly"
                label="Наименование"
                v-model="model.orderName"
              />
            </rir-form-item>
          </div>
        </div>
        <div class="row row--no-grep">
          <div class="col-md-6-12 mb-0 px-0">
            <rir-form-item
              :error="$v.model.areaIncreaseFee.$error"
              :message="mesErr($v.model.areaIncreaseFee)">
              <rir-input
                :readonly="readonly"
                label="Плата за увеличение площади земельного участка, руб."
                v-model="model.areaIncreaseFee"
              />
            </rir-form-item>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import RedistributionItem from '@/views/landServiceRds/components/RedistributionItem'
import { regEx, required, typeFloatOrNumber } from '@/plugins/vuelidate/customValidate'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
export default {
  name: 'lands-data',
  mounted () {
    this.model = { ...this.model, ...this.redistribution }
  },
  directives: { mask },
  mixins: [validationMixin, validateMixin],
  components: { RedistributionItem },
  data: () => ({
    model: {
      propertyLandArea: {
        cadastralNumber: null,
        address: null,
        area: null
      },
      formedLandArea: {
        cadastralNumber: null,
        address: null,
        area: null
      },
      basis: null,
      orderNumber: null,
      orderDate: null,
      orderName: null,
      areaIncreaseFee: null,
      landAreas: []
    }
  }),
  props: {
    redistribution: {
      type: Object
    },
    readonly: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('directory', ['redistributions'])
  },
  validations () {
    return {
      model: {
        propertyLandArea: {
          cadastralNumber: {
            required: required('Обязательное поле'),
            regEx: regEx(/\d{2}:\d{2}:\d{7}:\d{1,4}?/, 'Формат XX:XX:XXXXXXX:XXXX')
          },
          area: {
            typeFloatOrNumber: typeFloatOrNumber('Укажите числовое значение')
          }
        },
        formedLandArea: {
          cadastralNumber: {
            required: required('Обязательное поле'),
            regEx: regEx(/\d{2}:\d{2}:\d{7}:\d{1,4}?/, 'Формат XX:XX:XXXXXXX:XXXX')
          },
          address: {
            required: required('Обязательное поле')
          },
          area: {
            required: required('Обязательное поле'),
            typeFloatOrNumber: typeFloatOrNumber('Укажите числовое значение')
          }
        },
        landAreas: {
          $each: {
            landAreaTypeCode: {
              required: required('Обязательное поле')
            },
            cadastralNumber: {
              required: function (a, b) {
                if (['CODE2', 'CODE3'].includes(b.landAreaTypeCode)) {
                  return required('Обязательное поле')(a)
                }
                return true
              },
              regEx: regEx(/\d{2}:\d{2}:\d{7}:\d{1,4}?/, 'Формат XX:XX:XXXXXXX:XXXX')
            },
            area: {
              typeFloatOrNumber: typeFloatOrNumber('Укажите числовое значение')
            }
          }
        },
        basis: {
          required: required('Обязательное поле')
        },
        areaIncreaseFee: {
          typeFloatOrNumber: typeFloatOrNumber('Укажите числовое значение')
        }
      }
    }
  },
  methods: {
    addArea () {
      this.model.landAreas.push({
        landAreaTypeCode: null,
        cadastralNumber: null,
        address: null,
        area: null
      })
    },
    remArea (index) {
      this.model.landAreas.splice(index, 1)
    }
  }
}
</script>
