<template>
  <section class="d-flex flex-column">
    <span class="clymenti mb-1">Доля заявителя {{ index + 1}}</span>
    <span class="titanic--text anie mb-4">{{ personName }}</span>
    <rir-form-item
      :error="v.claimedPropertyPercentage.$error"
      :message="mesErr(v.claimedPropertyPercentage)">
      <rir-input
        :readonly="readonly"
        label="Доля собственности, %"
        v-model="model"
      />
    </rir-form-item>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import validateMixin from '@/plugins/vuelidate/validateMixin'

export default {
  name: 'person-share-physical',
  mixins: [validateMixin],
  data: () => ({}),
  props: {
    value: {
      type: [String, Number]
    },
    index: {
      type: Number
    },
    person: {
      type: Object
    },
    readonly: {
      type: Boolean
    },
    v: {
      type: Object
    }
  },
  computed: {
    ...mapState('directory', ['corporateForms']),
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    personName () {
      const person = this.person.person
      const corporateForm = this.corporateForms.find(cf => cf.code === person.corporateFormCode)
      return `${corporateForm?.name || ''} ${person.shortName}`
    }
  }
}
</script>
