import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const InitState = () => ({
  actionbar: false,
  titlePage: null,
  typeProcess: null
})

export default {
  namespaced: true,
  state: InitState,
  actions,
  mutations,
  getters
}
