<template>
  <div class="d-flex flex-column">
    <template v-if="statusStep === 1">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            class="mr-2"
            icon="waiting"
          />
          <span>Подготовка заявления специалистом</span>
        </div>
        <span class="pl-6 titanic--text anie">Ожидание</span>
      </div>
    </template>
    <template v-if="statusStep === 2">
      <div class="d-flex flex-column mb-5">
        <div class="d-flex align-center">
          <rir-icon
            fill="matrix"
            class="mr-2"
            icon="selected"
          />
          <span>Отправлено руководителю</span>
        </div>
        <span class="pl-6 titanic--text anie">Ожидаем ответ</span>
      </div>
      <a v-if="fileSpecialist.length" :href="fileSpecialist[0].url" class="mb-4" download target="_blank">
        <rir-button
          class="memento godfather--text"
        >Скачать договор</rir-button>
      </a>
<!--      <rir-drag-upload-file-->
<!--          class="mb-4"-->
<!--          :count-file="1"-->
<!--          :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt', '.zip']"-->
<!--          :upload-url="$root.$uploadServer"-->
<!--          :params-body="{-->
<!--              code: 'LAND',-->
<!--              type: '16-02'-->
<!--            }"-->
<!--          v-model="attachmentTrue" />-->
      <rir-button
        :disabled="!!comment"
        :loading="isSend"
        class="mb-6"
        @click="sendAgreement('APPROVE')">Утвердить</rir-button>
      <span class="mb-4 titanic--text anie kleodora">Если документ требует доработки, то опишите правки в поле для комментария</span>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0">
          <rir-textarea
            class="mb-6"
            v-model="comment"
            label="Текст комментария"
            :max-rows="5"
          />
<!--          <rir-drag-upload-file-->
<!--            class="mb-4"-->
<!--            :count-file="1"-->
<!--            :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt', '.zip']"-->
<!--            :upload-url="$root.$uploadServer"-->
<!--            :params-body="{-->
<!--              code: 'LAND',-->
<!--              type: '16-03'-->
<!--            }"-->
<!--            v-model="attachment" />-->
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0">
          <rir-button
            :disabled="!comment"
            color="error"
            :loading="isSend"
            @click="sendAgreement('RETURN')"
          >Вернуть на доработку</rir-button>
        </div>
      </div>
    </template>
    <template v-if="statusStep === 3">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="snatch"
            class="mr-2"
            icon="notifications"
          />
          <span>Подтверждено</span>
        </div>
        <span class="pl-6 titanic--text anie">{{ contentStep.title }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'form-chief',
  data: () => ({
    isSend: false,
    // attachment: [],
    // attachmentTrue: [],
    comment: null
  }),
  computed: {
    ...mapGetters(['GET_BLOB']),
    ...mapState('statePrv', ['statement']),
    statusStep () {
      /*
      * APPROVING_BY_HEAD_PERSON
      * ANALYZING_DEPARTMENTS_DOCUMENTS
      * APPROVING_LEGAL_DEPARTMENT
      *
      * 1 - ожидание или возврат на загрузку соглашения
      * 2 - проверка соглашения
      * 3 - Решение руководителя
      * */
      let step
      const statement = this.statement.statement
      switch (statement.status) {
        case 'ANALYZING_DEPARTMENTS_DOCUMENTS':
          step = 1
          break
        case 'APPROVING_BY_HEAD_PERSON':
          step = 2
          break
        case 'APPROVING_LEGAL_DEPARTMENT':
        case 'APPROVING_AGREEMENT_BY_DEPARTMENT_DIRECTOR':
        case 'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_LD':
        case 'COMPLETING_REJECTION_AFTER_LD':
          step = 3
          break
      }
      return step
    },
    contentStep () {
      let step
      const statement = this.statement.statement
      switch (statement.status) {
        case 'APPROVING_LEGAL_DEPARTMENT':
          step = {
            title: 'На утверждение у правового департамента'
          }
          break
        case 'APPROVING_AGREEMENT_BY_DEPARTMENT_DIRECTOR':
          step = {
            title: 'На утверждении у директора'
          }
          break
        default:
          step = {
            title: 'Соглашение утверждено'
          }
      }
      return step
    },
    fileChief () {
      return this.statement.statement.attachments.filter(el => el.type === '16-02')
    },
    fileSpecialist () {
      return this.statement.statement.attachments.filter(el => el.type === '16-01')
    }
  },
  methods: {
    ...mapActions('statePrv', ['changeStatus', 'addAttachStatement']),
    async sendAgreement (type) {
      this.isSend = true
      // !!this.attachment.length && await this.addAttachStatement(this.attachment[0])
      // !!this.attachmentTrue.length && await this.addAttachStatement(this.attachmentTrue[0])
      await this.changeStatus({ decision: type, note: this.comment })
      this.isSend = false
    },
    async downloadReport () {
      const response = await this.GET_BLOB(`land/prv/provision/${this.statement.id}/agreement`,
        {},
        {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Соглашение.odt') // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }
}
</script>
