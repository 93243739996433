import DirectorRequest from '@/views/landServicePrv/components/DirectorRequest'
import MayorRequest from '@/views/landServicePrv/components/MayorRequest'

export default function (status) {
  let state
  const userRole = this.$store.getters['session/rolePlatform']
  const isDzp = this.$store.getters['session/isDzp']
  const isDAIG = this.$store.getters['session/isDAIG']
  const isPD = this.$store.getters['session/isPD']
  if (['DEPARTMENT_DIRECTOR', 'VICE_MAYOR'].includes(userRole)) {
    const components = []
    switch (status) {
      case 'APPROVING_AGREEMENT_BY_VICE_MAYOR':
        if (['VICE_MAYOR'].includes(userRole)) {
          components.push(MayorRequest)
        }
        break
      case 'APPROVING_REJECTION_BY_DEPARTMENT_DIRECTOR':
      case 'APPROVING_AGREEMENT_BY_DEPARTMENT_DIRECTOR':
        if (['DEPARTMENT_DIRECTOR'].includes(userRole)) {
          components.push(DirectorRequest)
        }
        break
    }
    return {
      title: 'Заявление',
      isEdit: false,
      isRead: true,
      isLandsData: true,
      components
    }
  } else if (isDzp || isDAIG || isPD) {
    return {
      title: 'Заявление',
      isEdit: false,
      isRead: true,
      isLandsData: true
    }
  } else {
    switch (status) {
      case 'ASSIGNING_EXECUTOR':
        state = {
          isEdit: false,
          isRead: false
        }
        break
      case 'ANALYZING_DOCUMENTS_COMPLETENESS':
        state = {
          title: 'Заявление',
          isEdit: true,
          isRead: false,
          isLandsData: true,
          actions: [
            {
              color: 'primary',
              text: 'Сохранить',
              action: () => this.updateRequest()
            },
            {
              color: 'primary',
              text: 'Отправить в работу',
              action: async () => {
                if (await this.updateRequest()) {
                  this.$store.dispatch('statePrv/changeStatus', { decision: 'APPROVE' })
                }
              }
            },
            {
              color: 'error',
              text: 'Дозапрос документов у заявителя',
              action: async () => {
                if (await this.updateRequest()) {
                  this.$store.dispatch('statePrv/changeStatus', { decision: 'CANCEL' })
                }
              }
            }
          ]
        }
        break
      case 'REQUERYING_DOCUMENTS_FROM_APPLICANT':
        state = {
          title: 'Заявление',
          isEdit: true,
          isRead: false,
          isLandsData: true,
          actions: [
            {
              color: 'primary',
              text: 'Сохранить',
              action: () => this.updateRequest()
            },
            {
              color: 'primary',
              text: 'Отправить в работу',
              action: async () => {
                await this.updateRequest()
                this.$store.dispatch('statePrv/changeStatus', { decision: 'APPROVE' })
              }
            },
            {
              color: 'error',
              text: 'Возврат документов',
              action: async () => {
                await this.updateRequest()
                this.$store.dispatch('statePrv/changeStatus', { decision: 'CANCEL' })
              }
            }
          ]
        }
        break
      case 'REQUESTING_FROM_DEPARTMENTS':
      case 'ANALYZING_DEPARTMENTS_DOCUMENTS':
      case 'APPROVING_BY_HEAD_PERSON':
      case 'PREPARING_LEGAL_DEPARTMENT_REQUEST':
      case 'APPROVING_LEGAL_DEPARTMENT':
      case 'ANALYZING_LEGAL_DEPARTMENT_RESPONSE':
      case 'COMPLETING_HANDLING_STATEMENT':
      case 'PREPARING_REJECTION_PROJECT':
      case 'ISSUED_AGREEMENT':
      case 'REJECTED_BY_HEAD_PERSON':
      case 'REJECTED':
      case 'REJECTED_BY_LEGAL_DEPARTMENT':
      case 'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_DEPARTMENTS':
      case 'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_LD':
      case 'COMPLETING_REJECTION_AFTER_DEPARTMENTS':
      case 'CORRECTING_REJECTION':
      case 'COMPLETING_REJECTION_AFTER_LD':
      case 'APPROVING_REJECTION_BY_DEPARTMENT_DIRECTOR':
      case 'APPROVING_AGREEMENT_BY_DEPARTMENT_DIRECTOR':
      case 'APPROVING_AGREEMENT_BY_VICE_MAYOR':
      case 'APPROVING_REJECTION_BY_LD_AFTER_DEPARTMENTS':
      case 'ANALYZING_LEGAL_DEPARTMENT_REJECT_RESPONSE':
        state = {
          title: 'Заявление',
          isEdit: true,
          isRead: true,
          isLandsData: true,
          actions: [
            {
              color: 'primary',
              text: 'Продолжить',
              action: () => {
                this.$router.push({
                  name: 'land-prv-item-status',
                  params: { requestId: this.$route.params.requestId }
                })
              }
            }
          ]
        }
        break
      case 'RETURNED':
        state = {
          title: 'Заявление',
          isEdit: false,
          isRead: true,
          isLandsData: true
        }
        break
      default:
        state = {
          title: 'Новое заявление со строением',
          isEdit: true,
          isRead: false,
          isLandsData: false,
          actions: [
            {
              color: 'primary',
              text: 'Создать заявление',
              action: () => this.saveRequest()
            }
          ]
        }
    }
    return state
  }
}
