import { helpers } from 'vuelidate/lib/validators'
export const required = (mesErr) =>
  helpers.withParams(
    { type: 'required', mesErr: mesErr },
    (value) => !!value && helpers.req(value)
  )
export const regEx = (regEx, mesErr) =>
  helpers.withParams(
    { type: 'regEx', mesErr: mesErr },
    (value) => !value || regEx.test(value)
  )
export const typeFloatOrNumber = (mesErr) =>
  helpers.withParams(
    { type: 'typeFloat', mesErr: mesErr },
    // eslint-disable-next-line eqeqeq
    (value) => !value || Number(value) == value
  )
export const minLength = (countLength, mesErr) =>
  helpers.withParams(
    { type: 'minLength', mesErr: mesErr },
    (value) => !!value && value.length >= countLength
  )
export const strLength = (countLength, mesErr) =>
  helpers.withParams(
    { type: 'minLength', mesErr: mesErr },
    (value) => !value || value.toString().length === countLength
  )
export const apiCheck = (action, mesErr) =>
  helpers.withParams(
    { type: 'apiCheck', mesErr: mesErr },
    async () => {
      return await action()
    }
  )
// Определение равности всех значений сумме
export const sumDataEach = (attr, sum, mesErr) =>
  helpers.withParams(
    { type: 'typeFloat', mesErr: mesErr },
    // eslint-disable-next-line eqeqeq
    (value) => {
      const val = value.reduce((acc, el) => {
        acc = acc + parseFloat(el[attr])
        return acc
      }, 0)
      return parseFloat(val).toFixed(2) === sum.toFixed(2)
    }
  )
