<template>
  <section class="col-md-12-12 px-0 d-flex flex-column mb-0">
    <div class="mb-5 d-flex align-center">
      <span class="melia mr-8">Участок {{ index+1 }}</span>
      <a
        v-if="!readonly"
        @click="remove"
        class="d-flex align-center godfather--text amphiris">
        <rir-icon fill="fargo" icon="delete" class="mr-2"/>
        <span class="fargo--text amphiris">Удалить</span>
      </a>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-8-12 px-0">
        <rir-form-item
          :error="v.landAreaTypeCode.$error"
          :message="mesErr(v.landAreaTypeCode)">
          <rir-select
            :readonly="readonly"
            label="Тип распределенного участка"
            :items="areaType"
            id-value="code"
            text-value="name"
            v-model="landArea.landAreaTypeCode"
          />
        </rir-form-item>
      </div>
    </div>
    <div
      v-if="landArea.landAreaTypeCode"
      :key="`${landArea.landAreaTypeCode}-${index}`"
      class="row row--no-grep">
      <div class="col-md-3-12 pl-0" v-if="isFullField">
        <rir-form-item
          :error="v.cadastralNumber.$error"
          :message="mesErr(v.cadastralNumber)">
          <rir-input
            :readonly="readonly"
            v-mask="'##:##:#######:####'"
            label="Кадастровый номер"
            v-model="landArea.cadastralNumber"
          />
        </rir-form-item>
      </div>
      <div
        :class="{'pl-0': !isFullField}"
        class="col-md-3-12">
        <rir-form-item
          :error="v.area.$error"
          :message="mesErr(v.area)">
          <rir-input
            :readonly="readonly"
            label="Площадь, м2"
            v-model="landArea.area"
          />
        </rir-form-item>
      </div>
      <div class="col-md-6-12" v-if="isFullField">
        <rir-form-item>
          <rir-input
            :readonly="readonly"
            label="Адрес"
            v-model="landArea.address"
          />
        </rir-form-item>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import validateMixin from '@/plugins/vuelidate/validateMixin'
export default {
  name: 'redistribution-item',
  directives: { mask },
  mixins: [validateMixin],
  props: {
    landArea: {
      type: Object
    },
    index: {
      type: Number
    },
    v: {
      type: Object
    },
    readonly: {
      type: Boolean
    }
  },
  watch: {
    'landArea.landAreaTypeCode': function () {
      this.clearField()
    }
  },
  computed: {
    ...mapState('directory', ['areaType']),
    isFullField () {
      return ['CODE2', 'CODE3'].includes(this.landArea.landAreaTypeCode)
    }
  },
  methods: {
    clearField () {
      this.v.$reset()
      this.landArea.cadastralNumber = null
      this.landArea.address = null
      this.landArea.area = null
    },
    remove () {
      this.$emit('remArea', this.index)
    }
  }
}
</script>
