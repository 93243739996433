import Vue from 'vue'
import App from './App.vue'
import './plugins/axios'
import './registerServiceWorker'
import RirLib from './plugins/RirLib'
import router from './router'
import store from './store'
import './assets/scss/main.scss'
import Vuelidate from 'vuelidate'
import filterDate from '@/plugins/filters/filterDate'
import filterNumber from '@/plugins/filters/filterNumber'
import filterString from '@/plugins/filters/filterString'

import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import getEnv from '@/plugins/env'

Vue.config.productionTip = false
Vue.use(filterDate)
Vue.use(filterNumber)
Vue.use(filterString)
Vue.use(Vuelidate)
Vue.use(RirLib)

const initKeyCloak = () => {
  return new Promise(resolve => {
    Vue.use(VueKeyCloak, {
      init: {
        onLoad: 'login-required',
        checkLoginIframe: false
      },
      config: {
        authRealm: getEnv('VUE_APP_KC_REALM'),
        authUrl: getEnv('VUE_APP_KC_AUTH'),
        authClientId: getEnv('VUE_APP_KC_CLIENT')
      },
      onReady: (keycloak) => {
        store.dispatch('directory/loadDirectories')
        store.dispatch('session/getUserInfo')
          .then(() => {
            const role = store.getters['session/rolePlatform']
            !role ? keycloak.logout() : resolve()
          })
      }
    })
  })
}

const checkStartApp = (role) => {
  const isDzp = store.getters['session/isDzp']
  const isDAIG = store.getters['session/isDAIG']
  const isPD = store.getters['session/isPD']
  if (isDzp || isDAIG || isPD) {
    router.push({ name: 'departments' })
    return
  }
  if (role === 'SECRETARY') {
    router.push({ name: 'land-prv-create' })
  } else {
    router.push({ name: 'land-prv' })
  }
}

export default new Vue({
  created () {
    this.$root.$uploadServer = `${getEnv('VUE_APP_API_SERVER')}upload-by-code`
  },
  async mounted () {
    await initKeyCloak()
    const role = store.getters['session/rolePlatform']
    this.$route.meta.roles.some(el => el === role) || checkStartApp(role)
    this.initSession = true
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  data: () => ({
    windowWidth: window.innerWidth,
    initSession: false
  }),
  router,
  store,
  // eslint-disable-next-line vue/require-render-return
  render (h) {
    if (this.initSession) {
      return h(App)
    }
  }
}).$mount('#app')
