<template>
  <rir-cards-block>
    <section slot="title" class="d-flex flex-column px-8">
      <span class="nomira mb-2">Заявители</span>
      <span class="titanic--text anie metioche">№ {{ item.number }}</span>
    </section>
    <div
      class="d-flex flex-column px-8"
      v-for="(person, index) in applicants"
      :key="index">
      <view-person-info
        :person="person"
        :index="index"
        readonly
      />
    </div>
  </rir-cards-block>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewPersonInfo from '@/components/statmentPerson/ViewPersonInfo'

export default {
  name: 'applicants-list-modal',
  components: { ViewPersonInfo },
  mounted () {
    // const typeLand = this.$route.name.split('-')
    this.loadApplicants('prv')
  },
  data: () => ({
    applicants: [],
    isLoading: false
  }),
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['GET'])
  },
  methods: {
    async loadApplicants (typeLand) {
      this.isLoading = true
      const applicants = await this.GET(`land/${typeLand}/statement/${this.item.idStatement || this.item.id}/applicants`)
      const isEdit = [
        'ASSIGNING_EXECUTOR',
        'ANALYZING_DOCUMENTS_COMPLETENESS',
        'REQUERYING_DOCUMENTS_FROM_APPLICANT',
        'REQUESTING_FROM_DEPARTMENTS',
        'ANALYZING_DEPARTMENTS_DOCUMENTS']
        .includes(this.item.status)
      this.applicants = applicants.map(apl => {
        let property
        switch (apl.type) {
          case 'physical':
            property = 'physicalPerson'
            break
          case 'legal':
            property = 'legalPerson'
            break
          case 'individual':
            property = 'individualEntrepreneur'
            break
        }
        return {
          id: apl.id,
          claimedPropertyPercentage: apl.claimedPropertyPercentage,
          person: isEdit ? apl[property] : apl,
          type: apl.type
        }
      })
      this.isLoading = false
    }
  }
}
</script>
