export default {
  executors (state) {
    return state.executors.map(el => ({
      ...el,
      fullName: `${el.lastName ? `${el.lastName} ` : ''}${el.firstName ? `${el.firstName} ` : ''}${el.middleName || ''}`,
      activeStatementsCount: el.activeProcessInstances.reduce((acc, el) => {
        if (['LAND_PROVISION', 'LAND_REDISTRIBUTION'].includes(el.processName)) {
          acc = acc + el.count
        }
        return acc
      }, 0)
    }))
  },
  personTypesEnum (state) {
    return state.personTypes.map(pt => ({
      ...pt,
      enum: pt.code === 'P'
        ? 'physical'
        : pt.code === 'L'
          ? 'legal'
          : 'individual'
    }))
  },
  statusProcess (state) {
    return (processCode) => state.statementStatuses.filter(s => s.processCode === processCode)
  }
}
