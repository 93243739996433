<template>
  <rir-cards-block>
    <template slot="status" v-if="contentModal.status">
      <a class="d-flex align-center" @click="$attrs.closeModal">
        <rir-icon icon="arrow-left"/>
        Назад к поиску физического лица
      </a>
    </template>
    <template slot="title">
      <div class="nomira">{{ contentModal.title }}</div>
    </template>
    <template slot="default" v-if="!isLoadData">
      <div class="row row--no-grep">
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.lastName.$error"
            :message="mesErr($v.model.lastName)">
            <rir-input
              label="Фамилия"
              v-model="model.lastName"
            />
          </rir-form-item>
        </div>
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.gender.$error"
            :message="mesErr($v.model.gender)">
            <rir-select
              label="Пол"
              :items="genderTypes"
              id-value="value"
              text-value="text"
              v-model="model.gender"
            />
          </rir-form-item>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.firstName.$error"
            :message="mesErr($v.model.firstName)">
            <rir-input
              label="Имя"
              v-model="model.firstName"
            />
          </rir-form-item>
        </div>
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.birthDate.$error"
            :message="mesErr($v.model.birthDate)">
            <rir-date-picker
              label="Дата рождения"
              v-model="model.birthDate"
              v-mask="'##.##.####'"
            />
          </rir-form-item>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.middleName.$error"
            :message="mesErr($v.model.middleName)">
            <rir-input
              label="Отчество"
              v-model="model.middleName"
            />
          </rir-form-item>
        </div>
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.phone.$error"
            :message="mesErr($v.model.phone)">
            <rir-input
              label="Телефон"
              v-model="model.phone"
              v-mask="'+7 (###) ###-##-##'"
            />
          </rir-form-item>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 melia">Паспортные данные</div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.passport.series.$error"
            :message="mesErr($v.model.passport.series)">
            <rir-input
              label="Серия"
              v-model="model.passport.series"
              v-mask="'####'"
            />
          </rir-form-item>
        </div>
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.passport.number.$error"
            :message="mesErr($v.model.passport.number)">
            <rir-input
              label="Номер"
              v-model="model.passport.number"
              v-mask="'######'"
            />
          </rir-form-item>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12">
          <rir-form-item
            :error="$v.model.passport.issuerName.$error"
            :message="mesErr($v.model.passport.issuerName)">
            <rir-input
              label="Кем выдан"
              v-model="model.passport.issuerName"
            />
          </rir-form-item>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.passport.issueDate.$error"
            :message="mesErr($v.model.passport.issueDate)">
            <rir-date-picker
              click-close
              label="Дата выдачи"
              v-model="model.passport.issueDate"
              v-mask="'##.##.####'" />
          </rir-form-item>
        </div>
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.passport.issuerCode.$error"
            :message="mesErr($v.model.passport.issuerCode)">
            <rir-input
              label="Код подразделения"
              v-model="model.passport.issuerCode"
              v-mask="['######', '###-###']"
            />
          </rir-form-item>
        </div>
      </div>
    </template>
    <template slot="actions">
      <rir-button @click="saveData" :loading="isAction">{{ contentModal.action }}</rir-button>
    </template>
  </rir-cards-block>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, strLength, regEx } from '@/plugins/vuelidate/customValidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { mask } from 'vue-the-mask'
export default {
  name: 'form-physical',
  async created () {
    if (this.physicalId) {
      await this.getInfoPerson()
    }
  },
  mixins: [validateMixin, validationMixin],
  directives: { mask },
  data: () => ({
    isAction: false,
    isLoadData: false,
    model: {
      firstName: null,
      middleName: null,
      lastName: null,
      birthDate: null,
      gender: null,
      phone: null,
      passport: {
        series: null,
        number: null,
        issueDate: null,
        issuerName: null,
        issuerCode: null
      }
    }
  }),
  props: {
    physicalId: {
      type: String
    }
  },
  validations: {
    model: {
      firstName: {
        required: required('Обязательное поле')
      },
      middleName: {
        required: required('Обязательное поле')
      },
      lastName: {
        required: required('Обязательное поле')
      },
      birthDate: {
        required: required('Обязательное поле')
      },
      gender: {
        required: required('Обязательное поле')
      },
      phone: {
        required: required('Обязательное поле'),
        strLength: strLength(18, 'Требуется формат +7 (XXX) XXX-XX-XX')
      },
      passport: {
        series: {
          required: required('Обязательное поле'),
          regEx: regEx(/^\d+$/, 'Только цифры'),
          strLength: strLength(4, 'Требуется 4 символа')
        },
        number: {
          required: required('Обязательное поле'),
          regEx: regEx(/^\d+$/, 'Только цифры'),
          strLength: strLength(6, 'Требуется 6 символов')
        },
        issueDate: {
          required: required('Обязательное поле')
        },
        issuerName: {
          required: required('Обязательное поле')
        },
        issuerCode: {
          required: required('Обязательное поле'),
          regEx: regEx(/^[0-9]{3}-?[0-9]{3}$/, 'Формат ввода 000-000')
        }
      }
    }
  },
  computed: {
    ...mapState('directory', ['genderTypes']),
    contentModal () {
      return {
        status: !this.physicalId,
        title: `${this.physicalId ? 'Редактирование' : 'Создание'} физического лица`,
        action: this.physicalId ? 'Сохранить' : 'Создать'
      }
    }
  },
  methods: {
    ...mapActions('person', ['createPerson', 'updatePerson', 'getPerson']),
    async getInfoPerson () {
      this.isLoadData = true
      const model = await this.getPerson({ personType: 'physical', id: this.physicalId })
      this.model = { ...this.model, ...model }
      this.isLoadData = false
    },
    async saveData () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.isCreate = true
      let person = null
      if (this.model.id) {
        person = await this.updatePerson({ personType: 'physical', model: this.model })
      } else {
        person = await this.createPerson({ personType: 'physical', model: this.model })
      }
      this.isCreate = false
      this.$attrs.closeModal(person, true)
    }
  }
}
</script>
