import UploadRequest from '@/views/Department/components/UploadRequest'
import InfoStatus from '@/views/Department/components/InfoStatus'
import MatchingSolution from '@/views/Department/components/MatchingSolution'
import ChangeExecutor from '@/views/Department/components/ChangeExecutor'
import MatchingSolutionSubStatus from '@/views/Department/components/MatchingSolutionSubStatus'

export default function (process, rolePlatform) {
  const { status: { code } } = process
  const { headPersonUrbanDecision, headPersonSocialDecision, deputyDirectorDecision } = process
  switch (code) {
    case 'ASSIGNING_EXECUTOR':
      return rolePlatform === 'DAIG_HEAD_PERSON_URBAN' ? ChangeExecutor : InfoStatus
    case 'PREPARING_RESPONSE':
      return rolePlatform === 'DAIG_CHIEF_INSPECTOR' ? UploadRequest : InfoStatus
    case 'APPROVING_RESPONSE':
      switch (rolePlatform) {
        case 'DAIG_HEAD_PERSON_URBAN':
          return headPersonUrbanDecision ? InfoStatus : MatchingSolutionSubStatus
        case 'DAIG_DEPUTY_DIRECTOR':
          return deputyDirectorDecision ? InfoStatus : MatchingSolutionSubStatus
        case 'DAIG_HEAD_PERSON_SOCIAL':
          return headPersonSocialDecision ? InfoStatus : MatchingSolutionSubStatus
      }
      return InfoStatus
    case 'SIGNING_BY_DEPARTMENT_DIRECTOR':
      return rolePlatform === 'DAIG_DEPARTMENT_DIRECTOR' ? MatchingSolution : InfoStatus
  }
  return InfoStatus
}
