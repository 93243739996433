import apps from './apps'
import directory from './directory'
import session from './session'
import person from './person'
import stateRds from './stateRds'
import statePrv from './statePrv'
import statementPerson from './statementPerson'

export default {
  apps,
  directory,
  session,
  person,
  stateRds,
  statePrv,
  statementPerson
}
