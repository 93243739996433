<template>
  <rir-cards-block>
    <div
      class="nomira px-8"
      slot="status">Выбор физического лица</div>
    <template slot="title">
      <div class="row">
        <div class="col-md-12-12">
          <rir-tabs
            id-value="enum"
            text-value="name"
            :items="personTypesEnum"
            v-model="personType"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5-12 mb-0">
          <rir-input
            label="Поиск"
            v-model="querySearch"
          >
            <rir-icon slot="before" icon="search" />
          </rir-input>
        </div>
      </div>
    </template>
    <template slot="default">
      <div class="row">
        <div class="col-xs-12-12">
          <rir-table
            :headers="columns"
            :loading="isLoad"
            :items="persons"
            select-event
            @selectRow="selectRow"
          />
        </div>
      </div>
    </template>
  </rir-cards-block>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import moment from 'moment'
export default {
  name: 'select-person',
  data: () => ({
    isLoad: false,
    querySearch: null,
    personType: 'physical',
    persons: []
  }),
  watch: {
    querySearch: function () {
      this.loadData()
    }
  },
  computed: {
    ...mapState('statementPerson', ['personsList']),
    ...mapGetters('directory', ['personTypesEnum']),
    columns () {
      this.loadData()
      switch (this.personType) {
        case 'physical':
          return [
            {
              title: 'ФИО',
              column: 'fullName'
            },
            {
              title: 'Паспорт',
              column: 'passportData'
            },
            {
              title: 'Дата выдачи',
              column: 'passportIssueDate'
            },
            {
              title: 'Дата рождения',
              column: 'birthDateFormat'
            }
          ]
        case 'legal':
          return [
            {
              title: 'Название',
              column: 'fullName'
            },
            {
              title: 'ИНН организации',
              column: 'inn'
            }
          ]
        case 'individual':
          return [
            {
              title: 'ФИО ИП',
              column: 'fullName'
            },
            {
              title: 'Свидетельства о регистрации',
              column: 'registrationCertificateNumber'
            },
            {
              title: 'Паспорт',
              column: 'passportIssueDate'
            },
            {
              title: 'Дата выдачи',
              column: 'issueDate'
            }
          ]
      }
      return []
    }
  },
  methods: {
    ...mapActions('person', ['listPerson']),
    async loadData () {
      this.isLoad = true
      const persons = await this.listPerson({
        personType: this.personType,
        query: {
          query: this.querySearch,
          excludedIds: this.personsList.map(el => el.person.id).join(',')
        }
      })
      this.persons = persons.map(p => {
        const person = p.physicalPerson || p.headPerson || p
        switch (this.personType) {
          case 'physical':
            return {
              ...p,
              fullName: `${person.lastName} ${person.firstName} ${person.middleName || ''}`,
              passportData: `${person.passport.series} ${person.passport.number}`,
              passportIssueDate: moment(p.passport.issueDate).format('DD.MM.YYYY'),
              birthDateFormat: moment(p.birthDate).format('DD.MM.YYYY')
            }
          case 'legal':
            return {
              ...p
            }
          case 'individual':
            return {
              ...p,
              fullName: `${person.lastName} ${person.firstName} ${person.middleName || ''}`,
              passportData: `${person.passport.series} ${person.passport.number}`,
              passportIssueDate: moment(person.passport.issueDate).format('DD.MM.YYYY')
            }
        }
      })
      this.isLoad = false
    },
    selectRow (item) {
      this.$attrs.closeModal({
        type: this.personType,
        person: item
      })
    }
  }
}
</script>
