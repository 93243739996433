<template>
  <section class="request-list">
    <filters-list
      v-model="filters"
      type-process="LAND_REDISTRIBUTION"
      @loadData="loadData"
    />
    <div class="row">
      <div class="col-md-12-12">
        <rir-table
          :loading="isLoading"
          ref="table"
          @selectRow="selectRow"
          select-event
          :headers="headers"
          :items="statements"
          :sort-list.sync="sorting"
          single-sort
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import DateTable from '@/components/table/DateTable'
import ReceptionTypeTable from '@/components/table/ReceptionTypeTable'
import DeadlineTable from '@//components/table/DeadlineTable'
import StatusTable from '@/components/table/StatusTable'
import GoToExecutor from '@/views/landServiceRds/modal/GoToExecutor'
import FiltersList from '@/components/forms/FiltersList'
import ApplicantTable from '@/components/table/ApplicantTable'

export default {
  name: 'land-service-list',
  components: { FiltersList },
  created () {
    this.filters.period =
      JSON.parse(sessionStorage.getItem('filter-period')) || []
    if (!this.filters.period.length) {
      const tz = new Date().getTimezoneOffset()
      const timeZone = Math.abs(tz)
      const hours = Math.floor(timeZone / 60)
      const minutes = timeZone - hours * 60
      const tzLocal = `Z${tz <= 0 ? '+' : '-'}${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`

      const current = new Date()
      const prior = new Date().setDate(current.getDate() - 30)
      this.filters.period = [
        `${new Date(prior).toLocaleDateString('en-CA')}${tzLocal}`,
        `${current.toLocaleDateString('en-CA')}${tzLocal}`
      ]
    }
  },
  mounted () {
    this.updateTitleApp({ name: 'titlePage', value: 'Список заявлений' })
    this.loadData()
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.isLoadScroll) {
        this.isLoadScroll = true
        if (this.statements.length > this.filters.pageIndex * this.filters.size) {
          this.filters.pageIndex++
          this.loadData()
        } else {
          this.isLoadScroll = false
        }
      }
    }
  },
  beforeDestroy () {
    window.onscroll = () => {}
    this.setProperty({ name: 'statements', value: [] })
  },
  data: () => ({
    isLoading: false,
    isLoadScroll: false,
    filters: {
      period: null,
      executorId: null,
      cadastralNumber: null,
      applicant: null,
      statusCodes: null,
      pageIndex: 0,
      size: 20
    },
    sorting: [
      {
        column: 'receiptDate',
        direction: 'DESC'
      }
    ],
    headers: [
      {
        title: '№',
        column: 'number',
        sort: true,
        width: '36px'
      },
      {
        title: 'Поступило',
        column: 'receiptDate',
        component: DateTable,
        sort: true,
        width: '70px'
      },
      {
        title: 'Номер СЭД',
        column: 'sedNumber',
        sort: true
      },
      {
        title: 'Принято',
        column: 'receptionMethod',
        component: ReceptionTypeTable,
        sort: true
      },
      {
        title: 'Заявитель',
        column: 'applicant',
        component: ApplicantTable
      },
      {
        title: 'Статус',
        column: 'status',
        component: StatusTable,
        sort: true
      },
      {
        title: 'Дедлайн',
        column: 'deadline',
        component: DeadlineTable,
        width: '70px'
      }
    ]
  }),
  props: {
    statusCodes: {
      type: String
    }
  },
  watch: {
    sorting: function () {
      this.filters.pageIndex = 0
      this.loadData()
    }
    // statusCodes: function () {
    //   this.loadData()
    // }
  },
  computed: {
    ...mapState('stateRds', ['statements'])
  },
  methods: {
    ...mapMutations('apps', {
      updateTitleApp: 'setProperty'
    }),
    ...mapMutations('stateRds', ['setProperty']),
    ...mapActions('stateRds', ['statementList', 'onlyStatementItem']),
    async loadData () {
      this.isLoading = !this.isLoadScroll
      const { period, ...filter } = this.filters
      const filters = {
        from: period[0],
        to: period[1],
        sorting: [],
        ...filter
      }
      if (this.sorting.length) {
        filters.sorting = this.sorting.map(s => `${s.column}:${s.direction}`).join(',')
      }
      await this.statementList({ filters })
      this.isLoading = false
      this.isLoadScroll = false
    },
    async selectRow (item) {
      this.$refs.table.clearSelectRow()
      if (item.status === 'ASSIGNING_EXECUTOR') {
        await this.onlyStatementItem(item)
        await this.$root.$modal(GoToExecutor)
      } else {
        await this.$router.push({ name: 'land-rds-item', params: { requestId: item.id } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
