<template>
  <section>
    <div
      class="d-flex flex-column"
      v-for="(person, index) in personsList"
      :key="index">
      <view-person-info
        :person="person"
        :index="index"
        :readonly="readonly"
      />
    </div>
    <rir-button
      v-if="!readonly"
      color="secondary"
      @click="openSelectPerson">Добавить заявителя</rir-button>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import SelectPerson from '@/components/statmentPerson/modal/SelectPerson'
import ViewPersonInfo from '@/components/statmentPerson/ViewPersonInfo'

export default {
  name: 'statement-person-block',
  components: { ViewPersonInfo },
  computed: {
    ...mapState('statementPerson', ['personsList'])
  },
  props: {
    readonly: {
      type: Boolean
    }
  },
  methods: {
    ...mapMutations('statementPerson', ['addPerson']),
    async openSelectPerson () {
      const person = await this.$root.$modal(SelectPerson)
      !!person && this.addPerson(person)
    }
  }
}
</script>

<style scoped>

</style>
