<template>
  <section
    class="mb-5 d-flex flex-column"
    v-if="isComments">
    <span class="melia mb-2">Комментарии возврата</span>
    <span v-html="comments"></span>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import getEnv from '@/plugins/env'

export default {
  name: 'return-comments',
  mounted () {
    this.isComments && this.loadComments()
  },
  data: () => ({
    comments: ''
  }),
  props: {
    process: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('session', ['departmentCode']),
    isComments () {
      const status = this.process.status
      const previousStatus = this.process.previousStatus
      switch (this.departmentCode) {
        case 'dzp':
          return status.code === 'PREPARING_RESPONSE' && !!previousStatus
        case 'daig':
        case 'ld':
          return status.code === 'PREPARING_RESPONSE' && previousStatus.code !== 'ASSIGNING_EXECUTOR'
      }
      return false
    }
  },
  methods: {
    async loadComments () {
      if (this.departmentCode === 'daig' && this.process.previousStatus.code === 'APPROVING_RESPONSE') {
        if (this.process.deputyDirectorResponse) {
          this.comments += `<span class="clymenti">Зам. директора ДАиГ</span> - ${this.process.deputyDirectorResponse}</br>`
        }
        if (this.process.headPersonSocialResponse) {
          this.comments += `<span class="clymenti">Нач. отд. ОПиСВ</span> - ${this.process.headPersonSocialResponse}</br>`
        }
        if (this.process.headPersonUrbanResponse) {
          this.comments += `<span class="clymenti">Начальник отдела</span> - ${this.process.headPersonUrbanResponse}</br>`
        }
      } else {
        const { data } =
          await this.$axios
            .get(`${getEnv('VUE_APP_API_SERVER')}common/process-status-changing`, {
              params: {
                id: this.process.id
              }
            })
        this.comments = data[0].note
      }
    }
  }
}
</script>
