export default {
  mounted () {
    const content = this.$refs.content
    this.isTooltip = content.clientWidth < content.scrollWidth
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  data: () => ({
    isTooltip: true
  }),
  methods: {
    handleResize () {
      const content = this.$refs.content
      this.isTooltip = content.clientWidth < content.scrollWidth
    }
  }
}
