<template>
  <rir-accordion v-if="isView">
    <template slot="activator">
      <div class="d-flex flex-column">
        <div class="mb-1 titanic--text melia">
          Завершение работ по отказу над заявлением
        </div>
        <div class="d-flex align-center">
          <rir-icon
            class="mr-2"
            :icon="isStatus.icon"
            :fill="isStatus.fillIcon"
          />
          <span class="anie terminator--text">{{ isStatus.text }}</span>
        </div>
      </div>
    </template>
    <template v-if="statusStep === 1">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="matrix"
            class="mr-2"
            icon="selected"
          />
          <span>Ожидается подпись начальника</span>
        </div>
        <span class="pl-6 titanic--text anie">Ожидаем подпись</span>
      </div>
      <rir-file-list
        class="mt-2"
        :count-line="1"
        :files="fileCancel"
      />
      <div v-if="!isSpecialist" class="d-flex flex-column mt-5">
        <rir-drag-upload-file
          class="mb-5"
          :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt']"
          :count-file="1"
          :upload-url="$root.$uploadServer"
          :params-body="{
              code: 'LAND',
              type: '17-05'
            }"
          v-model="attachment" />
        <rir-button
          @click="changeStatusFinish('CANCEL')">
          Подписать</rir-button>
      </div>
    </template>
    <template v-if="statusStep === 2">
      <div class="d-flex flex-column">
        <div class="mb-5">
          <rir-icon
            class="mr-2"
            icon="selected"
          />
          <span>Подписано начальником</span>
        </div>
        <a v-if="fileApproveCancel.length" :href="fileApproveCancel[0].url" download target="_blank">
          <rir-button
            class="memento godfather--text"
          >Скачать Документ</rir-button>
        </a>
        <rir-button
          @click="changeStatusFinish"
          class="mt-5"
          v-if="isSpecialist">Завершить работы по заявлению</rir-button>
      </div>
    </template>
    <template v-if="statusStep === 3">
      <div class="d-flex flex-column">
        <div class="mb-5">
          <rir-icon
            class="mr-2"
            icon="selected"
          />
          <span>Подписано начальником</span>
        </div>
        <a v-if="fileApproveCancel.length" :href="fileApproveCancel[0].url" download target="_blank">
          <rir-button
            class="memento godfather--text"
          >Скачать Документ</rir-button>
        </a>
      </div>
    </template>
  </rir-accordion>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'finish-step-reject',
  data: () => ({
    attachment: []
  }),
  computed: {
    ...mapGetters('session', ['rolePlatform']),
    ...mapState('statePrv', ['statement']),
    computedModel () {
      return Object.assign({}, this.statement)
    },
    isView () {
      return [
        'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_LD',
        'COMPLETING_REJECTION_AFTER_LD',
        'REJECTED_BY_LEGAL_DEPARTMENT'
      ].includes(this.statement.statement.status)
    },
    isStatus () {
      switch (this.statement.statement.status) {
        case 'REJECTED_BY_LEGAL_DEPARTMENT':
          return {
            icon: 'error',
            fillIcon: 'fargo',
            text: 'Отказано'
          }
        default:
          return {
            icon: 'in-progress',
            fillIcon: 'rocky',
            text: 'В процессе'
          }
      }
    },
    statusStep () {
      /*
      * 1 - Ожидание подписи от начальника
      * 2 - Подтверждение сдачи документов
      * 3 - окончание работ
      * */
      switch (this.statement.statement.status) {
        case 'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_LD':
          return 1
        case 'COMPLETING_REJECTION_AFTER_LD':
          return 2
        default:
          return 3
      }
    },
    isSpecialist () {
      return this.rolePlatform === 'SPECIALIST'
    },
    fileCancel () {
      return this.statement.statement.attachments.filter(el => el.type === '17-04')
    },
    fileApproveCancel () {
      return this.statement.statement.attachments.filter(el => el.type === '17-05')
    }
  },
  methods: {
    ...mapActions('statePrv', ['changeStatus', 'updateStatement', 'addAttachStatement']),
    async changeStatusFinish () {
      !!this.attachment.length && await this.addAttachStatement(this.attachment[0])
      await this.changeStatus({ decision: 'APPROVE' })
    }
  }
}
</script>
