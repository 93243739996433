<template>
  <rir-accordion>
    <template slot="activator">
      <div class="d-flex flex-column">
        <div class="row row--no-grep">
          <div class="col-md-8-12 mb-2 pl-0 titanic--text melia">Формирование запросов по заявлению</div>
          <div class="col-md-2-12 mb-2 kleodora d-flex align-center anie terminator--text">Отправлено</div>
          <div class="col-md-2-12 mb-2 pr-0 kleodora d-flex align-center anie terminator--text">Получен ответ</div>
        </div>
        <div class="row row--no-grep">
          <div class="col-md-8-12 pl-0 mb-0 d-flex align-center">
            <rir-icon
              class="mr-2"
              :icon="isStatus.icon"
              :fill="isStatus.fillIcon"
            />
            <span class="anie terminator--text">{{ isStatus.text }}</span>
          </div>
          <div class="col-md-2-12 mb-0 melia d-flex align-center titanic--text">
            {{ countSend }} / 2
          </div>
          <div class="col-md-2-12 pr-0 mb-0 melia d-flex align-center titanic--text">
            {{ countGet }} / 2
          </div>
        </div>
      </div>
    </template>
    <div class="row row--no-grep">
      <div class="col-md-6-12 pl-0 mb-0">
        <rir-cards-block class="amelie department-card">
          <span class="titanic--text clymenti" slot="title">
            Департамент архитектуры и градостроительства
          </span>
          <template>
            <department-daig
              @changeStatus="changeStatus"
              :status="statement.requestDaigStatus" />
          </template>
        </rir-cards-block>
      </div>
      <div class="col-md-6-12 pr-0 mb-0">
        <rir-cards-block class="amelie department-card">
          <span class="titanic--text clymenti" slot="title">
            Росреестр
          </span>
          <template>
            <department-rosreestor
              @changeStatus="changeStatus"
              :status="statement.requestRosreestrStatus"/>
          </template>
        </rir-cards-block>
      </div>
    </div>
  </rir-accordion>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DepartmentDaig from '@/views/landServicePrv/components/statuses/DepartmentDaig'
import DepartmentRosreestor from '@/views/landServicePrv/components/statuses/DepartmentRosreestor'
export default {
  name: 'departments-status',
  components: { DepartmentRosreestor, DepartmentDaig },
  computed: {
    ...mapState('statePrv', ['statement']),
    countSend () {
      /*
        requestDaigStatus: "STARTED"
        requestDzpStatus: "STARTED"
        requestRosreestrStatus: "STARTED"
      * */
      const mapStatus = ['requestDaigStatus', 'requestRosreestrStatus']
      return mapStatus.reduce((count, el) => {
        if (this.statement[el] !== 'STARTED') count++
        return count
      }, 0)
    },
    countGet () {
      /*
        requestDaigStatus: "STARTED"
        requestDzpStatus: "STARTED"
        requestRosreestrStatus: "STARTED"
      * */
      const mapStatus = ['requestDaigStatus', 'requestRosreestrStatus']
      return mapStatus.reduce((count, el) => {
        if (this.statement[el] === 'FINISHED') count++
        return count
      }, 0)
    },
    isStatus () {
      switch (this.statement.statement.status) {
        case 'ANALYZING_DEPARTMENTS_DOCUMENTS':
        case 'APPROVING_BY_HEAD_PERSON':
        case 'PREPARING_LEGAL_DEPARTMENT_REQUEST':
        case 'APPROVING_LEGAL_DEPARTMENT':
        case 'ANALYZING_LEGAL_DEPARTMENT_RESPONSE':
        case 'COMPLETING_HANDLING_STATEMENT':
        case 'REJECTED_BY_LEGAL_DEPARTMENT':
        case 'ISSUED_AGREEMENT':
        case 'PREPARING_REJECTION_PROJECT':
        case 'REJECTED_BY_HEAD_PERSON':
        case 'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_DEPARTMENTS':
        case 'APPROVING_REJECTION_BY_LD_AFTER_DEPARTMENTS':
        case 'COMPLETING_REJECTION_AFTER_DEPARTMENTS':
        case 'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_LD':
        case 'COMPLETING_REJECTION_AFTER_LD':
        case 'REJECTED':
          return {
            icon: 'selected',
            fillIcon: 'matrix',
            text: 'Завершено'
          }
        default:
          return {
            icon: 'in-progress',
            fillIcon: 'rocky',
            text: 'В процессе'
          }
      }
    }
  },
  methods: {
    ...mapActions('statePrv', ['changeStatusDepartment']),
    async changeStatus (type) {
      await this.changeStatusDepartment({ type })
      this.$root.$notification({
        type: 'success',
        text: 'Запрос отправлен!'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.department-card{
  border-radius: 16px;
  padding: 20px
}
</style>
