<template>
  <div class="d-flex flex-column">
    <template v-if="statusStep === 1">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="fargo"
            class="mr-2"
            icon="close"
          />
          <span>Отказано в заявление</span>
        </div>
        <span class="pl-6 titanic--text anie">Требуется утверждение</span>
        <a v-if="fileCancel.length" :href="fileCancel[0].url" class="mt-5 mb-5" download target="_blank">
          <rir-button
            class="memento godfather--text"
          >
            Скачать документ
          </rir-button>
        </a>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0">
          <rir-button
            :disabled="!!comment"
            :loading="isSend"
            @click="sendAgreement('APPROVE')"
          >Утвердить отказ</rir-button>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0 kleodora anie terminator--text">
          Если документ требует доработки, то опишите правки в комментарии
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0">
          <rir-textarea
            class="mb-6"
            v-model="comment"
            label="Текст комментария"
            :max-rows="5"
          />
<!--          <rir-drag-upload-file-->
<!--            class="mb-4"-->
<!--            :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt']"-->
<!--            :count-file="1"-->
<!--            :upload-url="$root.$uploadServer"-->
<!--            :params-body="{-->
<!--              code: 'LAND',-->
<!--              type: '17-02'-->
<!--            }"-->
<!--            v-model="attachmentCancel" />-->
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 px-0">
          <rir-button
            :disabled="!comment"
            color="error"
            :loading="isSend"
            @click="sendAgreement('RETURN')"
          >Вернуть специалисту</rir-button>
        </div>
      </div>
    </template>
    <template v-if="statusStep === 2">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            class="mr-2"
            icon="info"
          />
          <span>Утверждение директором и правовым департаментом</span>
        </div>
        <span class="pl-6 titanic--text anie">Ожидается утверждение</span>
        <a v-if="fileCancel.length" :href="fileCancel[0].url" class="mt-5" download target="_blank">
          <rir-button
            class="memento godfather--text"
          >
            Скачать документ
          </rir-button>
        </a>
      </div>
    </template>
    <template v-if="statusStep === 3">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="fargo"
            class="mr-2"
            icon="answer"
          />
          <span>Возвращено на доработку</span>
        </div>
        <span class="pl-6 titanic--text anie">Ожидаются исправления</span>
      </div>
    </template>
    <template v-if="statusStep === 4">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="fargo"
            class="mr-2"
            icon="close"
          />
          <span>Анализ ответа от правового департамента</span>
        </div>
        <span class="pl-6 titanic--text anie">Решение</span>
        <a v-if="filePDAnswer.length" :href="filePDAnswer[0].url" class="mt-5" download target="_blank">
          <rir-button
            class="memento godfather--text"
          >Скачать Документ</rir-button>
        </a>
      </div>
    </template>
    <template v-if="statusStep === 5">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="fargo"
            class="mr-2"
            icon="close"
          />
          <span>Отказано в заявление</span>
        </div>
        <span class="pl-6 titanic--text anie">Решение отказа</span>
        <a v-if="fileCancel.length" :href="fileCancel[0].url" class="mt-5" download target="_blank">
          <rir-button
            class="memento godfather--text"
          >Скачать Документ</rir-button>
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'form-chief-reject',
  data: () => ({
    isSend: false,
    comment: null,
    attachmentCancel: []
  }),
  computed: {
    ...mapGetters(['GET_BLOB']),
    ...mapState('statePrv', ['statement']),
    statusStep () {
      /*
      * APPROVING_BY_HEAD_PERSON
      * ANALYZING_DEPARTMENTS_DOCUMENTS
      * APPROVING_LEGAL_DEPARTMENT
      * ANALYZING_LEGAL_DEPARTMENT_REJECT_RESPONSE
      *
      * 1 - Утверждение или возврат специалисту
      * 2 - Ожидание утверждение ПД
      * 3 - Ожидание правок по отказу от специалиста
      * 4 - Ожидание проверка ответа от ПД специалистом
      * */
      let step
      const statement = this.statement.statement
      switch (statement.status) {
        case 'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_DEPARTMENTS':
          step = 1
          break
        case 'APPROVING_REJECTION_BY_LD_AFTER_DEPARTMENTS':
        case 'APPROVING_REJECTION_BY_DEPARTMENT_DIRECTOR':
          step = 2
          break
        case 'CORRECTING_REJECTION':
          step = 3
          break
        case 'ANALYZING_LEGAL_DEPARTMENT_REJECT_RESPONSE':
          step = 4
          break
        case 'REJECTED':
          step = 5
          break
      }
      return step
    },
    fileCancel () {
      return this.statement.statement.attachments.filter(el => el.type === '17-01')
    },
    filePDAnswer () {
      return this.statement.statement.attachments.filter(el => el.type === '15-02')
    }
  },
  methods: {
    ...mapActions('statePrv', ['changeStatus', 'addAttachStatement']),
    async sendAgreement (type) {
      this.isSend = true
      // !!this.attachmentCancel.length && await this.addAttachStatement(this.attachmentCancel[0])
      await this.changeStatus({ decision: type, note: this.comment })
      this.isSend = false
    }
  }
}
</script>
