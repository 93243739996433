<template>
  <rir-accordion >
    <template slot="activator">
      <div class="d-flex flex-column">
        <div class="titanic--text melia">
          Утверждение у курирующего вице-мэра
        </div>
        <div class="d-flex align-center mt-1">
          <rir-icon
            class="mr-2"
            :icon="isStatus.icon"
            :fill="isStatus.fillIcon"
          />
          <span class="anie terminator--text">{{ isStatus.text }}</span>
        </div>
      </div>
    </template>
    <section class="d-flex flex-column">
      <div class="d-flex align-center">
        <rir-icon
          :icon="finish ? 'selected' : 'waiting'"
          class="mr-2"
        />
        <span>{{ finish ? 'Утверждено' : 'Ожидаем ответ' }}</span>
      </div>
    </section>
  </rir-accordion>
</template>

<script>

export default {
  name: 'approving-agreement-by-vice-mayor',
  props: {
    finish: {
      type: Boolean
    }
  },
  computed: {
    isStatus () {
      return {
        icon: this.finish ? 'selected' : 'in-progress',
        fillIcon: this.finish ? 'matrix' : 'rocky',
        text: this.finish ? 'Завершено' : 'В процессе'
      }
    }
  }
}
</script>
