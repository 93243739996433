<template>
  <rir-cards-block>
    <template slot="status">
      <a @click="$attrs.closeModal" class="go-to-executor__back px-8">
        <rir-icon icon="arrow-left" />
        Назад к списку заявлений
      </a>
    </template>
    <span slot="title" class="nomira px-8">Заявление № {{ statement.number }}</span>
    <template slot="default">
      <view-statement
        :statement="statement" />
    </template>
    <template slot="actions">
      <div class="row">
        <div class="col-md-12-12 d-flex flex-column">
          <span class="melia titanic--text mb-4">Назначить ответственного</span>
          <rir-select
            :items="executors"
            id-value="id"
            text-value="fullName"
            label="Ответственный"
            v-model="executorId"
          >
            <span slot="item" slot-scope="{ item }">
              {{ item.fullName }}
              <span class="rocky--text">{{ item.activeStatementsCount }} {{ item.activeStatementsCount | declOfNum(['заявка в работе', 'заявки в работе', 'заявок в работе']) }}</span>
            </span>
          </rir-select>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 mb-0">
          <rir-button
            @click="initExecutor"
            :loading="isLoading"
          >
            Назначить
          </rir-button>
        </div>
      </div>
    </template>
  </rir-cards-block>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import ViewStatement from '@/views/landServiceRds/components/layout/ViewStatement'
export default {
  name: 'go-to-executor',
  beforeDestroy () {
    this.setProperty({
      name: 'statement',
      value: null
    })
  },
  components: {
    ViewStatement
  },
  data: () => ({
    isLoading: false,
    executorId: null
  }),
  computed: {
    ...mapGetters('directory', ['executors']),
    ...mapState('stateRds', ['statement'])
  },
  methods: {
    ...mapActions('stateRds', ['setExecutor']),
    ...mapMutations('stateRds', ['setProperty']),
    initExecutor () {
      this.isLoading = !this.isLoading
      this.setExecutor({
        landExecutorId: this.executorId,
        statementId: this.statement.id
      })
        .then(() => {
          this.$attrs.closeModal()
          this.$root.$notification({
            type: 'success',
            text: 'Ответственный назначен'
          })
        })
        .finally(() => {
          this.isLoading = !this.isLoading
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.go-to-executor{
  &__back{
    display: flex;
    align-items: center;
  }
}
</style>
