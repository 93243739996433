<template>
  <rir-table
    :items="itemsTable"
    :headers="column"
    @selectRow="selectRow"
    select-event
  />
</template>

<script>
import listData from '@/views/persons/mixins/listData'

export default {
  name: 'legal-list',
  mixins: [listData],
  data: () => ({
    column: [
      {
        title: 'Наименование организации',
        column: 'fullName'
      },
      {
        title: 'ИНН организации',
        column: 'inn'
      },
      {
        title: 'ФИО руководителя',
        column: 'headName'
      }
    ]
  }),
  computed: {
    itemsTable () {
      return this.persons.map(el => ({
        id: el.id,
        fullName: el.fullName,
        inn: el.inn,
        headName: `${el.headPersonLastName || ''} ${el.headPersonFirstName || ''} ${el.headPersonMiddleName || ''}`
      }))
    }
  }
}
</script>
