<template>
  <rir-accordion >
    <template slot="activator">
      <div class="d-flex flex-column">
        <div class="titanic--text melia">
          Утверждение у начальника отдела
        </div>
        <div class="d-flex align-center mt-1">
          <rir-icon
            class="mr-2"
            :icon="isStatus.icon"
            :fill="isStatus.fillIcon"
          />
          <span class="anie terminator--text">{{ isStatus.text }}</span>
        </div>
      </div>
    </template>
    <section class="d-flex flex-column">
      <div class="d-flex align-center">
        <rir-icon
          :icon="finish ? 'selected' : 'waiting'"
          class="mr-2"
          />
        <span>{{ finish ? 'Утверждено' : 'Ожидаем ответа' }}</span>
      </div>
      <confirm-document
        class="mt-8"
        v-if="!finish && isHeadPerson"
      />
    </section>
  </rir-accordion>
</template>

<script>
import ConfirmDocument from '@/views/landServiceRds/components/ConfirmDocument'
import { mapGetters } from 'vuex'
export default {
  name: 'approving-by-head-person',
  components: { ConfirmDocument },
  data: () => ({}),
  props: {
    finish: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('session', ['isHeadPerson']),
    isStatus () {
      return {
        icon: this.finish ? 'selected' : 'in-progress',
        fillIcon: this.finish ? 'matrix' : 'rocky',
        text: this.finish ? 'Завершено' : 'В процессе'
      }
    }
  }
}
</script>
