<template>
  <rir-table
    :headers="column"
    :items="itemsTable"
    @selectRow="selectRow"
    select-event
  />
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import listData from '@/views/persons/mixins/listData'
export default {
  name: 'physical-list',
  mixins: [listData],
  data: () => ({
    column: [
      {
        title: 'ФИО',
        column: 'fullName'
      },
      {
        title: 'Паспорт',
        column: 'passport'
      },
      {
        title: 'Дата выдачи',
        column: 'issueDate'
      },
      {
        title: 'Дата рождения',
        column: 'birthDate'
      },
      {
        title: 'Пол',
        column: 'gender'
      }
    ]
  }),
  computed: {
    ...mapState('directory', ['genderTypes']),
    itemsTable () {
      return this.persons.map(el => ({
        id: el.id,
        fullName: `${el.lastName} ${el.firstName} ${el.middleName || ''}`,
        passport: `${el.passport.series} ${el.passport.number}`,
        issueDate: moment(el.passport.issueDate).format('DD.MM.YYYY'),
        birthDate: moment(el.birthDate).format('DD.MM.YYYY'),
        gender: this.genderTypes.find(g => g.value === el.gender).text
      }))
    }
  }
}
</script>
