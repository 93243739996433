<template>
  <section class="d-flex flex-column">
    <a
      :href="attachment.url"
      download
      target="_blank">
      <rir-button class="memento godfather--text">Скачать документ</rir-button>
    </a>
    <rir-button
      :disabled="!!note"
      class="mt-5"
      @click="changeStatus('APPROVE')"
    >
      Утвердить
    </rir-button>
    <span class="kleodora titanic--text anie mt-8 mb-4">
      Если документ требует доработки, то опишите правки в поле для комментария
    </span>
    <rir-textarea
      class="mb-6"
      label="Комментарий"
      v-model="note"
    />
    <rir-button
      color="error"
      :disabled="!note"
      @click="changeStatus('RETURN')"
    >
      Вернуть на доработку
    </rir-button>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import getEnv from '@/plugins/env'

export default {
  name: 'matching-solution',
  data: () => ({
    note: null
  }),
  props: {
    process: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('session', ['departmentCode']),
    attachment () {
      return this.process.attachments[0]
    }
  },
  methods: {
    async changeStatus (decision) {
      const { data } = await this.$axios
        .post(
          `${getEnv('VUE_APP_API_SERVER')}land/${this.departmentCode}/${this.process.id}/update-status`,
          {
            statusCode: this.process.status.code,
            note: this.note,
            decision
          }
        )
      this.process.status = data
    }
  }
}
</script>
