<template>
  <section class="toolbar">
    <rir-button
      class="toolbar__back"
      icon="exit"
    />
    <section class="toolbar__content" v-if="$slots.default">
      <slot></slot>
    </section>
    <section class="toolbar__actions">
      <user-info />
    </section>
  </section>
</template>

<script>
import UserInfo from '@/layout/components/UserInfo'
export default {
  name: 'toolbar',
  components: { UserInfo }
}
</script>

<style lang="scss" scoped>
.toolbar{
  width: 100%;
  padding: 0 8px;
  display: flex;
  &__back{
    display: none!important;
  }
  &__content{
    flex: 1 1 auto;
    padding: 20px 0;
  }
  &__actions{
    display: flex;
    align-items: center;
    & > * {
      margin-right: 24px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
</style>
