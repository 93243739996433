<template>
  <section class="create-person">
    <div class="row">
      <div class="col-md-12-12">
        <div class="crephusa">Информация о заявителе</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12">
        <rir-radio-group
          is-column
          v-model="personType"
        >
          <rir-radio
            v-for="type in personTypesEnum"
            :key="type.code"
            :label="type.name"
            :value="type.code"
          />
        </rir-radio-group>
      </div>
    </div>
    <component
      :is="typeForm"
      v-model="model"
      :v="$v.model"
    />
    <div class="row">
      <div class="col-md-12-12">
        <rir-button
          :loading="isCreate"
          @click="saveData">
          Создать заявителя
        </rir-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, strLength, regEx } from '@/plugins/vuelidate/customValidate'
import IeForm from '@/views/persons/layout/IeForm'
import LForm from '@/views/persons/layout/LForm'
import PForm from '@/views/persons/layout/PForm'

export default {
  name: 'person-create',
  components: { IeForm, LForm, PForm },
  mounted () {
    this.setProperty({ name: 'titlePage', value: 'Новый заявитель' })
    if (this.person) {
      this.personType = this.person.personType
      this.$nextTick(() => {
        this.model = this.person
      })
    }
  },
  mixins: [validationMixin],
  data: () => ({
    isCreate: false,
    personType: 'P',
    model: null
  }),
  validations () {
    let valid = {}
    switch (this.personType) {
      case 'P':
        valid = {
          model: {
            firstName: {
              required: required('Обязательное поле')
            },
            lastName: {
              required: required('Обязательное поле')
            },
            birthDate: {
              required: required('Обязательное поле')
            },
            gender: {
              required: required('Обязательное поле')
            },
            phone: {
              strLength: strLength(18, 'Требуется формат +7 (XXX) XXX-XX-XX')
            },
            passport: {
              series: {
                required: required('Обязательное поле'),
                regEx: regEx(/^\d+$/, 'Только цифры'),
                strLength: strLength(4, 'Требуется 4 символа')
              },
              number: {
                required: required('Обязательное поле'),
                regEx: regEx(/^\d+$/, 'Только цифры'),
                strLength: strLength(6, 'Требуется 6 символов')
              },
              issueDate: {
                required: required('Обязательное поле')
              },
              issuerName: {
                required: required('Обязательное поле')
              },
              issuerCode: {
                required: required('Обязательное поле'),
                regEx: regEx(/^[0-9]{3}[- ]?[0-9]{3}$/, 'Форматы 000-000, 000000, 000 000')
              }
            }
          }
        }
        break
      case 'L':
        valid = {
          model: {
            inn: {
              required: required('Обязательное поле'),
              regEx: regEx(/^\d+$/, 'Только цифры'),
              strLength: strLength(10, 'Требуется 10 символов')
            },
            fullName: {
              required: required('Обязательное поле')
            },
            shortName: {
              required: required('Обязательное поле')
            },
            corporateFormCode: {
              required: required('Обязательное поле')
            },
            legalAddress: {
              required: required('Обязательное поле')
            }
          }
        }
        break
      case 'IE':
        valid = {
          model: {
            registrationCertificateNumber: {
              strLength: strLength(15, 'Требуется 15 символов'),
              required: required('Обязательное поле'),
              regEx: regEx(/^\d+$/, 'Только цифры')
            },
            registrationCertificateDate: {
              required: required('Обязательное поле')
            }
          }
        }
        break
    }
    return valid
  },
  computed: {
    ...mapGetters('directory', ['personTypesEnum']),
    typeForm () {
      this.initModel()
      return `${this.personType.toLowerCase()}Form`
    }
  },
  methods: {
    ...mapMutations('apps', ['setProperty']),
    ...mapActions('person', ['createPerson']),
    initModel () {
      this.$v.$reset()
      switch (this.personType) {
        case 'P':
          this.model = {
            firstName: null,
            middleName: null,
            lastName: null,
            birthDate: null,
            gender: null,
            phone: null,
            passport: {
              series: null,
              number: null,
              issueDate: null,
              issuerName: null,
              issuerCode: null
            }
          }
          break
        case 'L':
          this.model = {
            inn: null,
            fullName: null,
            shortName: null,
            corporateFormCode: null,
            headPersonPost: null,
            headPersonLastName: null,
            headPersonFirstName: null,
            headPersonMiddleName: null,
            legalAddress: null,
            headPersonId: null
          }
          break
        case 'IE':
          this.model = {
            registrationCertificateNumber: null,
            registrationCertificateDate: null,
            physicalPersonId: null
          }
          break
      }
    },
    async saveData () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.isCreate = true
      const personType = this.personTypesEnum.find(pte => pte.code === this.personType).enum
      await this.createPerson({ personType, model: this.model })
      this.isCreate = false
      await this.$router.push({ name: 'persons-list', params: { personType } })
    }
  }
}
</script>

<style lang="scss" scoped>
.create-person{
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}
</style>
