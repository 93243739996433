<template>
  <rir-tooltip
    :activator-hover="isTooltip"
  >
    <span
      ref="content"
      slot="activator"
      class="receptionTypeTable__content">
      {{ receptionType.name }}
    </span>
    <span class="amelie--text">{{ receptionType.name }}</span>
  </rir-tooltip>
</template>

<script>
import { mapState } from 'vuex'
import tableCheckEclipse from '@/plugins/mixins/tableCheckEclipse'
export default {
  name: 'reception-type-table',
  mixins: [tableCheckEclipse],
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    ...mapState('directory', ['receptions']),
    receptionType () {
      return this.receptions.find(el => {
        return el.code === this.item.receptionMethod
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.receptionTypeTable{
  &__content{
    @media (max-width: 1366px) {
      width: 72px;
    }
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
