<template>
  <rir-app-layout id="app">
    <rir-modal ref="modal"/>
    <rir-notification ref="notification" />
    <sidebar slot="sidebar" />
    <toolbar slot="toolbar">
      <div class="nomira">
        {{ titlePage }}
      </div>
    </toolbar>
    <router-view />
  </rir-app-layout>
</template>
<script>

import Sidebar from '@/layout/components/Sidebar'
import Toolbar from '@/layout/components/Toolbar'
import { mapState } from 'vuex'
export default {
  name: 'app',
  components: { Toolbar, Sidebar },
  mounted () {
    this.$root.$modal = this.$refs.modal.openModal
    this.$root.$notification = this.$refs.notification.pushNotification
  },
  computed: {
    ...mapState('apps', ['titlePage'])
  }
}
</script>
<style lang="scss" scoped>
.app{
  height: 100%;
}
</style>
