<template>
  <section>
    <div class="row">
      <div class="col-md-12-12">
        <span class="mr-4 titanic--text biqe">{{ guid }}</span>
        <span class="rocky--text">{{ personTypeItem.name }}</span>
      </div>
    </div>
    <component
      v-if="isLoadData"
      :is="typeForm"
      v-model="model"
      :v="$v.model"
    />
    <div class="row">
      <div class="col-md-12-12">
        <rir-button
          :loading="isCreate"
          @click="saveData">
          Сохранить
        </rir-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import IeForm from '@/views/persons/layout/IeForm'
import LForm from '@/views/persons/layout/LForm'
import PForm from '@/views/persons/layout/PForm'
import { regEx, required, strLength } from '@/plugins/vuelidate/customValidate'
import { validationMixin } from 'vuelidate'

export default {
  name: 'PersonItem',
  async mounted () {
    this.setProperty({ name: 'titlePage', value: 'Редактировать заявителя' })
    this.model = await this.getPerson({ personType: this.personType, id: this.guid })
    this.isLoadData = true
  },
  components: { IeForm, LForm, PForm },
  mixins: [validationMixin],
  data: () => ({
    isLoadData: false,
    isCreate: false,
    model: null
  }),
  props: {
    guid: {
      type: String
    },
    personType: {
      type: String
    }
  },
  validations () {
    let valid = {}
    switch (this.personTypeItem.code) {
      case 'P':
        valid = {
          model: {
            firstName: {
              required: required('Обязательное поле')
            },
            lastName: {
              required: required('Обязательное поле')
            },
            birthDate: {
              required: required('Обязательное поле')
            },
            gender: {
              required: required('Обязательное поле')
            },
            phone: {
              strLength: strLength(18, 'Требуется формат +7 (XXX) XXX-XX-XX')
            },
            passport: {
              series: {
                required: required('Обязательное поле'),
                regEx: regEx(/^\d+$/, 'Только цифры'),
                strLength: strLength(4, 'Требуется 4 символа')
              },
              number: {
                required: required('Обязательное поле'),
                regEx: regEx(/^\d+$/, 'Только цифры'),
                strLength: strLength(6, 'Требуется 6 символов')
              },
              issueDate: {
                required: required('Обязательное поле')
              },
              issuerName: {
                required: required('Обязательное поле')
              },
              issuerCode: {
                required: required('Обязательное поле'),
                regEx: regEx(/^[0-9]{3}[- ]?[0-9]{3}$/, 'Форматы 000-000, 000000, 000 000')
              }
            }
          }
        }
        break
      case 'L':
        valid = {
          model: {
            inn: {
              required: required('Обязательное поле'),
              regEx: regEx(/^\d+$/, 'Только цифры'),
              strLength: strLength(10, 'Требуется 10 символов')
            },
            fullName: {
              required: required('Обязательное поле')
            },
            shortName: {
              required: required('Обязательное поле')
            },
            corporateFormCode: {
              required: required('Обязательное поле')
            },
            legalAddress: {
              required: required('Обязательное поле')
            }
          }
        }
        break
      case 'IE':
        valid = {
          model: {
            registrationCertificateNumber: {
              required: required('Обязательное поле'),
              strLength: strLength(15, 'Требуется 15 символов'),
              regEx: regEx(/^\d+$/, 'Только цифры')
            },
            registrationCertificateDate: {
              required: required('Обязательное поле')
            }
          }
        }
        break
    }
    return valid
  },
  computed: {
    ...mapGetters('directory', ['personTypesEnum']),
    typeForm () {
      return `${this.personTypeItem.code.toLowerCase()}Form`
    },
    personTypeItem () {
      return this.personTypesEnum.find(pte => pte.enum === this.personType)
    }
  },
  methods: {
    ...mapMutations('apps', ['setProperty']),
    ...mapActions('person', ['getPerson', 'updatePerson']),
    async saveData () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      try {
        this.isCreate = true
        await this.updatePerson({ personType: this.personTypeItem.enum, model: this.model })
      } catch {}
      this.isCreate = false
    }
  }
}
</script>

<style scoped>

</style>
