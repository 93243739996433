import mixinsCommit from '@/store/mixins/mixinsCommit'

export default {
  ...mixinsCommit,
  addPerson (state, payload) {
    state.personsList.push(payload)
  },
  removePersonList: (state, payload) => {
    const { index } = payload
    state.personsList.splice(index, 1)
  },
  changePersonList: (state, payload) => {
    const { index, person } = payload
    state.personsList.splice(index, 1, person)
  },
  clearPersonList: (state) => {
    state.personsList = []
  },
  setApplicants: (state, payload) => {
    const { applicants, stateStatus } = payload
    const isEdit = [
      'ASSIGNING_EXECUTOR',
      'ANALYZING_DOCUMENTS_COMPLETENESS',
      'REQUERYING_DOCUMENTS_FROM_APPLICANT',
      'REQUESTING_FROM_DEPARTMENTS',
      'ANALYZING_DEPARTMENTS_DOCUMENTS']
      .includes(stateStatus)
    state.personsList = applicants.map(apl => {
      let property
      switch (apl.type) {
        case 'physical':
          property = 'physicalPerson'
          break
        case 'legal':
          property = 'legalPerson'
          break
        case 'individual':
          property = 'individualEntrepreneur'
          break
      }
      return {
        id: apl.id,
        claimedPropertyPercentage: apl.claimedPropertyPercentage,
        person: isEdit ? apl[property] : apl,
        type: apl.type
      }
    })
  }
}
