<template>
  <section class="land-service-item">
    <div
      class="d-flex align-center justify-center"
      v-if="isLoadData">
      <loading-ring
        :size="32"
        color="var(--rir-rocky)"
      />
    </div>
    <div class="row flex-column" v-else>
      <component
        v-for="(comp, index) in stepsList"
        :key="index"
        :finish="stepsList.length > index + 1"
        :is="comp"
        class="mb-3"
      />
<!--      <departments-status class="mb-3"/>-->
<!--      <requesting-from-departments class="mb3"/>-->
<!--      <legal-status class="mb-5" />-->
<!--      <finish-step class="mb-5"/>-->
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import DepartmentsStatus from '@/views/landServiceRds/components/statuses/DepartmentsStatus'
import FinishStep from '@/views/landServiceRds/components/statuses/FinishStep'
import RequestingFromDepartments from '@/views/landServiceRds/components/statuses/RequestingFromDepartments'
import ApprovingByHeadPerson from '@/views/landServiceRds/components/statuses/ApprovingByHeadPerson'
import ApprovingByDepartmentDirector from '@/views/landServiceRds/components/statuses/ApprovingByDepartmentDirector'
import ApprovingLegalDepartment from '@/views/landServiceRds/components/statuses/ApprovingLegalDepartment'
import AnalyzingLegalDepartmentResponse from '@/views/landServiceRds/components/statuses/AnalyzingLegalDepartmentResponse'
import ApprovingAgreementByViceMayor from '@/views/landServiceRds/components/statuses/ApprovingAgreementByViceMayor'
import FinishStepReject from '@/views/landServiceRds/components/statuses/FinishStepReject'

export default {
  name: 'land-service-status',
  mounted () {
    this.loadData()
    this.updateTitle({ name: 'titlePage', value: 'Этапы работ по заявлению' })
  },
  data: () => ({
    isLoadData: true
  }),
  props: {
    requestId: {
      type: String
    }
  },
  computed: {
    ...mapState('stateRds', ['statement']),
    stepsList () {
      let list = [DepartmentsStatus]
      switch (this.statement.status) {
        case 'ANALYZING_DEPARTMENTS_DOCUMENTS':
          list = list.concat([
            RequestingFromDepartments
          ])
          break
        case 'APPROVING_BY_HEAD_PERSON':
          list = list.concat([
            RequestingFromDepartments,
            ApprovingByHeadPerson
          ])
          break
        case 'APPROVING_BY_DEPARTMENT_DIRECTOR':
          list = list.concat([
            RequestingFromDepartments,
            ApprovingByHeadPerson,
            ApprovingByDepartmentDirector
          ])
          break
        case 'APPROVING_LEGAL_DEPARTMENT':
          list = list.concat([
            RequestingFromDepartments,
            ApprovingByHeadPerson,
            ApprovingByDepartmentDirector,
            ApprovingLegalDepartment
          ])
          break
        case 'ANALYZING_LEGAL_DEPARTMENT_RESPONSE':
          list = list.concat([
            RequestingFromDepartments,
            ApprovingByHeadPerson,
            ApprovingByDepartmentDirector,
            ApprovingLegalDepartment,
            AnalyzingLegalDepartmentResponse
          ])
          break
        // Положительное решение по заявлению
        case 'APPROVING_AGREEMENT_BY_VICE_MAYOR':
          list = list.concat([
            RequestingFromDepartments,
            ApprovingByHeadPerson,
            ApprovingByDepartmentDirector,
            ApprovingLegalDepartment,
            AnalyzingLegalDepartmentResponse,
            ApprovingAgreementByViceMayor
          ])
          break
        case 'COMPLETING_HANDLING_STATEMENT':
          list = list.concat([
            RequestingFromDepartments,
            ApprovingByHeadPerson,
            ApprovingByDepartmentDirector,
            ApprovingLegalDepartment,
            AnalyzingLegalDepartmentResponse,
            ApprovingAgreementByViceMayor,
            FinishStep
          ])
          break
        case 'ISSUED_AGREEMENT':
          list = list.concat([
            RequestingFromDepartments,
            ApprovingByHeadPerson,
            ApprovingByDepartmentDirector,
            ApprovingLegalDepartment,
            AnalyzingLegalDepartmentResponse,
            ApprovingAgreementByViceMayor,
            FinishStep,
            null
          ])
          break
        // Отрицательное решение по заявлению
        case 'COMPLETING_REJECTION_AFTER_LD':
          list = list.concat([
            RequestingFromDepartments,
            ApprovingByHeadPerson,
            ApprovingByDepartmentDirector,
            ApprovingLegalDepartment,
            AnalyzingLegalDepartmentResponse,
            FinishStepReject
          ])
          break
        case 'REJECTED_BY_LEGAL_DEPARTMENT':
          list = list.concat([
            RequestingFromDepartments,
            ApprovingByHeadPerson,
            ApprovingByDepartmentDirector,
            ApprovingLegalDepartment,
            AnalyzingLegalDepartmentResponse,
            FinishStepReject,
            null
          ])
          break
      }
      return list
    }
  },
  methods: {
    ...mapMutations('apps', {
      updateTitle: 'setProperty'
    }),
    ...mapActions('stateRds', ['fullStatementItem']),
    async loadData () {
      this.isLoadData = true
      await this.fullStatementItem({
        id: this.requestId
      })
      this.isLoadData = false
    }
  }
}
</script>

<style lang="scss" scoped>
.land-service-item{
  display: flex;
  flex-direction: column;
  &__column{
    display: flex;
    align-items: center
  }
}
</style>
