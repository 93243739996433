export default {
  async savePersonToStatement ({ rootGetters, state }, payload) {
    const { id } = payload
    const persons = state.personsList.map(p => ({
      id: p.person.id,
      type: p.type,
      claimedPropertyPercentage: p.claimedPropertyPercentage
    }))
    await rootGetters.PUT(`land/rds/statement/${id}/applicants`, persons)
  }
}
