<template>
  <section class="d-flex flex-column pb-5">
    <div
      class="d-flex align-center justify-center"
      v-if="isLoadData">
      <loading-ring
        :size="32"
        color="var(--rir-rocky)"
      />
    </div>
    <template
      v-else>
      <component
        :is="statementComponent"
        :statement="statement"
        :redistribution="redistribution"
      />
      <div class="px-8 d-flex flex-column">
        <template
          v-if="!!isRequest"
        >
          <div class="melia">Запрос от специалиста</div>
          <a
            class="my-5"
            :href="isRequest.url"
            download
            target="_blank">
            <rir-button class="memento godfather--text">Скачать запрос</rir-button>
          </a>
        </template>
        <return-comments
          :process="dataProcess"
        />
        <component
          :process="dataProcess"
          :processCode="processCode"
          :is="componentStep"
        />
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import getEnv from '@/plugins/env'
import LogicStep from '@/views/Department/utils/LogicStep'

import FormPrv from '@/views/landServicePrv/LandServiceForm'
import FormRds from '@/views/landServiceRds/LandServiceForm'
import ReturnComments from '@/views/Department/components/ReturnComments'

export default {
  name: 'statement-item',
  components: { ReturnComments, FormPrv, FormRds },
  mounted () {
    this.loadData()
  },
  data: () => ({
    isLoadData: true,
    processCode: null,
    dataProcess: null
  }),
  props: {
    requestId: {
      type: String
    }
  },
  computed: {
    ...mapGetters('session', ['departmentCode']),
    ...mapState('statePrv', { stateDataPrv: 'statement' }),
    ...mapState('stateRds', { stateDataRds: 'statement', redistribution: 'redistribution' }),
    statement () {
      switch (this.processCode) {
        case 'LAND_REDISTRIBUTION':
          return this.stateDataRds
        case 'LAND_PROVISION':
          return this.stateDataPrv
      }
      return null
    },
    statementComponent () {
      switch (this.processCode) {
        case 'LAND_REDISTRIBUTION':
          return FormRds
        case 'LAND_PROVISION':
          return FormPrv
      }
      return null
    },
    componentStep () {
      return LogicStep.apply(this, [this.dataProcess, this.departmentCode])
    },
    isRequest () {
      let code = null
      switch (this.processCode) {
        case 'LAND_REDISTRIBUTION':
          switch (this.departmentCode) {
            case 'dzp':
              code = '04-01'
              break
            case 'daig':
              code = '03-01'
              break
            case 'ld':
              code = ['06-01', '07-01']
              return this.statement.attachments.find(el => code.includes(el.type))
          }
          return this.statement.attachments.find(el => el.type === code)
        case 'LAND_PROVISION':
          switch (this.departmentCode) {
            case 'dzp':
              code = '14-01'
              break
            case 'daig':
              code = '13-01'
              break
            case 'ld':
              code = ['16-01', '17-01']
              return this.statement.statement.attachments.find(el => code.includes(el.type))
          }
          return this.statement.statement.attachments.find(el => el.type === code)
      }
      return null
    }
  },
  methods: {
    ...mapActions('statePrv', { prvData: 'fullStatementItem' }),
    ...mapActions('stateRds', { rdsData: 'fullStatementItem' }),
    async loadData () {
      this.isLoadData = true
      const { data } =
        await this.$axios
          .get(`${getEnv('VUE_APP_API_SERVER')}land/${this.departmentCode}/${this.requestId}`)
      const {
        processCode,
        statement: { id: statementId },
        ...el
      } = data
      this.dataProcess = el
      this.processCode = processCode
      switch (processCode) {
        case 'LAND_REDISTRIBUTION':
          await this.rdsData({ id: statementId })
          break
        case 'LAND_PROVISION':
          await this.prvData({ id: statementId })
          break
      }
      this.isLoadData = false
    }
  }
}
</script>
