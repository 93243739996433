<template>
  <rir-accordion v-if="isView">
    <template slot="activator">
      <div class="d-flex flex-column">
        <div class="titanic--text melia mb-1">
          Отправка договора в правовой департамент
        </div>
        <div class=" d-flex align-center">
          <rir-icon
            class="mr-2"
            :icon="isStatus.icon"
            :fill="isStatus.fillIcon"
          />
          <span class="anie terminator--text">{{ isStatus.text }}</span>
        </div>
      </div>
    </template>
    <div class="d-flex flex-column">
      <div class="mb-2 titanic--text melia">
        Правовой департамент
      </div>
      <template v-if="statusStep === 1">
        <div class="d-flex flex-column">
          <div class="d-flex align-center">
            <rir-icon
              fill="matrix"
              class="mr-2"
              icon="selected"
            />
            <span>Отправлено в ПД</span>
          </div>
          <span class="pl-6 titanic--text anie">Ожидаем ответ</span>
        </div>
      </template>
      <template v-if="statusStep === 2">
        <div class="d-flex flex-column">
          <template v-if="!statement.statement.note">
            <div class="d-flex align-center">
              <rir-icon
                fill="snatch"
                class="mr-2"
                icon="notifications"
              />
              <span>Получен ответ</span>
            </div>
            <span class="mb-5 pl-6 titanic--text anie">Ознакомьтесь с документом</span>
          </template>
          <template v-else>
            <div class="d-flex align-center">
              <rir-icon
                fill="rocky"
                class="mr-2"
                icon="answer"
              />
              <span>Возвращено от мэра</span>
            </div>
            <span class="mb-5 pl-6 titanic--text anie">{{ statement.statement.note }}</span>
          </template>
          <a v-if="filePDist.length" :href="filePDist[0].url" class="mb-5" download target="_blank">
            <rir-button
              class="memento godfather--text"
            >Скачать Документ</rir-button>
          </a>
          <div class="row row--no-grep">
            <div class="col-md-6-12 pl-0">
              <rir-button
                @click="changeStatusLegal('APPROVE')">Продолжить работу по заявлению</rir-button>
            </div>
            <div class="col-md-6-12 pr-0">
              <rir-button
                @click="changeStatusLegal('RETURN')"
                color="secondary">Поиск решения</rir-button>
            </div>
          </div>
          <rir-drag-upload-file
            class="mb-5"
            :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt', '.zip']"
            :count-file="1"
            :upload-url="$root.$uploadServer"
            :params-body="{
              code: 'LAND',
              type: '17-04'
            }"
            v-model="attachment" />
          <rir-button
            :disabled="!attachment.length"
            @click="changeStatusLegal('CANCEL')"
            color="error">Отправить отказ на согласование</rir-button>
        </div>
      </template>
      <template v-if="statusStep === 3">
        <div class="d-flex flex-column">
          <div class="d-flex align-center">
            <rir-icon
              fill="snatch"
              class="mr-2"
              icon="notifications"
            />
            <span>{{ contentData.title }}</span>
          </div>
          <rir-file-list
            class="mt-2"
            :count-line="1"
            :files="filePDist"
          />
        </div>
      </template>
    </div>
  </rir-accordion>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'legal-status',
  data: () => ({
    attachment: []
  }),
  computed: {
    ...mapGetters('session', ['rolePlatform']),
    ...mapState('statePrv', ['statement']),
    isView () {
      return [
        'APPROVING_LEGAL_DEPARTMENT',
        'ANALYZING_LEGAL_DEPARTMENT_RESPONSE',
        'COMPLETING_HANDLING_STATEMENT',
        'PREPARING_REJECTION_PROJECT',
        'REJECTED_BY_LEGAL_DEPARTMENT',
        'ISSUED_AGREEMENT',
        'COMPLETING_REJECTION_AFTER_LD',
        'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_LD',
        'APPROVING_AGREEMENT_BY_VICE_MAYOR'
      ].includes(this.statement.statement.status)
    },
    isStatus () {
      let status = {
        icon: 'in-progress',
        fillIcon: 'rocky',
        text: 'В процессе'
      }
      const isFinishStep = [
        'COMPLETING_HANDLING_STATEMENT',
        'PREPARING_REJECTION_PROJECT',
        'REJECTED_BY_HEAD_PERSON',
        'COMPLETING_HANDLING_STATEMENT',
        'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_LD',
        'COMPLETING_REJECTION_AFTER_LD',
        'REJECTED_BY_LEGAL_DEPARTMENT',
        'COMPLETING_REJECTION_AFTER_LD',
        'ISSUED_AGREEMENT',
        'APPROVING_AGREEMENT_BY_VICE_MAYOR'
      ].includes(this.statement.statement.status)
      if (isFinishStep) {
        status = {
          icon: 'selected',
          fillIcon: 'matrix',
          text: 'Завершено'
        }
      }
      return status
    },
    statusStep () {
      /*
      * 1 - Ожидается ответ от ПД
      * 2 - принятие решение специалистом
      * 3 - Решение принято
      * */
      switch (this.statement.statement.status) {
        case 'APPROVING_LEGAL_DEPARTMENT':
          return 1
        case 'ANALYZING_LEGAL_DEPARTMENT_RESPONSE':
          return 2
        default:
          return 3
      }
    },
    contentData () {
      let content
      switch (this.statement.statement.status) {
        case 'APPROVING_AGREEMENT_BY_VICE_MAYOR':
        case 'COMPLETING_HANDLING_STATEMENT':
        case 'ISSUED_AGREEMENT':
          content = {
            title: 'Принято положительное решение',
            status: true
          }
          break
        case 'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_LD':
        case 'COMPLETING_REJECTION_AFTER_LD':
        case 'REJECTED_BY_LEGAL_DEPARTMENT':
          content = {
            title: 'Принято отрицательное решение',
            status: false
          }
          break
      }
      return content
    },
    filePDist () {
      return this.statement.statement.attachments.filter(el => el.type === '15-02')
    },
    fileReject () {
      return this.statement.statement.attachments.filter(el => el.type === '17-04')
    }
  },
  methods: {
    ...mapActions('statePrv', ['changeStatus', 'addAttachStatement']),
    ...mapMutations('statePrv', ['clearFile']),
    async changeStatusLegal (type) {
      if (type === 'CANCEL') {
        !!this.attachment.length && await this.addAttachStatement(this.attachment[0])
      }
      if (type === 'RETURN') {
        this.clearFile()
      }
      await this.changeStatus({ decision: type })
    }
  }
}
</script>
