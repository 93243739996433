<template>
  <span>{{ deadline | dateRuFormat }}</span>
</template>

<script>
export default {
  name: 'deadline-table',
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    deadline () {
      // прибавляем 30 дней
      return this.item.receiptDate + 2592000000
    }
  }
}
</script>
