<template>
  <section>
    <div class="row">
      <div class="col-md-12-12">
        <span class="melia">Распределение долей участка</span>
      </div>
    </div>
    <rir-form-item
      :error="$v.model.$error">
      <div class="row">
        <div
          v-for="(person, index) in model"
          :key="index"
          class="col-md-4-12 d-flex flex-column">
          <component
            :is="`person-share-${person.type}`"
            v-model="person.claimedPropertyPercentage"
            :index="index"
            :person="person"
            :v="$v.model.$each[index]"
            :readonly="readonly" />
        </div>
      </div>
      <div class="row" v-if="$v.model.$error">
        <div class="col-md-4-12 fargo--text">
          {{ mesErr($v.model) }}
        </div>
      </div>
    </rir-form-item>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required, sumDataEach, typeFloatOrNumber } from '@/plugins/vuelidate/customValidate'

import PersonSharePhysical from '@/views/landServicePrv/components/layout/PersonSharePhysical'
import PersonShareIndividual from '@/views/landServicePrv/components/layout/PersonShareIndividual'
import PersonShareLegal from '@/views/landServicePrv/components/layout/PersonShareLegal'

export default {
  name: 'persons-share-land',
  mixins: [validationMixin, validateMixin],
  components: { PersonSharePhysical, PersonShareIndividual, PersonShareLegal },
  mounted () {
    this.model = JSON.parse(JSON.stringify(this.personsList))
  },
  data: () => ({
    model: []
  }),
  validations: {
    model: {
      $each: {
        claimedPropertyPercentage: {
          required: required('Обязательное поле'),
          typeFloatOrNumber: typeFloatOrNumber('Формат DDD.DD')
        }
      },
      sumPercentage: sumDataEach('claimedPropertyPercentage', 100, 'Общая сумма долей не равна 100%')
    }
  },
  props: {
    readonly: {
      type: Boolean
    }
  },
  watch: {
    model: {
      deep: true,
      handler (val) {
        this.setProperty({ name: 'personsList', value: val })
      }
    }
  },
  computed: {
    ...mapState('statementPerson', ['personsList'])
  },
  methods: {
    ...mapMutations('statementPerson', ['setProperty'])
  }
}
</script>
