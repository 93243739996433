import Vue from 'vue'
import VueRouter from 'vue-router'
import DefaultLayout from '@/layout/DefaultLayout'
import LayoutRouterView from '@/layout/LayoutRouterView'
// RDS
import LandServiceList from '@/views/landServiceRds/LandServiceList'
import LandServiceForm from '@/views/landServiceRds/LandServiceForm'
import LandServiceItem from '@/views/landServiceRds/LandServiceItem'
import LandServiceStatus from '@/views/landServiceRds/LandServiceStatus'

// PRV
import LandServiceListPrv from '@/views/landServicePrv/LandServiceList'
import LandServiceFormPrv from '@/views/landServicePrv/LandServiceForm'
import LandServiceItemPrv from '@/views/landServicePrv/LandServiceItem'
import LandServiceStatusPrv from '@/views/landServicePrv/LandServiceStatus'

import getEnv from '@/plugins/env'
import PersonsList from '@/views/persons/PersonsList'
import PersonItem from '@/views/persons/PersonItem'
import PersonCreate from '@/views/persons/PersonCreate'

// Department
import StatementList from '@/views/Department/StatementList'
import StatementItem from '@/views/Department/StatementItem'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'land-prv' }
  },
  // {
  //   path: '/land-rds',
  //   name: 'land-rds',
  //   component: LayoutRouterView,
  //   redirect: { name: 'land-rds-list' },
  //   children: [
  //     {
  //       path: 'create',
  //       name: 'land-rds-create',
  //       component: LandServiceForm,
  //       meta: {
  //         layout: DefaultLayout,
  //         roles: ['SECRETARY']
  //       }
  //     },
  //     {
  //       path: '',
  //       name: 'land-rds-list',
  //       component: LandServiceList,
  //       props: (route) => ({ statusCodes: route.query.statusCodes }),
  //       meta: {
  //         layout: DefaultLayout,
  //         roles: ['CHIEF', 'SPECIALIST', 'DEPARTMENT_DIRECTOR', 'VICE_MAYOR']
  //       }
  //     },
  //     {
  //       path: ':requestId',
  //       name: 'land-rds-item',
  //       component: LandServiceItem,
  //       props: true,
  //       meta: {
  //         layout: DefaultLayout,
  //         roles: ['DAIG', 'DZP', 'DP', 'CHIEF', 'SPECIALIST', 'DEPARTMENT_DIRECTOR', 'VICE_MAYOR']
  //       }
  //     },
  //     {
  //       path: ':requestId/status',
  //       name: 'land-rds-item-status',
  //       component: LandServiceStatus,
  //       props: true,
  //       meta: {
  //         layout: DefaultLayout,
  //         roles: ['CHIEF', 'SPECIALIST', 'DEPARTMENT_DIRECTOR']
  //       }
  //     }
  //   ]
  // },
  {
    path: '/land-prv',
    name: 'land-prv',
    component: LayoutRouterView,
    redirect: { name: 'land-prv-list' },
    children: [
      {
        path: 'create',
        name: 'land-prv-create',
        component: LandServiceFormPrv,
        meta: {
          roles: ['SECRETARY']
        }
      },
      {
        path: '',
        name: 'land-prv-list',
        component: LandServiceListPrv,
        props: (route) => ({ statusCodes: route.query.statusCodes }),
        meta: {
          roles: ['DAIG', 'DZP', 'DP', 'CHIEF', 'SPECIALIST', 'VICE_MAYOR', 'DEPARTMENT_DIRECTOR']
        }
      },
      {
        path: ':requestId',
        name: 'land-prv-item',
        component: LandServiceItemPrv,
        props: true,
        meta: {
          roles: ['DAIG', 'DZP', 'DP', 'CHIEF', 'SPECIALIST', 'VICE_MAYOR', 'DEPARTMENT_DIRECTOR']
        }
      },
      {
        path: ':requestId/status',
        name: 'land-prv-item-status',
        component: LandServiceStatusPrv,
        props: true,
        meta: {
          roles: ['CHIEF', 'SPECIALIST']
        }
      }
    ]
  },
  {
    path: '/departments',
    name: 'departments',
    component: LayoutRouterView,
    redirect: { name: 'departments-list' },
    children: [
      {
        path: '',
        name: 'departments-list',
        component: StatementList,
        meta: {
          roles: [
            'DZP_LEAD_ADVISOR',
            'DZP_HEAD_PERSON',
            'DZP_DEPUTY_DIRECTOR',
            'DZP_DEPARTMENT_DIRECTOR',
            'DAIG_HEAD_PERSON_URBAN', 'DAIG_CHIEF_INSPECTOR', 'DAIG_DEPUTY_DIRECTOR', 'DAIG_HEAD_PERSON_SOCIAL', 'DAIG_DEPARTMENT_DIRECTOR',
            'LD_HEAD_PERSON', 'LD_LEAD_ADVISOR', 'LD_DEPARTMENT_DIRECTOR'
          ]
        }
      },
      {
        path: ':requestId',
        name: 'departments-item',
        component: StatementItem,
        props: true,
        meta: {
          roles: [
            'DZP_LEAD_ADVISOR',
            'DZP_HEAD_PERSON',
            'DZP_DEPUTY_DIRECTOR',
            'DZP_DEPARTMENT_DIRECTOR',
            'DAIG_HEAD_PERSON_URBAN', 'DAIG_CHIEF_INSPECTOR', 'DAIG_DEPUTY_DIRECTOR', 'DAIG_HEAD_PERSON_SOCIAL', 'DAIG_DEPARTMENT_DIRECTOR',
            'LD_HEAD_PERSON', 'LD_LEAD_ADVISOR', 'LD_DEPARTMENT_DIRECTOR'
          ]
        }
      }
    ]
  },
  {
    path: '/persons',
    name: 'persons',
    component: LayoutRouterView,
    redirect: { name: 'persons-list', params: { personType: 'physical' } },
    children: [
      {
        path: 'create',
        name: 'person-create',
        component: PersonCreate,
        meta: {
          roles: ['DAIG', 'DZP', 'DP', 'CHIEF', 'SPECIALIST', 'SECRETARY']
        }
      },
      {
        path: ':personType',
        name: 'persons-list',
        component: PersonsList,
        props: true,
        meta: {
          roles: ['DAIG', 'DZP', 'DP', 'CHIEF', 'SPECIALIST', 'SECRETARY']
        }
      },
      {
        path: ':personType/:guid',
        name: 'person-item',
        component: PersonItem,
        props: true,
        meta: {
          roles: ['DAIG', 'DZP', 'DP', 'CHIEF', 'SPECIALIST', 'SECRETARY']
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: getEnv('BASE_URL'),
  routes
})
export default router
