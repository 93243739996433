<template>
  <section class="land-service-form">
    <div class="row" v-if="isEditForm">
      <div
        class="col-md-12-12 metioche titanic--text biqe">
        № {{ numberStatement }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4-12">
        <rir-form-item
          :error="$v.model.receiptDate.$error"
          :message="mesErr($v.model.receiptDate)">
          <rir-date-picker
            :readonly="isReadOnly"
            click-close
            label="Дата поступления"
            is-time
            v-model="model.receiptDate"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-input
          :readonly="isReadOnly"
          label="Номер СЭД"
          v-model="model.sedNumber"
        />
      </div>
      <div class="col-md-4-12">
        <rir-form-item
          :error="$v.model.receptionMethod.$error"
          :message="mesErr($v.model.receptionMethod)">
          <rir-select
            :readonly="isReadOnly"
            :items="receptions"
            label="Способ приема заявления"
            v-model="model.receptionMethod"
            id-value="code"
            text-value="name"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12">
        <statement-person-block
          :readonly="isReadOnly"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12 d-flex flex-column">
        <rir-form-item
          :error="$v.attachmentStatementPerson.$error"
          :message="mesErr($v.attachmentStatementPerson)">
        <div class="melia mb-5">Пакет документов от заявителя</div>
        <rir-file-list
          v-if="isReadOnly"
          :files="attachmentStatementPerson"
        />
        <rir-drag-upload-file
          v-else
          v-model="attachmentStatementPerson"
          :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt']"
          :count-file="10"
          :upload-url="$root.$uploadServer"
          :params-body="{
            code: 'LAND',
            type: '01-01'
          }"
        />
        </rir-form-item>
      </div>
    </div>
    <div class="row" v-if="attachmentStatement.length">
      <div class="col-md-12-12 d-flex flex-column">
        <div class="d-flex align-center mb-5">
          <span class="melia mr-6">Пакет документов по заявлению</span>
          <a
            v-if="isAnalyzeDocFromDepartment"
            @click="openAddFiles"
            class="amphiris d-flex align-center">
            <rir-icon
              icon="add"
              class="mr-2"
            />
            Добавить
          </a>
        </div>
        <rir-file-list
          :files="attachmentStatement"
        />
      </div>
    </div>
    <template v-if="formSettingsDefault.isLandsData">
      <lands-data
        :readonly="isReadOnly"
        :redistribution="redistribution"
        ref="lands"
      />
    </template>
    <div class="row" v-if="isGetDocPerson">
      <div class="col-md-12-12 melia">Осталось времени до возврата заявления</div>
      <div class="col-md-12-12 d-flex align-center">
        <rir-icon
          class="mr-2"
          icon="clock"
        />
        <span>{{ isGetDocPerson }}</span>
      </div>
    </div>
    <div
      class="row"
       v-for="(action, index) in formSettingsDefault.actions"
      :key="index"
    >
      <div class="col-md-12-12">
        <rir-button
          :loading="isSave"
          :color="action.color"
          @click="action.action"
        >
          {{ action.text }}
        </rir-button>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions, mapMutations } from 'vuex'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required } from '@/plugins/vuelidate/customValidate'
import formState from '@/views/landServiceRds/utils/formState'
import LandsData from '@/views/landServiceRds/components/LandsData'
import StatementPersonBlock from '@/components/statmentPerson/StatementPersonBlock'
import AddFilesStatement from '@/views/landServiceRds/modal/AddFilesStatement'

export default {
  name: 'land-service-form',
  components: { StatementPersonBlock, LandsData },
  mixins: [validationMixin, validateMixin],
  mounted () {
    if (this.statement) {
      this.model = { ...this.statement }
    }
    this.updateTitle({ name: 'titlePage', value: this.statusStatement?.name || this.formSettingsDefault.title })
  },
  beforeDestroy () {
    this.model = {
      receiptDate: +new Date(),
      sedNumber: null,
      receptionMethod: null,
      attachments: []
    }
    this.setProperty({ name: 'personsList', value: [] })
  },
  data: () => ({
    isSave: false,
    model: {
      receiptDate: +new Date(),
      sedNumber: null,
      receptionMethod: null,
      attachments: []
    }
  }),
  validations: {
    model: {
      receiptDate: {
        required: required('Обязательное поле')
      },
      receptionMethod: {
        required: required('Обязательное поле')
      }
    },
    attachmentStatementPerson: {
      required: required('Обязательное поле')
    }
  },
  props: {
    statement: {
      type: Object
    },
    redistribution: {
      type: Object
    }
  },
  computed: {
    ...mapState('directory', ['receptions', 'statementStatuses']),
    formSettingsDefault () {
      return formState.apply(this, [this?.statement?.status])
    },
    isEditForm () {
      return !!this.statement
    },
    isReadOnly () {
      return this.formSettingsDefault.isRead
    },
    numberStatement () {
      return this.statement?.number
    },
    statusStatement () {
      return this.statementStatuses.find(s => s.code === this.statement?.status)
    },
    attachmentStatementPerson: {
      get () {
        return this.model.attachments.filter(el => el.type === '01-01')
      },
      set (val) {
        const model = { ...this.model }
        const attach = model.attachments.filter(attach => attach.type !== '01-01')
        this.model.attachments = attach.concat(val)
      }
    },
    attachmentStatement () {
      return this.statement?.attachments
        .filter(el => !['01-01', '02-01', '03-01', '04-01'].includes(el.type)) || []
    },
    isGetDocPerson () {
      if (this?.statement?.status === 'REQUERYING_DOCUMENTS_FROM_APPLICANT') {
        const start = this.redistribution.requeryingDocumentsStartTime ? moment(this.redistribution.requeryingDocumentsStartTime) : moment()
        const end = (this.redistribution.requeryingDocumentsStartTime ? moment(this.redistribution.requeryingDocumentsStartTime) : moment()).add(5, 'days')
        return end.from(start)
      } else {
        return false
      }
    },
    isAnalyzeDocFromDepartment () {
      return this.statement?.status === 'ANALYZING_DEPARTMENTS_DOCUMENTS'
    }
  },
  methods: {
    ...mapMutations('apps', {
      updateTitle: 'setProperty'
    }),
    ...mapMutations('statementPerson', ['setProperty']),
    ...mapActions('stateRds', ['updateStatement', 'saveStatement', 'statementItem']),
    async saveRequest () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.isSave = true
      // Создаем заявление
      await this.saveStatement(this.model)
      this.clearForm()
      this.$root.$notification({
        type: 'success',
        text: 'Заявление отправлено в работу'
      })
      this.isSave = false
    },
    async updateRequest () {
      this.$v.$touch()
      this.$refs.lands.$v.$touch()
      if (this.$v.$invalid || this.$refs.lands.$v.$invalid) return false
      const model = {
        ...this.$refs.lands.model,
        id: this.model.id,
        statement: this.model
      }
      await this.updateStatement(model)
      this.$root.$notification({
        type: 'success',
        text: 'Заявление обновлено!'
      })
      return true
    },
    clearForm () {
      this.model = {
        receiptDate: +new Date(),
        sedNumber: null,
        receptionMethod: null,
        attachments: []
      }
      this.$v.$reset()
    },
    openAddFiles () {
      this.$root.$modal(AddFilesStatement)
    }
  }
}
</script>

<style lang="scss" scoped>
.land-service-form{
  &__person{
    padding: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 12px;
    background-color: var(--rir-amelie);
  }
}
</style>
