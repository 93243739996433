<template>
  <rir-accordion>
    <template slot="activator">
      <div class="d-flex flex-column">
        <div class="titanic--text melia mb-1">
          Окончание работ по заявлению: соглашение
        </div>
        <div class="d-flex align-center">
          <rir-icon
            class="mr-2"
            :icon="isStatus.icon"
            :fill="isStatus.fillIcon"
          />
          <span class="anie terminator--text">{{ isStatus.text }}</span>
        </div>
      </div>
      <div class="d-flex flex-column flex-auto">
        <div class="kleodora anie titanic--text mb-1">
          Выполнено задач
        </div>
        <div class="melia titanic--text">
          {{ countGet }} / 3
        </div>
      </div>
    </template>
    <div class="d-flex flex-column">
      <rir-checkbox
        :disabled="isChangeCheckbox || finish"
        class="mb-5"
        label="Соглашение подписано с обеих сторон"
        v-model="redistribution.signed"
      />
      <rir-checkbox
        :disabled="isChangeCheckbox || finish"
        class="mb-5"
        label="Квитанция оплачена"
        v-model="redistribution.paid"
      />
      <rir-checkbox
        :disabled="isChangeCheckbox || finish"
        class="mb-5"
        label="Документы лично выданы заявителю"
        v-model="redistribution.issued"
      />
      <rir-button
        v-if="!finish"
        :disabled="!isCloseStatement"
        @click="changeStatusFinish">
        Завершить работы по заявлению</rir-button>
    </div>
  </rir-accordion>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'finish-step',
  data: () => ({
    isChangeCheckbox: false
  }),
  watch: {
    computedModel: {
      async handler (val, oldVal) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal) && !this.isChangeCheckbox) {
          this.isChangeCheckbox = true
          await this.updateCheckListFinish({ ...val, statement: this.statement })
          this.isChangeCheckbox = false
        }
      },
      deep: true
    }
  },
  props: {
    finish: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('session', ['rolePlatform']),
    ...mapState('stateRds', ['statement', 'redistribution']),
    computedModel () {
      return Object.assign({}, this.redistribution)
    },
    isStatus () {
      return {
        icon: this.finish ? 'selected' : 'in-progress',
        fillIcon: this.finish ? 'matrix' : 'rocky',
        text: this.finish ? 'Завершено' : 'В процессе'
      }
    },
    isCloseStatement () {
      return this.redistribution.issued && this.redistribution.paid && this.redistribution.signed
    },
    countGet () {
      let count = 0
      count += +this.redistribution.issued
      count += +this.redistribution.signed
      count += +this.redistribution.paid
      return count
    }
  },
  methods: {
    ...mapActions('stateRds', ['changeStatus', 'updateCheckListFinish']),
    ...mapMutations('stateRds', ['setProperty']),
    async changeStatusFinish () {
      await this.changeStatus({
        decision: 'APPROVE'
      })
    }
  }
}
</script>
