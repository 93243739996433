<template>
  <section>
    <div class="row">
      <div class="col-md-12-12">
        <span class="melia">Информация об участке</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6-12 d-flex flex-column">
        <rir-form-item
          :error="$v.landArea.cadastralNumber.$error"
          :message="mesErr($v.landArea.cadastralNumber)">
          <rir-input
            :readonly="readonly"
            label="Кадастровый номер"
            v-model="landArea.cadastralNumber"
            v-mask="'##:##:#######:####'"
          />
        </rir-form-item>
      </div>
      <div class="col-md-6-12">
        <rir-form-item
          :error="$v.landArea.area.$error"
          :message="mesErr($v.landArea.area)">
          <rir-input
            :readonly="readonly"
            label="Площадь, м2"
            v-model="landArea.area"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6-12">
        <rir-form-item>
          <rir-input
            :readonly="readonly"
            label="Реквизиты решения"
            v-model="landArea.preliminaryAgreementDecision"
          />
        </rir-form-item>
      </div>
<!--      <div class="col-md-6-12">-->
<!--        <rir-form-item-->
<!--          :error="$v.landArea.usagePurpose.$error"-->
<!--          :message="mesErr($v.landArea.usagePurpose)">-->
<!--          <rir-input-->
<!--            :readonly="readonly"-->
<!--            label="Цель использования"-->
<!--            v-model="landArea.usagePurpose"-->
<!--          />-->
<!--        </rir-form-item>-->
<!--      </div>-->
      <div class="col-md-6-12">
        <rir-form-item
          :error="$v.landArea.address.$error"
          :message="mesErr($v.landArea.address)">
          <rir-input
            :readonly="readonly"
            label="Адрес"
            v-model="landArea.address"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6-12">
        <rir-form-item>
          <rir-input
            :readonly="readonly"
            label="Право пользования"
            v-model="landArea.usageRights"
          />
        </rir-form-item>
      </div>
      <div class="col-md-6-12">
        <rir-form-item>
          <rir-input
            :readonly="readonly"
            label="Реквизиты документа на право пользования"
            v-model="landArea.usageRightsDoc"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8-12">
        <rir-form-item
          :error="$v.landArea.permittedUsage.$error"
          :message="mesErr($v.landArea.permittedUsage)">
          <rir-input
            :readonly="readonly"
            label="Разрешенное использование"
            v-model="landArea.permittedUsage"
          />
        </rir-form-item>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required } from '@/plugins/vuelidate/customValidate'
import { mask } from 'vue-the-mask'
export default {
  name: 'lands-data',
  directives: { mask },
  mixins: [validationMixin, validateMixin],
  mounted () {
    if (this.item.landArea) this.landArea = { ...this.landArea, ...this.item.landArea }
  },
  data: () => ({
    landArea: {
      cadastralNumber: null,
      address: null,
      area: null,
      usageRights: null,
      usageRightsDoc: null,
      preliminaryAgreementDecision: null,
      usagePurpose: null,
      permittedUsage: null
    }
  }),
  validations: {
    landArea: {
      cadastralNumber: {
        required: required('Обязательное поле')
      },
      address: {
        required: required('Обязательное поле')
      },
      area: {
        required: required('Обязательное поле')
      },
      // usageRights: {
      //   required: required('Обязательное поле')
      // },
      // usageRightsDoc: {
      //   required: required('Обязательное поле')
      // },
      // preliminaryAgreementDecision: {
      //   required: required('Обязательное поле')
      // },
      // usagePurpose: {
      //   required: required('Обязательное поле')
      // },
      permittedUsage: {
        required: required('Обязательное поле')
      }
    }
  },
  props: {
    item: {
      type: Object
    },
    readonly: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('directory', ['redistributions'])
  },
  methods: {
    addArea () {
      this.landAreas.push({
        realtyType: null,
        address: null,
        usagePurpose: null,
        cadastralNumber: null,
        // @TODO прописать заявителей и при изменение сделать замену
        applicantsShare: []
      })
    },
    remArea (index) {
      this.landAreas.splice(index, 1)
    }
  }
}
</script>
