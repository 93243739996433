export default {
  rolePlatform (state) {
    const roles = new Map([
      ['LAND.SECRETARY', 'SECRETARY'],
      ['LAND.HEAD_PERSON', 'CHIEF'],
      ['LAND.SPECIALIST', 'SPECIALIST'],
      ['LAND.VICE_MAYOR', 'VICE_MAYOR'],
      ['LAND.DEPARTMENT_DIRECTOR', 'DEPARTMENT_DIRECTOR'],
      // DZP
      ['LAND.DZP.LEAD_ADVISOR', 'DZP_LEAD_ADVISOR'],
      ['LAND.DZP.HEAD_PERSON', 'DZP_HEAD_PERSON'],
      ['LAND.DZP.DEPUTY_DIRECTOR', 'DZP_DEPUTY_DIRECTOR'],
      ['LAND.DZP.DEPARTMENT_DIRECTOR', 'DZP_DEPARTMENT_DIRECTOR'],
      // DAIG
      ['LAND.DAIG.HEAD_PERSON_URBAN', 'DAIG_HEAD_PERSON_URBAN'],
      ['LAND.DAIG.CHIEF_INSPECTOR', 'DAIG_CHIEF_INSPECTOR'],
      ['LAND.DAIG.DEPUTY_DIRECTOR', 'DAIG_DEPUTY_DIRECTOR'],
      ['LAND.DAIG.HEAD_PERSON_SOCIAL', 'DAIG_HEAD_PERSON_SOCIAL'],
      ['LAND.DAIG.DEPARTMENT_DIRECTOR', 'DAIG_DEPARTMENT_DIRECTOR'],
      // LD
      ['LAND.LD.HEAD_PERSON', 'LD_HEAD_PERSON'],
      ['LAND.LD.LEAD_ADVISOR', 'LD_LEAD_ADVISOR'],
      ['LAND.LD.DEPARTMENT_DIRECTOR', 'LD_DEPARTMENT_DIRECTOR']
    ])
    return roles.get(state.user.roles.find(el => roles.get(el)))
  },
  roleName (state) {
    const user = state.user
    const roles = new Map([
      ['LAND.SECRETARY', 'SECRETARY'],
      ['LAND.HEAD_PERSON', 'CHIEF'],
      ['LAND.SPECIALIST', 'SPECIALIST'],
      ['LAND.VICE_MAYOR', 'VICE_MAYOR'],
      ['LAND.DEPARTMENT_DIRECTOR', 'DEPARTMENT_DIRECTOR'],
      // DZP
      ['LAND.DZP.LEAD_ADVISOR', 'DZP_LEAD_ADVISOR'],
      ['LAND.DZP.HEAD_PERSON', 'DZP_HEAD_PERSON'],
      ['LAND.DZP.DEPUTY_DIRECTOR', 'DZP_DEPUTY_DIRECTOR'],
      ['LAND.DZP.DEPARTMENT_DIRECTOR', 'DZP_DEPARTMENT_DIRECTOR'],
      // DAIG
      ['LAND.DAIG.HEAD_PERSON_URBAN', 'DAIG_HEAD_PERSON_URBAN'],
      ['LAND.DAIG.CHIEF_INSPECTOR', 'DAIG_CHIEF_INSPECTOR'],
      ['LAND.DAIG.DEPUTY_DIRECTOR', 'DAIG_DEPUTY_DIRECTOR'],
      ['LAND.DAIG.HEAD_PERSON_SOCIAL', 'DAIG_HEAD_PERSON_SOCIAL'],
      ['LAND.DAIG.DEPARTMENT_DIRECTOR', 'DAIG_DEPARTMENT_DIRECTOR'],
      // LD
      ['LAND.LD.HEAD_PERSON', 'LD_HEAD_PERSON'],
      ['LAND.LD.LEAD_ADVISOR', 'LD_LEAD_ADVISOR'],
      ['LAND.LD.DEPARTMENT_DIRECTOR', 'LD_DEPARTMENT_DIRECTOR']
    ])
    return roles.get(user.roles.find(el => roles.get(el)))
  },
  infoName (state, getters) {
    const user = state.user
    return user.firstName || getters.roleName
  },
  isSpecialist (state, getters) {
    return getters.rolePlatform === 'SPECIALIST'
  },
  isHeadPerson (state, getters) {
    return getters.rolePlatform === 'CHIEF'
  },
  isDirector (state, getters) {
    return getters.rolePlatform === 'DEPARTMENT_DIRECTOR'
  },
  isViceMayor (state, getters) {
    return getters.rolePlatform === 'VICE_MAYOR'
  },
  isDzp (state, getters) {
    return ['DZP_LEAD_ADVISOR', 'DZP_HEAD_PERSON', 'DZP_DEPUTY_DIRECTOR', 'DZP_DEPARTMENT_DIRECTOR'].includes(getters.rolePlatform)
  },
  isDAIG (state, getters) {
    return ['DAIG_HEAD_PERSON_URBAN', 'DAIG_CHIEF_INSPECTOR', 'DAIG_DEPUTY_DIRECTOR', 'DAIG_HEAD_PERSON_SOCIAL', 'DAIG_DEPARTMENT_DIRECTOR'].includes(getters.rolePlatform)
  },
  isPD (state, getters) {
    return ['LD_HEAD_PERSON', 'LD_LEAD_ADVISOR', 'LD_DEPARTMENT_DIRECTOR'].includes(getters.rolePlatform)
  },
  departmentCode (state, getters) {
    return getters.isDzp ? 'dzp'
      : getters.isDAIG ? 'daig'
        : getters.isPD ? 'ld'
          : ''
  }
}
