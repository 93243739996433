<template>
  <rir-accordion v-if="isView">
    <template slot="activator">
      <div class="d-flex flex-column">
        <div class="mb-2 titanic--text melia">
          Принятие решения по заявлению
        </div>
        <div class="d-flex align-center">
          <rir-icon
            class="mr-2"
            :icon="isStatus.icon"
            :fill="isStatus.fillIcon"
          />
          <span class="anie terminator--text">{{ isStatus.text }}</span>
        </div>
      </div>
    </template>
    <component
      :is="componentForm"
    />
  </rir-accordion>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FormSpecialist from '@/views/landServicePrv/components/statuses/agreement/FormSpecialist'
import FormChief from '@/views/landServicePrv/components/statuses/agreement/FormChief'

export default {
  name: 'agreement-status',
  computed: {
    ...mapGetters('session', ['rolePlatform']),
    ...mapState('statePrv', ['statement']),
    isView () {
      return [
        'APPROVING_BY_HEAD_PERSON',
        'APPROVING_AGREEMENT_BY_DEPARTMENT_DIRECTOR',
        'ANALYZING_DEPARTMENTS_DOCUMENTS',
        'PREPARING_LEGAL_DEPARTMENT_REQUEST',
        'APPROVING_LEGAL_DEPARTMENT',
        'ANALYZING_LEGAL_DEPARTMENT_RESPONSE',
        'COMPLETING_HANDLING_STATEMENT',
        'PREPARING_REJECTION_PROJECT',
        'REJECTED_BY_LEGAL_DEPARTMENT',
        'ISSUED_AGREEMENT',
        'COMPLETING_REJECTION_AFTER_LD',
        'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_LD',
        'APPROVING_AGREEMENT_BY_VICE_MAYOR'
      ].includes(this.statement.statement.status)
    },
    isStatus () {
      let status = {
        icon: 'in-progress',
        fillIcon: 'rocky',
        text: 'В процессе'
      }
      const isFinishStep = [
        'APPROVING_LEGAL_DEPARTMENT',
        'ANALYZING_LEGAL_DEPARTMENT_RESPONSE',
        'COMPLETING_HANDLING_STATEMENT',
        'PREPARING_REJECTION_PROJECT',
        'REJECTED_BY_HEAD_PERSON',
        'COMPLETING_HANDLING_STATEMENT',
        'REJECTED_BY_LEGAL_DEPARTMENT',
        'ISSUED_AGREEMENT',
        'COMPLETING_REJECTION_AFTER_LD',
        'SIGNING_REJECTION_BY_HEAD_PERSON_AFTER_LD',
        'APPROVING_AGREEMENT_BY_VICE_MAYOR'
      ].includes(this.statement.statement.status)
      if (isFinishStep) {
        status = {
          icon: 'selected',
          fillIcon: 'matrix',
          text: 'Завершено'
        }
      }
      return status
    },
    componentForm () {
      switch (this.rolePlatform) {
        case 'SPECIALIST':
          return FormSpecialist
        case 'CHIEF':
          return FormChief
      }
      return null
    }
  }
}
</script>
