<template>
  <section>
    <div class="row row--no-grep">
      <div class="col-md-12-12 pl-0">
        <rir-form-item
          label="Юридический статус">
          Юридическое лицо
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12 px-0">
        <rir-form-item
          label="Название">
          {{ person.fullName || person.shortName }}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12 px-0">
        <rir-form-item
          label="ИНН организации">
          {{ person.inn }}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12 px-0">
        <rir-form-item
          label="Юридический адрес">
          {{ person.legalAddress }}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12 d-flex px-0">
        <rir-form-item
          class="mr-10"
          label="ФИО руководителя">
          {{ person.headPersonLastName }} {{ person.headPersonFirstName }} {{ person.headPersonMiddleName || '' }}
        </rir-form-item>
        <rir-form-item
          class="mt-0"
          label="Должность руководителя">
          {{ person.headPersonPost }}
        </rir-form-item>
      </div>
    </div>
    <template v-if="personInfo">
      <div class="row row--no-grep">
        <div class="col-md-2-12">
          <rir-form-item
            label="Дата рождения">
            {{ personInfo.birthDate | dateRuFormat }}
          </rir-form-item>
        </div>
        <div class="col-md-2-12">
          <rir-form-item
            label="Пол">
            {{ gender }}
          </rir-form-item>
        </div>
        <div class="col-md-3-12">
          <rir-form-item
            label="Телефон">
            {{ personInfo.phone }}
          </rir-form-item>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-3-12">
          <rir-form-item
            label="Серия и номер паспорта">
            {{ personInfo.passport.series }} {{ personInfo.passport.number }}
          </rir-form-item>
        </div>
        <div class="col-md-4-12">
          <rir-form-item
            label="Дата выдачи">
            {{ personInfo.passport.issueDate | dateRuFormat}}
          </rir-form-item>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-3-12">
          <rir-form-item
            label="Код подразделения">
            {{ personInfo.passport.issuerCode }}
          </rir-form-item>
        </div>
        <div class="col-md-9-12">
          <rir-form-item
            label="Кем выдан">
            {{ personInfo.passport.issuerName }}
          </rir-form-item>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'legal-view',
  data: () => ({}),
  props: {
    person: {
      type: Object
    }
  },
  computed: {
    ...mapState('directory', ['genderTypes']),
    gender () {
      return this.genderTypes.find(g => g.value === this.personInfo.gender).text
    },
    personInfo () {
      return this.person.headPerson
    }
  }
}
</script>
