<template>
  <section class="default-layout">
    <sidebar class="default-layout__sidebar"/>
    <section class="default-layout__content">
      <toolbar class="default-layout__toolbar">
        <div class="nomira">
          {{ titlePage }}
        </div>
        <router-view name="toolbar" />
      </toolbar>
      <router-view class="default-layout__context"/>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Sidebar from '@/layout/components/Sidebar'
import Toolbar from '@/layout/components/Toolbar'
export default {
  name: 'default-layout',
  components: { Toolbar, Sidebar },
  computed: {
    ...mapState('apps', ['titlePage'])
  }
}
</script>
<style lang="scss" scoped>
.default-layout{
  height: 100%;
  padding: 0 0 ;
  &__sidebar{
    height: 100vh;
    flex: 0 0 228px;
    width: 228px;
    background-color: var(--rir-gladiator);
    position: fixed;
    top: 0;
    &:before{
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: -50px;
      width: 50px;
      height: 50px;
      background-color: var(--rir-gladiator);
    }
  }
  &__content{
    flex: 1 1 auto;
    height: 100%;
    margin-left: 228px;
    position: relative;
    z-index: 2;
    padding: 40px 0 32px;
  }
  &__toolbar{
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: -40px;
    z-index: 11;
    min-height: 80px;
    background-color: var(--rir-amelie);
    border-radius: 36px 0 0;
  }
  &__context{
    height: 100%;
  }
}
</style>
