<template>
  <div class="p-form">
    <div class="row">
      <div class="col-md-12-12 crephusa">
        Информация об ИП
      </div>
    </div>
    <div class="row">
      <div class="col-md-5-12">
        <rir-form-item
          :error="v.registrationCertificateNumber.$error"
          :message="mesErr(v.registrationCertificateNumber)">
          <rir-input
            label="Номер свидетельства о регистрации"
            v-model="value.registrationCertificateNumber"
            v-mask="'###############'"
          />
        </rir-form-item>
      </div>
      <div class="col-md-5-12">
        <rir-form-item
          :error="v.registrationCertificateDate.$error"
          :message="mesErr(v.registrationCertificateDate)">
          <rir-date-picker
            click-close
            label="Дата получения свидетельства"
            v-model="value.registrationCertificateDate"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12 crephusa">
        Паспортные данные
      </div>
    </div>
    <div class="row">
      <div class="col-md-5-12">
        <rir-button
          @click="selectPhysical"
          class="memento godfather--text">
          <rir-icon icon="avatar" class="mr-2"/>
          Выбрать физическое лицо из списка
        </rir-button>
      </div>
      <div class="col-md-4-12 d-flex align-center" v-if="physical">
        <a
          @click="editPhysical"
          class="rocky--text">
          <rir-icon icon="edit" class="mr-2"/>
          Редактировать информацию
        </a>
      </div>
    </div>
    <div class="row" v-if="physical">
      <div class="col-md-12-12">
        <p-view :personId="physical.id" :key="physical.id" />
      </div>
    </div>
  </div>
</template>

<script>
import validateMixin from '@/plugins/vuelidate/validateMixin'
import SelectPhysical from '@/views/persons/modal/SelectPhysical'
import PView from '@/views/persons/layout/PView'
import FormPhysical from '@/views/persons/modal/FormPhysical'
import { mask } from 'vue-the-mask'
export default {
  name: 'ie-form',
  directives: { mask },
  mounted () {
    this.physical = this.value.physicalPerson
    this.value.physicalPersonId = this.physical?.id
  },
  components: { PView },
  mixins: [validateMixin],
  data: () => ({
    physical: null
  }),
  props: {
    value: {
      type: Object
    },
    v: {
      type: Object,
      required: true
    }
  },
  methods: {
    async selectPhysical () {
      const physical = await this.$root.$modal(SelectPhysical, {
        returnSelect: true,
        personType: 'physical'
      })
      if (physical) {
        this.physical = null
        setTimeout(() => {
          this.physical = physical
          this.value.physicalPersonId = this.physical.id
        }, 0)
      }
    },
    async editPhysical () {
      const physical = await this.$root.$modal(FormPhysical, {
        physicalId: this.physical.id
      })
      if (physical) {
        this.physical = null
        setTimeout(() => {
          this.physical = physical
          this.value.physicalPersonId = this.physical.id
        }, 0)
      }
    }
  }
}
</script>
