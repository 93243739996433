<template>
  <rir-accordion >
    <template slot="activator">
      <div class="d-flex flex-column">
        <div class="titanic--text melia">
          Анализ ответа правового департамента
        </div>
        <div class="d-flex align-center mt-1">
          <rir-icon
            class="mr-2"
            :icon="isStatus.icon"
            :fill="isStatus.fillIcon"
          />
          <span class="anie terminator--text">{{ isStatus.text }}</span>
        </div>
      </div>
    </template>
    <section class="d-flex flex-column">
      <div class="d-flex align-center" v-if="isReturned">
        <rir-icon
          icon="notifications"
          class="mr-2"
        />
        <span>Доработка</span>
      </div>
      <div class="d-flex align-center" v-else>
        <rir-icon
          :icon="finish ? 'selected' : 'waiting'"
          class="mr-2"
        />
        <span>{{ finish ? 'Анализ проведен' : 'Ожидается анализ' }}</span>
      </div>
      <div
        class="d-flex flex-column mt-4 mb-4"
        v-if="!!statement.note && isReturned"
      >
        <span class="kleodora anie mb-1">Комментарий вице-мэра</span>
        <span class="clonia">{{ statement.note }}</span>
      </div>
      <section class="mt-4" v-if="isSpecialist && !finish">
        <rir-button
          class="mb-4"
          color="secondary"
          @click="downloadDoc"
        >
          Скачать документы
        </rir-button>
        <template
          v-if="isAgreement"
        >
          <div
            style="width: 500px"
            class="kleodora anie mb-4"
          >
            При необходимости напишите комментарий по своему решению для курирующего вице-мэра
          </div>
          <rir-textarea
            label="Памятка для вице-мэра"
            style="width: 500px"
            :rows="5"
            class="mb-4"
            v-model="comment"
          ></rir-textarea>
          <rir-button
            class="mb-4"
            @click="changeResult('APPROVE')"
          >
            Отправить на утверждение вице-мэру
          </rir-button>
        </template>
        <rir-button
          :disabled="!!comment"
          color="error"
          @click="changeResult('CANCEL')"
        >
          Отказать по заявлению
        </rir-button>
      </section>
    </section>
  </rir-accordion>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'analyzing-legal-department-response',
  data: function () {
    return {
      comment: null
    }
  },
  props: {
    finish: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(['POST_BLOB_ARCHIVE']),
    ...mapGetters('session', ['isSpecialist']),
    ...mapState('stateRds', ['statement']),
    isStatus () {
      return {
        icon: this.finish ? 'selected' : 'in-progress',
        fillIcon: this.finish ? 'matrix' : 'rocky',
        text: this.finish ? 'Завершено' : 'В процессе'
      }
    },
    isReturned () {
      return this.statement.previousStatus === 'APPROVING_AGREEMENT_BY_VICE_MAYOR' &&
        this.statement.status === 'ANALYZING_LEGAL_DEPARTMENT_RESPONSE'
    },
    isAgreement () {
      return this.statement.decision === 'AGREEMENT'
    }
  },
  methods: {
    ...mapActions('stateRds', ['changeStatus']),
    async changeResult (type) {
      await this.changeStatus({
        decision: type,
        note: this.comment
      })
    },
    async downloadDoc () {
      const arrayUrl = this.statement.attachments
        .filter(f => f.type === '05-02')
        .map(f => f.url)
      await this.POST_BLOB_ARCHIVE(arrayUrl)
    }
  }
}
</script>
