<template>
  <section class="d-flex flex-column">
    <template v-if="status === 'STARTED'">
      <span class="kleodora terminator--text anie mb-4">Сформируйте автоматический запрос в ДЗП, проверьте и при необходимости отредактируйте его, после чего загрузите в систему</span>
      <rir-button @click="downloadReport" class="memento godfather--text mb-4">Сформировать запрос</rir-button>
      <rir-drag-upload-file
        class="mb-4"
        :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt']"
        :count-file="1"
        :upload-url="$root.$uploadServer"
        :params-body="{
          code: 'LAND',
          type: '04-01'
        }"
        v-model="attachment" />
      <rir-button
        :disabled="!attachment.length"
        :loading="isLoading"
        @click="changeStatus">Отправить в ДЗП</rir-button>
    </template>
    <template v-if="status === 'PENDING'">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            fill="matrix"
            class="mr-2"
            icon="selected"
          />
          <span>Отправлено в ДЗП</span>
        </div>
        <span class="pl-6 titanic--text anie">Ожидаем ответ</span>
      </div>
    </template>
    <template v-if="status === 'FINISHED'">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <rir-icon
            class="mr-2"
            icon="notifications"
          />
          <span>Получен ответ</span>
        </div>
        <span class="mb-5 pl-6 titanic--text anie">Ознакомьтесь с документом</span>
        <rir-button
          @click="downloadFiles"
          class="memento godfather--text">Скачать документы</rir-button>
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'department-dzp',
  data: () => ({
    isLoading: false,
    attachment: []
  }),
  props: {
    status: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['GET_BLOB', 'POST_BLOB_ARCHIVE']),
    ...mapState('stateRds', ['statement']),
    attachFiles () {
      return this.statement.attachments.filter(el => el.type === '04-02')
    }
  },
  methods: {
    ...mapActions('stateRds', ['addAttachStatement']),
    async changeStatus () {
      this.isLoading = true
      !!this.attachment.length && await this.addAttachStatement(this.attachment)
      await this.$emit('changeStatus', 'Dzp')
      this.isLoading = false
    },
    async downloadFiles () {
      const arrayUrl = this.attachFiles.map(f => f.url)
      await this.POST_BLOB_ARCHIVE(arrayUrl)
    },
    async downloadReport () {
      const response = await this.GET_BLOB(`land/rds/redistribution/${this.statement.id}/dzp`,
        {},
        {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Запрос ДЗП.odt') // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }
}
</script>
