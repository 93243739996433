<template>
  <section>
    <div class="row">
      <div class="col-md-12-12">
        <rir-table
          :loading="isLoading"
          ref="table"
          @selectRow="selectRow"
          select-event
          :headers="headers"
          :items="list"
          single-sort
        />
      </div>
    </div>
  </section>
</template>

<script>
import getEnv from '@/plugins/env'
import DateTable from '@/components/table/DateTable'
import ReceptionTypeTable from '@/components/table/ReceptionTypeTable'
import DeadlineTable from '@/components/table/DeadlineTable'
import { mapGetters } from 'vuex'
import ApplicantTable from '@/components/table/ApplicantTable'

export default {
  name: 'statement-list',
  mounted () {
    this.loadList()
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.isLoadScroll) {
        this.isLoadScroll = true
        if (this.list.length > this.pagination.pageIndex * this.pagination.size) {
          this.pagination.pageIndex++
          this.loadList()
        } else {
          this.isLoadScroll = false
        }
      }
    }
  },
  data: () => ({
    isLoading: false,
    isLoadScroll: false,
    pagination: {
      pageIndex: 0,
      size: 20
    },
    headers: [
      {
        title: '№',
        column: 'number'
      },
      {
        title: 'Поступило',
        column: 'receiptDate',
        component: DateTable
      },
      {
        title: 'Регистрационный номер',
        column: 'sedNumber'
      },
      {
        title: 'Принято',
        column: 'receptionMethod',
        component: ReceptionTypeTable,
        width: '72px'
      },
      {
        title: 'Статус',
        column: 'statusName',
        width: '185px'
      },
      {
        title: 'Заявитель',
        column: 'applicant',
        component: ApplicantTable
      },
      {
        title: 'Дедлайн',
        column: 'deadline',
        component: DeadlineTable
      }
    ],
    list: []
  }),
  computed: {
    ...mapGetters('session', ['departmentCode'])
  },
  methods: {
    async loadList () {
      this.isLoading = this.pagination.pageIndex === 0
      const { data } = await this.$axios.get(`${getEnv('VUE_APP_API_SERVER')}land/${this.departmentCode}`,
        {
          params: {
            ...this.pagination
          }
        })
      const list = data.map(el => {
        const { statement, ...elem } = el
        return {
          ...statement,
          idStatement: statement.id,
          ...elem,
          statusName: elem.status.name
        }
      })
      this.list = this.list.concat(list)
      this.isLoading = false
    },
    selectRow (item) {
      this.$router.push({ name: 'departments-item', params: { requestId: item.id } })
    }
  }
}
</script>
