<template>
  <section>
    <div class="row row--no-grep">
      <div class="col-md-12-12 px-0">
        <rir-form-item
          label="Юридический статус">
          Индивидуальный предприниматель
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12 px-0">
        <rir-form-item
          label="ФИО">
          {{ personInfo.lastName }} {{ personInfo.firstName }} {{ personInfo.middleName || '' }}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-2-12 pl-0">
        <rir-form-item
          label="Дата рождения">
          {{ personInfo.birthDate | dateRuFormat }}
        </rir-form-item>
      </div>
      <div class="col-md-2-12">
        <rir-form-item
          label="Пол">
          {{ gender }}
        </rir-form-item>
      </div>
      <div class="col-md-5-12">
        <rir-form-item
          label="Телефон">
          {{ personInfo.phone }}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-3-12 pl-0">
        <rir-form-item
          label="Серия и номер паспорта">
          {{ personInfo.passport.series }} {{ personInfo.passport.number }}
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item
          label="Дата выдачи">
          {{ personInfo.passport.issueDate | dateRuFormat}}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-3-12 pl-0">
        <rir-form-item
          label="Код подразделения">
          {{ personInfo.passport.issuerCode }}
        </rir-form-item>
      </div>
      <div class="col-md-9-12">
        <rir-form-item
          label="Кем выдан">
          {{ personInfo.passport.issuerName }}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-4-12 pl-0">
        <rir-form-item
          label="Свидетельство регистрации ИП">
          {{ person.registrationCertificateNumber }}
        </rir-form-item>
      </div>
      <div class="col-md-8-12">
        <rir-form-item
          label="Дата выдачи">
          {{ person.registrationCertificateDate | dateRuFormat }}
        </rir-form-item>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'individual-view',
  data: () => ({}),
  props: {
    person: {
      type: Object
    }
  },
  computed: {
    ...mapState('directory', ['genderTypes']),
    gender () {
      return this.genderTypes.find(g => g.value === this.personInfo.gender).text
    },
    personInfo () {
      return this.person.physicalPerson
    }
  }
}
</script>
