export default {
  async listPerson ({ rootGetters }, payload) {
    const { personType, query } = payload
    return await rootGetters.GET(`common/person/${personType}`, query)
  },
  async getPerson ({ rootGetters }, payload) {
    const { personType, id } = payload
    return await rootGetters.GET(`common/person/${personType}/${id}`)
  },
  async createPerson ({ rootGetters }, payload) {
    const { personType, model } = payload
    return await rootGetters.POST(`common/person/${personType.toLowerCase()}`, model)
  },
  async updatePerson ({ rootGetters }, payload) {
    const { personType, model } = payload
    const { id } = model
    return await rootGetters.PUT(`common/person/${personType.toLowerCase()}/${id}`, model)
  }
}
