import mixinsCommit from '@/store/mixins/mixinsCommit'

export default {
  ...mixinsCommit,
  changeStatusStatement (state, { id, status }) {
    if (state.statements.length) {
      const index = state.statements.findIndex(el => el.id === id)
      state.statements[index].status = status
    }
  },
  changeStatusStatementItem (state, status) {
    state.statement.status = status
  },
  changeDecisionStatementItem (state, decision) {
    state.statement.decision = decision
  },
  changeStatusDepartmentItem (state, { type, status }) {
    state.redistribution[type] = status
  },
  addFileStatement (state, file) {
    const attach = state.statement.attachments
    state.statement.attachments = attach.concat(file)
  }
}
