<template>
  <section>
    <div class="row">
      <div class="col-md-12-12">
        <span class="melia">Объекты недвижимости на участке</span>
      </div>
    </div>
    <template v-for="(realty, index) in model">
      <div class="row" :key="`info-${index}`">
        <div class="col-md-12-12 d-flex align-center">
          <span class="melia mr-8">Объект {{ index + 1 }}</span>
          <a
            v-if="!readonly"
            class="godfather--text amphiris d-flex align-center" @click="removeRealty(index)">
            <rir-icon icon="delete" fill="godfather" class="mr-2"/>
            Удалить
          </a>
        </div>
      </div>
      <div class="row" :key="`data-1-${index}`">
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.$each[index].realtyType.$error"
            :message="mesErr($v.model.$each[index].realtyType)">
          <rir-input
            label="Тип объекта"
            :readonly="readonly"
            v-model="realty.realtyType"
            />
          </rir-form-item>
        </div>
        <div class="col-md-6-12">
          <rir-form-item
            :error="$v.model.$each[index].address.$error"
            :message="mesErr($v.model.$each[index].address)">
            <rir-input
              label="Адрес"
              :readonly="readonly"
              v-model="realty.address"
            />
          </rir-form-item>
        </div>
      </div>
      <div class="row" :key="`data-2-${index}`">
        <div class="col-md-4-12">
          <rir-form-item
            :error="$v.model.$each[index].usagePurpose.$error"
            :message="mesErr($v.model.$each[index].usagePurpose)">
            <rir-input
              label="Цель использования"
              :readonly="readonly"
              v-model="realty.usagePurpose"
            />
          </rir-form-item>
        </div>
        <div class="col-md-4-12">
          <rir-form-item
            :error="$v.model.$each[index].cadastralNumber.$error"
            :message="mesErr($v.model.$each[index].cadastralNumber)">
            <rir-input
              label="Кадастровый номер"
              v-mask="'##:##:#######:####'"
              :readonly="readonly"
              v-model="realty.cadastralNumber"
            />
          </rir-form-item>
        </div>
      </div>
      <div class="row" :key="`person-title-${index}`">
        <div class="col-md-12-12">
          <span class="clymenti">Распределение долей объекта {{ index + 1 }}</span>
        </div>
      </div>
      <div
        v-if="personsList.length !== realty.applicantsShare.length && !readonly"
        class="row"
        :key="`person-action-${index}`">
        <div class="col-md-12-12">
          <rir-form-item
            :error="$v.model.$each[index].applicantsShare.$error"
            :message="mesErr($v.model.$each[index].applicantsShare)">
            <rir-button
              color="secondary"
              content-width
              @click="addPerson(index)"
              class="d-flex align-center">
              <rir-icon icon="add" class="mr-2"/>
              Добавить дольщика
            </rir-button>
          </rir-form-item>
        </div>
      </div>
      <div class="row" :key="`person-${index}`">
        <div
          v-for="(person, indexPerson) in realty.applicantsShare"
          :key="indexPerson"
          class="col-md-4-12 d-flex flex-column">
          <div class="d-flex align-center mb-5">
            <span class="clymenti mr-8">Дольщик {{ indexPerson + 1 }}</span>
            <a
              v-if="!readonly"
              @click="removePerson(index, indexPerson)"
              class="godfather--text amphiris d-flex align-center">
              <rir-icon icon="delete" fill="godfather" class="mr-2"/>
              Удалить
            </a>
          </div>
          <rir-form-item
            :error="$v.model.$each[index].applicantsShare.$each[indexPerson].applicantId.$error"
            :message="mesErr($v.model.$each[index].applicantsShare.$each[indexPerson].applicantId)">
            <rir-select
              :readonly="readonly"
              :items="persons"
              v-model="person.applicantId"
              label="Заявитель"
              text-value="name"
              id-value="id"
            />
          </rir-form-item>
          <rir-form-item
            :error="$v.model.$each[index].applicantsShare.$each[indexPerson].realtyPercentage.$error"
            :message="mesErr($v.model.$each[index].applicantsShare.$each[indexPerson].realtyPercentage)">
            <rir-input
              :readonly="readonly"
              label="Доля собственности, %"
              v-model="person.realtyPercentage"
            />
          </rir-form-item>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-md-12-12">
        <rir-button
          v-if="!readonly"
          color="secondary"
          @click="addRealty"
        >
          Добавить еще объект
        </rir-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required, typeFloatOrNumber, minLength } from '@/plugins/vuelidate/customValidate'
import { mask } from 'vue-the-mask'

export default {
  name: 'realties-state',
  directives: { mask },
  mixins: [validationMixin, validateMixin],
  mounted () {
    if (this.realties) this.model = this.realties
  },
  data: () => ({
    model: []
  }),
  validations: {
    model: {
      $each: {
        realtyType: {
          required: required('Обязательное поле')
        },
        address: {
          required: required('Обязательное поле')
        },
        usagePurpose: {
          required: required('Обязательное поле')
        },
        cadastralNumber: {
          required: required('Обязательное поле')
        },
        applicantsShare: {
          minLength: minLength(1, 'Добавьте дольщика'),
          $each: {
            applicantId: {
              required: required('Обязательное поле')
            },
            realtyPercentage: {
              required: required('Обязательное поле'),
              typeFloatOrNumber: typeFloatOrNumber('Формат DDD.DD')
            }
          }
        }
      }
    }
  },
  props: {
    readonly: {
      type: Boolean
    },
    realties: {
      type: Array
    }
  },
  computed: {
    ...mapState('directory', ['corporateForms']),
    ...mapState('statementPerson', ['personsList']),
    persons () {
      return this.personsList.map(pl => {
        let name
        let person = pl.person
        switch (pl.type) {
          case 'physical':
            name = `${person.lastName || ''} ${person.firstName?.charAt(0).toUpperCase() || ''}. ${person.middleName?.charAt(0).toUpperCase() || ''}.`
            break
          case 'legal':
            name = `${this.corporateForms.find(cf => cf.code === person.corporateFormCode)?.name} ${person.shortName}`
            break
          case 'individual':
            person = pl.person.physicalPerson
            name = `ИП ${person.lastName} ${person.firstName?.charAt(0).toUpperCase() || ''}. ${person.middleName?.charAt(0).toUpperCase() || ''}.`
            break
        }
        return {
          id: pl.id,
          name
        }
      })
    }
  },
  methods: {
    ...mapMutations('statementPerson', ['setProperty']),
    addRealty () {
      this.model.push({
        realtyType: null,
        address: null,
        usagePurpose: null,
        cadastralNumber: null,
        applicantsShare: []
      })
    },
    addPerson (indexRealty) {
      this.model[indexRealty].applicantsShare.push({
        applicantId: null,
        realtyPercentage: null
      })
    },
    removeRealty (index) {
      this.model.splice(index, 1)
    },
    removePerson (indexRealty, indexPerson) {
      this.model[indexRealty].applicantsShare.splice(indexPerson, 1)
    }
  }
}
</script>
