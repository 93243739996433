<template>
  <section v-if="isFilterView">
    <div class="row">
      <div class="col-md-12-12">
        <a @click="isShow = !isShow" class=" d-flex align-center">
          <rir-icon
            class="mr-1"
            icon="filter" />
          <span class="rocky--text amphiris">Показать фильтр</span>
          <rir-icon
            :style="{
              transform: `rotate(${isShow ? 180 : 0}deg)`
            }"
            class="ml-1"
            size="12"
            icon="arrow-down" />
        </a>
      </div>
    </div>
    <section v-show="isShow">
      <div class="row">
        <div class="col-md-4-12">
          <rir-date-picker
            label="Период поступления"
            period
            is-time-zone
            v-model="model.period"
          />
        </div>
        <div class="col-md-4-12">
          <rir-select
            label="Статус"
            :items="statusList"
            id-value="code"
            text-value="name"
            v-model="model.statusCodes"
            select-all
          />
        </div>
        <div class="col-md-4-12" v-if="isHeadPerson">
          <rir-select
            label="Исполнитель"
            :items="executors"
            id-value="id"
            text-value="fullName"
            v-model="model.executorId"
            select-all
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6-12">
          <rir-input
            label="Кадастровый номер"
            v-model="model.cadastralNumber"
            v-mask="'##:##:#######:####'">
            <rir-icon
              slot="before"
              icon="search"
            />
          </rir-input>
        </div>
        <div class="col-md-6-12">
          <rir-input
            label="ФИО заявителя"
            v-model="model.applicant">
            <rir-icon
              slot="before"
              icon="search"
            />
          </rir-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12-12">
          <rir-button
            @click="setFilter"
          >Применить фильтр</rir-button>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'

export default {
  name: 'filters-list',
  directives: { mask },
  data: () => ({
    isShow: false
  }),
  props: {
    value: {
      type: Object,
      default: () => ({
        period: null,
        executorId: null,
        cadastralNumber: null,
        applicant: null,
        statusCodes: null,
        pageIndex: 0,
        size: 20
      })
    },
    typeProcess: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('directory', ['statusProcess', 'executors']),
    ...mapGetters('session', ['rolePlatform']),
    statusList () {
      return this.statusProcess(this.typeProcess).filter(sp => {
        if (this.isHeadPerson) return true
        return sp.code !== 'ASSIGNING_EXECUTOR'
      })
    },
    isFilterView () {
      return !['DZP', 'DAIG', 'DP', 'VICE_MAYOR'].includes(this.rolePlatform)
    },
    isHeadPerson () {
      return this.rolePlatform === 'CHIEF'
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    setFilter () {
      sessionStorage.setItem('filter-period', JSON.stringify(this.value.period))
      this.model.pageIndex = 0
      this.$emit('loadData')
    }
  }
}
</script>
