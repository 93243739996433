<template>
  <section class="d-flex flex-column">
    <span class="melia mb-5">Ваш ответ</span>
    <rir-drag-upload-file
      class="mb-4"
      :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt', '.zip']"
      :count-file="1"
      :upload-url="$root.$uploadServer"
      :params-body="{
          code: 'LAND',
          type: codeAnswerFile
        }"
      v-model="attachment" />
    <rir-button
      class="mt-5"
      @click="changeStatus"
    >
      Отправить на согласование
    </rir-button>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import getEnv from '@/plugins/env'

export default {
  name: 'upload-request',
  data: (vm) => ({
    attachment: vm.process.attachments || []
  }),
  props: {
    process: {
      type: Object
    },
    processCode: {
      type: String
    }
  },
  computed: {
    ...mapGetters('session', ['departmentCode']),
    codeAnswerFile () {
      let code = null
      switch (this.processCode) {
        case 'LAND_REDISTRIBUTION':
          switch (this.departmentCode) {
            case 'dzp':
              code = '04-02'
              break
            case 'daig':
              code = '03-02'
              break
            case 'ld':
              code = '05-02'
              break
          }
          break
        case 'LAND_PROVISION':
          switch (this.departmentCode) {
            case 'dzp':
              code = '14-01'
              break
            case 'daig':
              code = '13-02'
              break
            case 'ld':
              code = '15-02'
              break
          }
      }
      return code
    }
  },
  methods: {
    async changeStatus () {
      if (this.attachment.length) {
        await this.$axios
          .put(
            `${getEnv('VUE_APP_API_SERVER')}land/${this.departmentCode}/${this.process.id}/update-attachment`,
            this.attachment[0]
          )
        const { data } = await this.$axios
          .post(
            `${getEnv('VUE_APP_API_SERVER')}land/${this.departmentCode}/${this.process.id}/update-status`,
            {
              statusCode: this.process.status.code,
              decision: 'APPROVE'
            }
          )
        this.process.status = data
      }
    }
  }
}
</script>
