<template>
  <rir-accordion >
    <template slot="activator">
      <div class="d-flex flex-column">
        <div class="titanic--text melia">
          Подготовка  соглашения или отказа по заявлению
        </div>
        <div class="d-flex align-center mt-1">
          <rir-icon
            class="mr-2"
            :icon="isStatus.icon"
            :fill="isStatus.fillIcon"
          />
          <span class="anie terminator--text">{{ isStatus.text }}</span>
        </div>
      </div>
    </template>
    <section
      class="d-flex flex-column">
      <span class="mb-4 kleodora d-flex align-center" v-if="isReturned">
        <rir-icon
          class="mr-2"
          icon="notifications"/>
        Доработка
      </span>
      <span class="mb-4 kleodora anie" v-else-if="!isStatus.progress">На основе анализа ответов по заявлению выберите документ</span>
      <span class="mb-4 kleodora d-flex align-center" v-else-if="finish">
        <rir-icon
          class="mr-2"
          icon="selected"/>
        Отправлено на утверждение
      </span>
      <span class="mb-4 kleodora d-flex align-center" v-else>
        <rir-icon
          class="mr-2"
          icon="waiting"/>
        Ожидание подготовки документов специалистом
      </span>
      <div v-if="isReturned && statement.note" class="d-flex flex-column">
        <span style="width: 500px" class="kleodora anie mb-3">Изучите комменатарии при наличии, внесите необходимые коррективы и загрузите исправленный документ в систему</span>
        <span class="kleodora anie mb-1">Комменатрий</span>
        <span class="clonia mb-6">{{ statement.note }}</span>
      </div>
      <span class="kleodora anie mb-4">Выберите документ для формирования</span>
      <rir-radio-group
        class="mb-5"
        v-model="decision"
        is-column>
        <rir-radio
          :readonly="isStatus.progress"
          label="Соглашение"
          value="AGREEMENT"
        />
        <rir-radio
          :readonly="isStatus.progress"
          label="Отказ"
          value="REJECTION"
        />
      </rir-radio-group>
      <template v-if="decision && !isStatus.progress">
        <rir-button
          class="mb-5"
          @click="downloadReport"
        >{{ dataDocument.btnText }}</rir-button>
        <rir-drag-upload-file
          class="mb-6"
          :count-file="1"
          :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt']"
          :upload-url="$root.$uploadServer"
          :params-body="{
              code: 'LAND',
              type: dataDocument.typeFile
            }"
          v-model="attachment" />
        <rir-button
          :disabled="!attachment.length"
          @click="send">
          Отправить на согласование руководителю
        </rir-button>
      </template>
      <template v-if="isStatus.progress">
        <rir-file-list
          :files="attachment" />
      </template>
    </section>
  </rir-accordion>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'requesting-from-departments',
  mounted () {
    this.decision = this.statement.decision
    this.attachment = this.statement.attachments.filter(el => el.type === this.dataDocument.typeFile)
  },
  data: () => ({
    decision: null,
    attachment: []
  }),
  props: {
    finish: {
      type: Boolean
    }
  },
  watch: {
    decision: function () {
      this.attachment = this.statement.attachments.filter(el => el.type === this.dataDocument.typeFile)
    }
  },
  computed: {
    ...mapGetters(['GET_BLOB']),
    ...mapGetters('session', ['isSpecialist']),
    ...mapState('stateRds', ['statement']),
    isStatus () {
      return {
        icon: this.finish ? 'selected' : 'in-progress',
        fillIcon: this.finish ? 'matrix' : 'rocky',
        text: this.finish ? 'Завершено' : 'В процессе',
        progress: this.finish || !this.isSpecialist
      }
    },
    dataDocument () {
      if (this.decision === 'AGREEMENT') {
        return {
          btnText: 'Сформировать соглашение',
          urlDownloadDoc: `land/rds/redistribution/${this.statement.id}/agreement`,
          fileName: 'Соглашение.odt',
          typeFile: '06-01'
        }
      }
      return {
        btnText: 'Сформировать отказ',
        urlDownloadDoc: `land/rds/redistribution/${this.statement.id}/rejection`,
        fileName: 'Отказ.odt',
        typeFile: '07-01'
      }
    },
    isReturned () {
      return [
        'APPROVING_BY_HEAD_PERSON',
        'APPROVING_BY_DEPARTMENT_DIRECTOR',
        'APPROVING_LEGAL_DEPARTMENT'
      ].includes(this.statement.previousStatus) &&
        this.statement.status === 'ANALYZING_DEPARTMENTS_DOCUMENTS'
    }
  },
  methods: {
    ...mapActions('stateRds', ['changeStatus', 'addAttachStatement', 'changeDecision']),
    async send () {
      this.isLoad = true
      await this.changeDecision({ decision: this.decision })
      await this.addAttachStatement(this.attachment)
      await this.changeStatus({
        decision: 'APPROVE'
      })
      this.isLoad = false
    },
    async downloadReport () {
      const response = await this.GET_BLOB(this.dataDocument.urlDownloadDoc,
        {},
        {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.dataDocument.fileName) // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }
}
</script>
