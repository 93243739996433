import DzpLogic from '@/views/Department/utils/DzpLogic'
import DaigLogic from '@/views/Department/utils/DaigLogic'
import LDLogic from '@/views/Department/utils/LDLogic'

export default function (process, department) {
  const rolePlatform = this.$store.getters['session/rolePlatform']
  switch (department) {
    case 'dzp':
      return DzpLogic(process, rolePlatform)
    case 'daig':
      return DaigLogic(process, rolePlatform)
    case 'ld':
      return LDLogic(process, rolePlatform)
  }
  return null
}
