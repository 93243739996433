<template>
  <section class="d-flex flex-column">
    <span class="melia titanic--text mb-4">Назначить ответственного</span>
    <rir-select
      class="mb-8"
      label="ФИО ответственного"
      :items="executors"
      id-value="id"
      text-value="fullName"
      v-model="executorId"
    />
    <rir-button
      @click="changeStatus"
      :disabled="!executorId"
    >Отправить на разработку ответа</rir-button>
  </section>
</template>

<script>
import getEnv from '@/plugins/env'
import { mapGetters } from 'vuex'
export default {
  name: 'change-executor',
  mounted () {
    this.loadExecutor()
  },
  data: () => ({
    executors: [],
    executorId: null
  }),
  props: {
    process: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('session', ['departmentCode']),
    findRoles () {
      switch (this.departmentCode) {
        case 'daig':
          return 'LAND.DAIG.CHIEF_INSPECTOR'
        case 'ld':
          return 'LAND.LD.LEAD_ADVISOR'
      }
      return null
    }
  },
  methods: {
    async loadExecutor () {
      const { data } = await this.$axios.get(`${getEnv('VUE_APP_API_SERVER')}common/get-users`,
        {
          params: {
            roles: this.findRoles
          }
        })
      this.executors = data.map((el) => ({
        ...el,
        fullName: `${el.lastName || ''} ${el.firstName || ''} ${el.middleName || ''} `
      }))
    },
    async changeStatus () {
      if (this.executorId) {
        const { data } = await this.$axios
          .post(
            `${getEnv('VUE_APP_API_SERVER')}land/${this.departmentCode}/${this.process.id}/update-status`,
            {
              statusCode: this.process.status.code,
              decision: 'APPROVE',
              executorId: this.executorId
            }
          )
        this.process.status = data
      }
    }
  }
}
</script>
