<template>
  <section v-if="!isLoadData">
    <div class="row row--no-grep">
      <div class="col-md-12-12">
        <rir-form-item
          label="ФИО">
          {{ person.lastName }} {{ person.firstName }} {{ person.middleName }}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-2-12">
        <rir-form-item
          label="Дата рождения">
          {{ person.birthDate | dateRuFormat }}
        </rir-form-item>
      </div>
      <div class="col-md-2-12">
        <rir-form-item
          label="Пол">
          {{ gender }}
        </rir-form-item>
      </div>
      <div class="col-md-3-12">
        <rir-form-item
          label="Телефон">
          {{ person.phone || "---"}}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-3-12">
        <rir-form-item
          label="Серия и номер паспорта">
          {{ person.passport.series }} {{ person.passport.number }}
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item
          label="Дата выдачи">
          {{ person.passport.issueDate | dateRuFormat}}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-3-12">
        <rir-form-item
          label="Код подразделения">
          {{ person.passport.issuerCode }}
        </rir-form-item>
      </div>
      <div class="col-md-9-12">
        <rir-form-item
          label="Кем выдан">
          {{ person.passport.issuerName }}
        </rir-form-item>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'p-view',
  mounted () {
    this.getInfoPerson()
  },
  data: () => ({
    isLoadData: true,
    person: null
  }),
  props: {
    personId: {
      type: String
    }
  },
  computed: {
    ...mapState('directory', ['genderTypes']),
    gender () {
      return this.genderTypes.find(g => g.value === this.person.gender).text
    }
  },
  methods: {
    ...mapActions('person', ['getPerson']),
    async getInfoPerson () {
      this.isLoadData = true
      this.person = await this.getPerson({ personType: 'physical', id: this.personId })
      this.isLoadData = false
    }
  }
}
</script>
