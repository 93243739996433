<template>
  <rir-cards-block>
    <div
      class="nomira px-4"
      slot="status">Выбор физического лица</div>
    <div slot="title" class="row row--no-grep">
      <div class="col-md-5-12">
        <rir-input
          label="Поиск"
          v-model="querySearch"
        >
          <rir-icon slot="before" icon="search" />
        </rir-input>
      </div>
    </div>
    <template slot="default">
      <div class="row row--no-grep">
        <div class="col-md-12-12 d-flex flex-column">
          <rir-table
            v-if="itemsTable.length"
            :headers="column"
            :items="itemsTable"
            @selectRow="selectPerson"
            select-event
          />
          <template v-else>
            <span class="melia mb-2 titanic--text ekas">Физические лица не найдены</span>
            <span class="kleodora terminator--text anie mb-8">Проверьте правильность введенных для поиска данных или создайте новое физическое лицо</span>
            <rir-button @click="openCreatePhysical">Создать физическое лицо</rir-button>
          </template>
        </div>
      </div>
    </template>
  </rir-cards-block>
</template>

<script>
import listData from '@/views/persons/mixins/listData'
import moment from 'moment'
import FormPhysical from '@/views/persons/modal/FormPhysical'
export default {
  name: 'select-physical',
  mixins: [listData],
  data: () => ({
    querySearch: null,
    column: [
      {
        title: 'ФИО',
        column: 'fullName'
      },
      {
        title: 'Паспорт',
        column: 'passport'
      },
      {
        title: 'Дата выдачи',
        column: 'issueDate'
      },
      {
        title: 'Дата рождения',
        column: 'birthDate'
      }
    ]
  }),
  computed: {
    itemsTable () {
      return this.persons.map(el => ({
        ...el,
        fullName: `${el.lastName} ${el.firstName} ${el.middleName || ''}`,
        passport: `${el.passport.series} ${el.passport.number}`,
        issueDate: moment(el.passport.issueDate).format('DD.MM.YYYY'),
        birthDate: moment(el.birthDate).format('DD.MM.YYYY')
      }))
    }
  },
  watch: {
    querySearch: function (val) {
      this.loadData({ query: val })
    }
  },
  methods: {
    selectPerson (item) {
      this.$attrs.closeModal(item)
    },
    openCreatePhysical () {
      this.$root.$modal(FormPhysical)
    }
  }
}
</script>
