<template>
  <section class="land-service-item">
    <div
      class="d-flex align-center justify-center"
      v-if="isLoadData">
      <loading-ring
        :size="32"
        color="var(--rir-rocky)"
      />
    </div>
    <div class="row flex-column" v-else>
      <departments-status class="mb-3" />
      <agreement-reject class="mb-5" />
      <agreement-status class="mb-5" />
      <legal-status class="mb-5" />
      <finish-step class="mb-5"/>
      <finish-step-reject class="mb-5"/>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import DepartmentsStatus from '@/views/landServicePrv/components/statuses/DepartmentsStatus'
import AgreementStatus from '@/views/landServicePrv/components/statuses/AgreementStatus'
import LegalStatus from '@/views/landServicePrv/components/statuses/LegalStatus'
import FinishStep from '@/views/landServicePrv/components/statuses/FinishStep'
import AgreementReject from '@/views/landServicePrv/components/statuses/AgreementReject'
import FinishStepReject from '@/views/landServicePrv/components/statuses/FinishStepReject'
export default {
  name: 'land-service-status',
  components: { FinishStepReject, AgreementReject, FinishStep, LegalStatus, AgreementStatus, DepartmentsStatus },
  mounted () {
    this.loadData()
    this.setProperty({ name: 'titlePage', value: 'Статусы заявления' })
  },
  data: () => ({
    isLoadData: true
  }),
  props: {
    requestId: {
      type: String
    }
  },
  computed: {
    ...mapState('statePrv', ['statement'])
  },
  methods: {
    ...mapMutations('apps', ['setProperty']),
    ...mapActions('statePrv', ['fullStatementItem']),
    async loadData () {
      this.isLoadData = true
      await this.fullStatementItem({
        id: this.requestId
      })
      this.isLoadData = false
    }
  }
}
</script>

<style lang="scss" scoped>
.land-service-item{
  display: flex;
  flex-direction: column;
  &__column{
    display: flex;
    align-items: center
  }
}
</style>
