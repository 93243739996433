<template>
  <rir-table
    :headers="column"
    :items="itemsTable"
    @selectRow="selectRow"
    select-event
  />
</template>

<script>
import listData from '@/views/persons/mixins/listData'
import moment from 'moment'

export default {
  name: 'individual-list',
  mixins: [listData],
  data: () => ({
    column: [
      {
        title: 'ФИО',
        column: 'fullName'
      },
      {
        title: 'Паспорт',
        column: 'passport'
      },
      {
        title: 'Дата выдачи',
        column: 'issueDate'
      },
      {
        title: 'Дата рождения',
        column: 'birthDate'
      },
      {
        title: '№ свидетельства',
        column: 'registrationCertificateNumber'
      }
    ]
  }),
  computed: {
    itemsTable () {
      return this.persons.map(el => ({
        id: el.id,
        fullName: `${el.physicalPerson.lastName} ${el.physicalPerson.firstName} ${el.physicalPerson.middleName || ''}`,
        passport: `${el.physicalPerson.passport.series} ${el.physicalPerson.passport.number}`,
        issueDate: moment(el.physicalPerson.passport.issueDate).format('DD.MM.YYYY'),
        birthDate: moment(el.physicalPerson.birthDate).format('DD.MM.YYYY'),
        registrationCertificateNumber: el.registrationCertificateNumber
      }))
    }
  }
}
</script>
