<template>
  <rir-tooltip
    :activator-hover="isTooltip"
  >
    <span
      ref="content"
      slot="activator"
      class="statusTable__content">{{ status.name }}</span>
    <span class="amelie--text">{{ status.name }}</span>
  </rir-tooltip>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import tableCheckEclipse from '@/plugins/mixins/tableCheckEclipse'
export default {
  name: 'status-table',
  mixins: [tableCheckEclipse],
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    ...mapState('apps', ['typeProcess']),
    ...mapGetters('directory', ['statusProcess']),
    status () {
      return this.statusProcess(this.typeProcess).find(el => {
        return el.code === this.item.status
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.statusTable{
  &__content{
    @media (max-width: 1366px) {
      width: 185px;
    }
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
