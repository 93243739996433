<template>
  <section>
    <div class="row">
      <div class="col-md-4-12">
        <rir-input
          label="Поиск"
          v-model="query">
          <rir-icon
            icon="search"
            slot="before"
          />
        </rir-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12">
        <rir-tabs
          id-value="enum"
          text-value="name"
          :items="personTypesEnum"
          v-model="personList"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12">
        <keep-alive>
          <component
            :is="`${personType}List`"
            :person-type="personType"
            :query="query"
          />
        </keep-alive>
      </div>
    </div>
    <router-link
      :to="{ name: 'person-create' }"
      class="create">
      <rir-icon icon="add" fill="amelie" />
    </router-link>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import LegalList from '@/views/persons/list/LegalList'
import IndividualList from '@/views/persons/list/IndividualList'
import PhysicalList from '@/views/persons/list/PhysicalList'

export default {
  name: 'persons-list',
  components: { LegalList, IndividualList, PhysicalList },
  mounted () {
    this.setProperty({ name: 'titlePage', value: 'Заявители' })
  },
  data: () => ({
    query: ''
  }),
  props: {
    personType: {
      type: String
    }
  },
  computed: {
    ...mapGetters('directory', ['personTypesEnum']),
    personList: {
      get () {
        return this.personType
      },
      set (val) {
        if (this.personType !== val) {
          this.$router.push({ name: 'persons-list', params: { personType: val } })
        }
      }
    }
  },
  methods: {
    ...mapMutations('apps', ['setProperty'])
  }
}
</script>

<style lang="scss" scoped>
.create{
  position: fixed;
  bottom: 40px;
  right: 40px;
  height: 40px;
  width: 40px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
