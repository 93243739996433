<template>
  <rir-cards-block
    class="pb-4"
  >
    <span slot="title" class="nomira px-8">Добавить документ по заявлению</span>
    <template slot="default">
      <section
        class="px-8 d-flex flex-column"
      >
        <div
          class="d-flex flex-column"
        >
          <rir-select
            :items="listType"
            id-value="code"
            text-value="text"
            v-model="typeAttaches.type"
          />
          <rir-drag-upload-file
            v-if="!!typeAttaches.type"
            class="mt-6"
            :count-line="3"
            :accept-file="['.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf', '.odt']"
            :count-file="0"
            v-model="typeAttaches.attachments"
            :upload-url="$root.$uploadServer"
            :params-body="{
              code: 'LAND',
              type: typeAttaches.type
            }" />
        </div>
      </section>
    </template>
    <section
      class="px-8 d-flex align-center"
      style="height: 100%"
      slot="actions">
      <rir-button
        :loading="isLoading"
        :disabled="!this.typeAttaches.attachments.length"
        @click="addAttach">
        Сохранить
      </rir-button>
    </section>
  </rir-cards-block>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'add-files-statement',
  data: () => ({
    isLoading: false,
    listType: [
      {
        code: '02-02',
        text: 'Документы от Росреестра'
      },
      {
        code: '03-02',
        text: 'Документы от ДАиГ'
      },
      {
        code: '04-02',
        text: 'Документы от ДЗП'
      }
    ],
    typeAttaches: {
      type: null,
      attachments: []
    }
  }),
  watch: {
    'typeAttaches.type': function () {
      this.typeAttaches.attachments = []
    }
  },
  methods: {
    ...mapActions('stateRds', ['addAttachStatement']),
    async addAttach () {
      this.isLoading = true
      await this.addAttachStatement(this.typeAttaches.attachments)
      this.$attrs.closeModal()
      this.isLoading = false
    }
  }
}
</script>
