export default {
  methods: {
    mesErr (v) {
      const invalid = v.$dirty && v.$invalid
      if (!invalid) {
        return
      }
      // if (v.$pending) {
      //   return ' '
      // }
      const arr = Object.keys(v.$params)
      const errorKey = arr.find((key) => {
        return !v[key]
      })
      return (errorKey && v.$params[errorKey].mesErr) || ''
    }
  }
}
